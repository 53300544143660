import { useEffect } from "react";
import { Button, Input, Form } from "antd";
import { checkUserName } from "../../../store/user";

const EmailForm = ({ onEmailSubmit, disabled, isSignUpPage }) => {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    onEmailSubmit(values.username, values.email);
  };

  useEffect(() => {
    form.setFieldsValue({
      username: null,
      email: "",
    });
  }, [isSignUpPage]);

  return (
    <Form
      name="nest-messages"
      onFinish={handleSubmit}
      className="login__form"
      layout="vertical"
      form={form}
    >
      {isSignUpPage && (
        <Form.Item
          name={["username"]}
          validateTrigger="onSubmit"
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(
                    new Error("Please input your Username")
                  );
                } else {
                  return checkUserName(value).then((isCorrect) => {
                    if (isCorrect) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                      new Error("Username is already taken")
                      )
                    }
                  });
                }
              },
            }),
          ]}
          className="login__form-item login__form-item_name"
          labelAlign="top"
          hasFeedback
        >
          <Input placeholder="Username" />
        </Form.Item>
      )}
      <Form.Item
        name={["email"]}
        validateTrigger="onSubmit"
        rules={[
          {
            validator: (_, value) => {
              if (
                !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                  value
                )
              ) {
                return Promise.reject("Please enter a valid email");
              }
              return Promise.resolve();
            },
          },
        ]}
        className="login__form-item"
        labelAlign="top"
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Button
        className="generated__button_blue-filled"
        type="primary"
        disabled={disabled}
        htmlType="submit"
      >
        Get a magic link
      </Button>
    </Form>
  );
};

export default EmailForm;
