import React, { useEffect, useRef } from "react";

function Matrix({ opacity }) {
  const canvas = useRef();

  const getRandomMobileChar = () => {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*()_-+={}[]\\|:;"<>,.?/~`';
    const randomIndex = Math.floor(Math.random() * chars.length);
    return chars.charAt(randomIndex);
  };

  useEffect(() => {
    const context = canvas.current.getContext("2d");

    const width = document.body.offsetWidth;
    const height = window.innerHeight;

    canvas.current.width = width;
    canvas.current.height = height;

    context.fillStyle = "#000";
    context.fillRect(0, 0, width, height);

    // calculate how many 'lines' to show and animate
    const columns = Math.floor(width / 20) + 1;
    const yPositions = Array.from({ length: columns }).fill(0);

    context.fillStyle = "#000";
    context.fillRect(0, 0, width, height);

    const matrixEffect = () => {
      context.fillStyle = "#0001";
      context.fillRect(0, 0, width, height);

      context.fillStyle = "#0f0";
      context.font = "15pt Array";

      yPositions.forEach((y, index) => {
        const text = getRandomMobileChar();
        const x = index * 20;
        context.fillText(text, x, y);

        if (y > 100 + Math.random() * 10000) {
          yPositions[index] = 0;
        } else {
          yPositions[index] = y + 20;
        }
      });
    };

    const interval = setInterval(matrixEffect, 70);
    return () => {
      clearInterval(interval);
    };
  }, [canvas]);

  return (
    <div
      style={{
        // custom styles to make it show up in the background
        background: "#1d1d1d",
        overflow: "hidden",
        position: "fixed",
        height: window.innerHeight,
        width: "100vw",
        zIndex: 0,
        left: "0",
        top: "0",
        opacity: opacity,
      }}
    >
      <canvas ref={canvas} />
    </div>
  );
}

export default Matrix;
