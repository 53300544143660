import React, { useState, useEffect, useRef } from "react";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import "antd/dist/reset.css";
import Body from "./components/body/Body";
import Login from "./components/login/Login";
import { magic } from "./lib/magic";
import { UserContext } from "./lib/UserContext";
import ProgressContext from "./lib/ProgressContext";
import ProtectedRoute from "./utils/ProtectedRoute";
import CurrentLevelContext from "./lib/CurrentLevelContext";
import Profile from "./components/profile/Profile";
import NotFound from "./components/notFound/NotFound";
import { getCurrentUser } from "./store/user";
import Leaderboard from "./components/leaderboard/Leaderboard";
import Welcome from "./components/welcome/Welcome";
import { useMediaQuery } from "react-responsive";
import SocialCallback from "./components/socialCallback/SocialCallback";

import { ConfigProvider, Layout, theme } from "antd";

const App = () => {
  const [user, setUser] = useState({ loading: true });
  const [progress, setProgress] = useState([
    {
      isWon: false,
      isInProgress: true,
      messages: [],
      symbols: 0,
      cluesUsed: 0,
      score: 0,
    },
  ]);
  const [currentLevel, setCurrentLevel] = useState(0);

  const effectCalled = useRef(false);

  const [isNotFirstTime, setIsNotFirstTime] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 980px)",
  });
  useEffect(() => {
    const setBodyHeight = () => {
      document.body.style.height = `${
        isMobile ? window.innerHeight : window.innerHeight
      }px`;
    };

    setBodyHeight();

    window.addEventListener("resize", setBodyHeight);

    return () => {
      window.removeEventListener("resize", setBodyHeight);
    };
  }, []);

  const logout = () => {
    setUser({ user: null });
    localStorage.removeItem("gtp-riddle_auth");
    setProgress([
      {
        isWon: false,
        isInProgress: true,
        messages: [],
        symbols: 0,
        cluesUsed: 0,
        score: 0,
      },
    ]);
    setCurrentLevel(0);
  };

  const updateFirstVisit = () => {
    setIsNotFirstTime(localStorage.getItem("gtp-riddle-first") == 1);
  };

  const checkCurrentLevel = (levels) => {
    setCurrentLevel(levels && levels.length > 0 ? levels.length - 1 : 0);
  };

  useEffect(() => {
    if (effectCalled.current) return;
    effectCalled.current = true;
    setIsNotFirstTime(localStorage.getItem("gtp-riddle-first") == 1);
    magic.user.isLoggedIn().then((isLoggedIn) => {
      return isLoggedIn
        ? magic.user.getMetadata().then((userData) => {
            const token = localStorage.getItem("gtp-riddle_auth");
            if (token) {
              try {
                getCurrentUser().then((response) => {
                  setUser({
                    user: response,
                  });
                  setProgress(response.progress);
                  checkCurrentLevel(response.progress);
                });
              } catch {
                setUser(userData);
              }
            } else {
              magic.user.logout().then(() => {
                logout();
              });
            }
          })
        : setUser({ user: null });
    });
  }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorBgBase: "#00000000",
          colorPrimary: "#3C8618",
        },
      }}
    >
      <Router>
        <UserContext.Provider value={[user, setUser]}>
          <ProgressContext.Provider value={[progress, setProgress]}>
            <CurrentLevelContext.Provider
              value={[currentLevel, setCurrentLevel]}
            >
              <Layout>
                <Routes>
                  <Route
                    path="/welcome"
                    element={
                      <ProtectedRoute
                        redirectPath="/"
                        isAllowed={user && !user.loading && !user.user}
                        isLoading={user && user.loading}
                        children={
                          <Welcome updateFirstVisit={updateFirstVisit} />
                        }
                      />
                    }
                  />
                  <Route
                    path="/callback"
                    element={
                      <ProtectedRoute
                        redirectPath="/"
                        isAllowed={
                          (user && !user.loading && !user.user) ||
                          isNotFirstTime
                        }
                        isLoading={user && user.loading}
                        children={
                          <SocialCallback
                            checkCurrentLevel={checkCurrentLevel}
                          />
                        }
                      />
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <ProtectedRoute
                        redirectPath="/"
                        isAllowed={
                          (user && !user.loading && !user.user) ||
                          isNotFirstTime
                        }
                        isLoading={user && user.loading}
                        children={
                          <Login checkCurrentLevel={checkCurrentLevel} />
                        }
                      />
                    }
                  />
                  <Route
                    path="/signup"
                    element={
                      <ProtectedRoute
                        redirectPath="/"
                        isAllowed={
                          (user && !user.loading && !user.user) ||
                          isNotFirstTime
                        }
                        isLoading={user && user.loading}
                        children={
                          <Login
                            isSignUp={true}
                            checkCurrentLevel={checkCurrentLevel}
                          />
                        }
                      />
                    }
                  />
                  <Route
                    element={
                      <ProtectedRoute
                        redirectPath="/welcome"
                        isAllowed={user && !user.loading && user.user}
                        isLoading={user && user.loading}
                      />
                    }
                  >
                    <Route
                      path="/profile"
                      element={<Profile onLogout={logout} />}
                    />
                    <Route path="/leaderboard" element={<Leaderboard />} />
                    <Route path="*" element={<NotFound />} />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        redirectPath="/welcome"
                        isAllowed={
                          (user && !user.loading && user.user) || isNotFirstTime
                        }
                        isLoading={user && user.loading}
                      />
                    }
                  >
                    <Route path="/" element={<Body />} />
                  </Route>
                </Routes>
              </Layout>
            </CurrentLevelContext.Provider>
          </ProgressContext.Provider>
        </UserContext.Provider>
      </Router>
    </ConfigProvider>
  );
};

export default App;
