import { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { magic } from "../../lib/magic";
import { UserContext } from "../../lib/UserContext";
import { Typography } from "antd";
import EmailForm from "./components/EmailForm";
import Footer from "../footer/Footer";
import "./login.scss";
import SocialLogins from "./components/SocialLogins";
import ProgressContext from "../../lib/ProgressContext";
import {
  updateUserName,
  getCurrentUser,
  updateUserScore,
} from "../../store/user";
import Navigation from "../navigation/Navigation";
import Loading from "../loading/Loading";

const { Title } = Typography;

const Login = ({ isSignUp, checkCurrentLevel }) => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [isSignUpPage, setIsSignUpPage] = useState(true);
  const [progress, setProgress] = useContext(ProgressContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsSignUpPage(isSignUp);
  }, [isSignUp]);

  useEffect(() => {
    user && user.issuer && navigate("/");
  }, [user, navigate]);

  const getCommonScore = () => {
    let scoreCommon = 0;
    progress.forEach((element) => {
      scoreCommon += element.score;
    });

    return scoreCommon;
  };

  async function handleLoginWithSocial(provider) {
    await magic.oauth.loginWithRedirect({
      provider,
      redirectURI: new URL(
        `/callback?isSignUp=${isSignUpPage}`,
        window.location.origin
      ).href, // required redirect to finish social login
    });
  }

  async function handleLoginWithEmail(username, email) {
    try {
      setDisabled(true);
      setIsLoading(true);
      let didToken = await magic.auth.loginWithMagicLink({
        email,
      });
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + didToken,
        },
      });
      if (res.status === 200) {
        res.json().then((data) => {
          localStorage.setItem("gtp-riddle_auth", data.token);
        });
        // Set the UserContext to the now logged in user
        await magic.user.getMetadata();
        try {
          if (isSignUp) {
            const response = await getCurrentUser();
            if (!response.progress.length) {
              updateUserName(username)
                .then((res) => {
                  res.progress = progress;
                  setUser({
                    user: res,
                  });

                  navigate("/");
                })
                .then(async () => {
                  await updateUserScore(getCommonScore());
                  navigate("/");
                });
            } else {
              setProgress(response.progress);
              checkCurrentLevel(response.progress);
              setUser({
                user: response,
              });
              navigate("/");
            }
          } else {
            onLogin();
          }
        } catch {
          onLogin();
        }
      } else {
        setDisabled(false);
      }
    } catch (error) {
      setDisabled(false);
      setIsLoading(false);
      console.log(error.data);
    }
  }

  async function onLogin() {
    try {
      const res = await getCurrentUser();
      setUser({
        user: res,
      });

      if (res.progress.length) {
        setProgress(res.progress);
        checkCurrentLevel(res.progress);
      } else {
        if (!progress.length) {
          setProgress([
            {
              isWon: false,
              isInProgress: true,
              messages: [],
              symbols: 0,
              cluesUsed: 0,
              score: 0,
            },
          ]);
        }
        checkCurrentLevel(progress);
        await updateUserScore(getCommonScore());
      }

      navigate("/");
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  return (
    <>
      {!isLoading ? (
        <>
          <Navigation isLoginHeader={true} />
          <div className="login">
            <div className="login-box">
              <Title level={1} className="login__name">
                {isSignUpPage ? "Sign up" : "Log in"}
              </Title>
              <p>
                Enter your email and we will send you a magic link to register
                or log in.
              </p>
              <EmailForm
                disabled={disabled}
                onEmailSubmit={handleLoginWithEmail}
                isSignUpPage={isSignUpPage}
              />
              <SocialLogins
                isSignUpPage={isSignUpPage}
                onSubmit={handleLoginWithSocial}
              />

              <p className="login__action">
                {isSignUpPage
                  ? "Already have an account? "
                  : "Don’t have an account? "}
                {isSignUpPage ? (
                  <Link className="login__link" to="/login">
                    Log in
                  </Link>
                ) : (
                  <Link className="login__link" to="/signup">
                    Sign up
                  </Link>
                )}
              </p>
            </div>
            <Footer />
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Login;
