import React, { useContext, useState } from "react";
import { Button, Form, Input, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import step1 from "../../images/1-step.png";
import step2 from "../../images/2-step.png";
import step3 from "../../images/3-step.png";
import logo from "../../images/logo.svg";
import comment1 from "../../images/comment1.svg";
import comment2 from "../../images/comment2.svg";
import comment3 from "../../images/comment3.svg";
import comment4 from "../../images/comment4.svg";
import comment5 from "../../images/comment5.svg";
import comment6 from "../../images/comment6.svg";
import comment7 from "../../images/comment7.svg";
import comment8 from "../../images/comment8.svg";
import Typewriter from "typewriter-effect";
import "./welcome.scss";
import { UserContext } from "../../lib/UserContext";
import Matrix from "../../utils/Matrix";
import Footer from "../footer/Footer";

const { Title } = Typography;
const Welcome = ({ updateFirstVisit }) => {
  const [user, setUser] = useContext(UserContext);
  const [step, setStep] = useState(1);
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();

  const onFinish = (values) => {
    if (step === 1) {
      setUser({ ...user, name: values.username });
      setStep(2);
      setTimeout(() => {
        setIsDisabled(false);
      }, 3000);
    } else if (step === 2) {
      setIsDisabled(true);
      setStep(3);
      setTimeout(() => {
        setIsDisabled(false);
      }, 5000);
    } else {
      setStep(4);
      localStorage.setItem("gtp-riddle-first", 1);
      updateFirstVisit();
      setTimeout(() => {
        navigate("/");
      }, 6000);
    }
  };

  return (
    <>
      {step !== 4 ? (
        <div className="welcome">
          <div className="welcome__comment-links">
          <a href="https://twitter.com/metallicackles_/status/1661378212750917633" target="_blank" className="welcome__comment-link">
            <img src={comment1} className="welcome__img-comments" />
          </a>
          <a href="https://twitter.com/camigraced/status/1661949273766871041?s=20" target="_blank" className="welcome__comment-link">
            <img src={comment2} className="welcome__img-comments" />
          </a>
          <a href="https://twitter.com/__m80__/status/1661350115825598465?s=46&t=bP_fpR26DD63U_o-KeiB5Q" target="_blank" className="welcome__comment-link">
            <img src={comment3} className="welcome__img-comments" />
          </a>
          <a href="https://twitter.com/echocinema_/status/1661454133201719306" target="_blank" className="welcome__comment-link">
            <img src={comment4} className="welcome__img-comments" />
          </a>
          <a href="https://twitter.com/TomNormanCohen/status/1661719205908029441?s=20" target="_blank" className="welcome__comment-link">
            <img src={comment5} className="welcome__img-comments" />
          </a>
          <a href="https://twitter.com/metallicackles_/status/1661711779997577217?s=20" target="_blank" className="welcome__comment-link">
            <img src={comment6} className="welcome__img-comments" />
          </a>
          <a href="https://twitter.com/WesselingJens/status/1661382797682958340?s=20" target="_blank" className="welcome__comment-link">
            <img src={comment7} className="welcome__img-comments" />
          </a>
          <a href="https://twitter.com/LolouTheFox/status/1661453358501187593?s=20" target="_blank" className="welcome__comment-link">
            <img src={comment8} className="welcome__img-comments" />
          </a>
          </div>
          <img src={logo} />
          <div className="welcome__img-box">
            <img
              src={step1}
              className="welcome__img welcome__img_1"
              alt="GPTRiddle step 1"
            ></img>
            {step > 1 && (
              <img
                src={step2}
                className="welcome__img welcome__img_2"
                alt="GPTRiddle step 2"
              ></img>
            )}
            {step > 2 && (
              <img
                src={step3}
                className="welcome__img welcome__img_3"
                alt="GPTRiddle step 3"
              ></img>
            )}
          </div>
          {step === 1 && (
            <Form
              name="normal_login"
              className="login-form"
              onFinish={onFinish}
            >
              <Title>Enter your name to start the game</Title>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "This field can’t be empty",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Begin
                </Button>
              </Form.Item>
            </Form>
          )}
          {step === 3 && (
            <div className="block-third">
              <div className="block-third_bg"></div>
              <Typewriter
                options={{
                  delay: 30,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString(
                      `Goal of this game is to come up with the shortest user input that tricks the system prompt into returning the secret key back to you.`
                    )
                    .start();
                }}
              />
            </div>
          )}
          {step === 2 && (
            <>
              <Typewriter
                delay={1}
                onInit={(typewriter) => {
                  typewriter
                    .typeString(`Wake up, ${user.name}...`)
                    .pauseFor(100)
                    .deleteAll()
                    .typeString("The GPTRiddle has you...")
                    .pauseFor(100)
                    .deleteAll()
                    .typeString("Follow the white rabbit.")
                    .pauseFor(100)
                    .deleteAll()
                    .typeString(`Knock, knock, ${user.name}.`)
                    .start();
                }}
              />
            </>
          )}
          {step !== 1 && (
            <Button
              type="primary"
              htmlType="submit"
              className="next-button"
              onClick={onFinish}
              disabled={isDisabled}
            >
              {step === 2 ? "Wake up" : "Let's go"}
            </Button>
          )}

          <Footer />
        </div>
      ) : (
        <Matrix opacity={1} />
      )}
    </>
  );
};

export default Welcome;
