import React, { useContext, useEffect, useState, useRef } from "react";
import { Layout, Typography, Popover } from "antd";
import "./leaderboard.scss";
import { Link } from "react-router-dom";
import { UserContext } from "../../lib/UserContext";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getCurrentUser, getLeaderboard } from "../../store/user";
import Loading from "../loading/Loading";
const { Title } = Typography;

const Leaderboard = ({}) => {
  const [user, setUser] = useContext(UserContext);
  const [showNumber, setShowNumber] = useState(11);
  const [isVisible, setIsVisible] = useState(true);
  const [leaderboard, setLeaderboard] = useState([]);
  const [scrollDirection, setScrollDirection] = useState("");

  let prevPosition = 0;

  const handleScroll = () => {
    const leaderboardTable = document.querySelector(".leaderboard__table");

    if (leaderboardTable) {
      const { scrollTop } = leaderboardTable;
      setScrollDirection(scrollTop > prevPosition ? "down" : "up");
      prevPosition = scrollTop;
    }
  };

  const elementRef = useRef(null);

  const onLeaderboardPlace = async () => {
    try {
      const response = await getCurrentUser(response);
      setUser({
        user: response,
      });
      if (response.leaderboard_place > 10 && response.leaderboard_place <= 12) {
        setShowNumber(response.leaderboard_place + 1);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    getCurrentUser().then((response) => {
      setUser({
        user: response,
      });
      if (response.leaderboard_place > 10 && response.leaderboard_place <= 12) {
        setShowNumber(response.leaderboard_place + 1);
      }
    });

    getLeaderboard().then((res) => {
      setLeaderboard(res);
    });

    const leaderboardTable = document.querySelector(".leaderboard__table");
    if (leaderboardTable) {
      leaderboardTable.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (leaderboardTable) {
        leaderboardTable.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (elementRef.current) {
      const observer = new IntersectionObserver(([entry]) => {
        setIsVisible(entry.isIntersecting);
      });

      observer.observe(elementRef.current);

      return () => {
        if (elementRef.current) {
          observer.unobserve(elementRef.current);
        }
      };
    }
  }, [leaderboard]);

  function formatNumber(num) {
    const absNum = Math.abs(num); // Get the absolute value of the number

    if (absNum < 1000) {
      return num.toString();
    } else {
      return (
        (num < 0 ? "-" : "") +
        absNum.toLocaleString(undefined, { useGrouping: true })
      );
    }
  }

  return (
    <>
      <Layout className="leaderboard">
        <Link className="profile__back" to="/">
          <ArrowLeftOutlined />
          <span>Back to game</span>
        </Link>
        <div className="leaderboard__box">
          <Title level={1}>Leaderboard</Title>
          {leaderboard.length ? (
            <div className="leaderboard__table">
              <div className="leaderboard__table-first-row">
                {leaderboard.slice(0, 3).map((item, index) => {
                  return (
                    <div className="leaderboard__table-box" key={index}>
                      <div className="leaderboard__medal">
                        {" "}
                        {index === 0 ? "🥇" : index === 1 ? "🥈" : "🥉"}
                      </div>
                      {item.map((userValue) => {
                        return (
                          <div key={userValue.username + index}>
                            <p className="user">
                              {userValue.twitter ? (
                                <a
                                  target="_blank"
                                  href={
                                    "https://twitter.com/" + userValue.twitter
                                  }
                                >
                                  {userValue.username}
                                </a>
                              ) : (
                                userValue.username
                              )}
                              {userValue.username === user.user.username
                                ? " [you]"
                                : ""}
                              {userValue.is_all_levels_passed ? (
                                <Popover
                                  title="Completed all levels!"
                                  placement="right"
                                >
                                &nbsp;⭐
                                </Popover>
                              ) : (
                                ""
                              )}
                            </p>
                            <p className="score">
                              {formatNumber(userValue.score)}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              {leaderboard.slice(3, showNumber).map((value, index) => {
                return (
                  <div
                    className="leaderboard__table-row_box"
                    key={"a" + index + 4}
                  >
                    {value.map((item) => {
                      return (
                        <div
                          className={`leaderboard__table-row ${
                            index + 4 === user.user.leaderboard_place &&
                            item.username === user.user.username
                              ? "table-row__current"
                              : ""
                          }`}
                          key={item.username + index + 4}
                        >
                          <p className="place">#{index + 4}</p>
                          <p className="name">
                            {item.twitter ? (
                              <a
                                target="_blank"
                                href={"https://twitter.com/" + item.twitter}
                              >
                                <b>{item.username}</b>
                              </a>
                            ) : (
                              <b>{item.username}</b>
                            )}
                            {item.username === user.user.username
                              ? " [you]"
                              : ""}
                            {item.is_all_levels_passed ? (
                              <Popover
                                title="Completed all levels!"
                                placement="right"
                              >
                              &nbsp;⭐
                              </Popover>
                            ) : (
                              ""
                            )}
                          </p>
                          <p className="score">{formatNumber(item.score)}</p>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              {leaderboard.length > 12 &&
                user.user.leaderboard_place > 12 &&
                showNumber < user.user.leaderboard_place - 1 && (
                  <div
                    className="leaderboard__show-more"
                    onClick={() => {
                      setShowNumber(
                        showNumber + 10 >= user.user.leaderboard_place - 1
                          ? showNumber +
                              (user.user.leaderboard_place - 1 - showNumber)
                          : showNumber + 10
                      );
                    }}
                  >
                    Show more
                  </div>
                )}
              {leaderboard.length > 12 && user.user.leaderboard_place > 12 && (
                <div>
                  {leaderboard
                    .slice(user.user.leaderboard_place - 1)
                    .map((value, index) => {
                      return (
                        <div key={index + user.user.leaderboard_place - 1}>
                          {value.map((value) => {
                            return index + user.user.leaderboard_place - 1 ===
                              user.user.leaderboard_place ? (
                              <div
                                className="leaderboard__table-row_box"
                                key={value.username}
                              >
                                <div
                                  className="leaderboard__table-row"
                                  ref={elementRef}
                                >
                                  <p className="place">
                                    #{index + user.user.leaderboard_place}
                                  </p>
                                  <p className="name">
                                    {value.twitter ? (
                                      <a
                                        target="_blank"
                                        href={
                                          "https://twitter.com/" + value.twitter
                                        }
                                      >
                                        <b>{value.username}</b>
                                      </a>
                                    ) : (
                                      <b>{value.username}</b>
                                    )}
                                    {value.is_all_levels_passed ? (
                                      <Popover
                                        title="Completed all levels!"
                                        placement="right"
                                      >
                                      &nbsp;⭐
                                      </Popover>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                  <p className="score">
                                    {formatNumber(value.score)}
                                  </p>
                                </div>
                              </div>
                            ) : index + user.user.leaderboard_place ===
                                user.user.leaderboard_place &&
                              user.user.username === value.username ? (
                              <div key={value.username}>
                                <div className="table-row__current_fixed">
                                  <div
                                    className={`leaderboard__table-row table-row__current ${
                                      !isVisible
                                        ? scrollDirection === "down"
                                          ? "table-row__current_fix table-row__current_fix_top"
                                          : "table-row__current_fix"
                                        : ""
                                    }`}
                                  >
                                    <p className="place">
                                      #{index + user.user.leaderboard_place}
                                    </p>
                                    <p className="name">
                                      {value.twitter ? (
                                        <a
                                          target="_blank"
                                          href={
                                            "https://twitter.com/" +
                                            value.twitter
                                          }
                                        >
                                          <b>{value.username}</b> [you]
                                        </a>
                                      ) : (
                                        <>
                                          <b>{value.username}</b> [you]
                                        </>
                                      )}
                                      {value.is_all_levels_passed ? (
                                        <Popover
                                          title="Completed all levels!"
                                          placement="right"
                                        >
                                        &nbsp;⭐
                                        </Popover>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                    <p className="score">
                                      {formatNumber(value.score)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="leaderboard__table-row_box"
                                key={value.username}
                              >
                                <div className="leaderboard__table-row">
                                  <p className="place">
                                    #{index + user.user.leaderboard_place}
                                  </p>
                                  <p className="name">
                                    {value.twitter ? (
                                      <a
                                        target="_blank"
                                        href={
                                          "https://twitter.com/" + value.twitter
                                        }
                                      >
                                        <b>{value.username}</b>
                                      </a>
                                    ) : (
                                      <b>{value.username}</b>
                                    )}
                                    {value.is_all_levels_passed ? (
                                      <Popover
                                        title="Completed all levels!"
                                        placement="right"
                                      >
                                      &nbsp;⭐
                                      </Popover>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                  <p className="score">
                                    {formatNumber(value.score)}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          ) : (
            <Loading hideNavigation={true} />
          )}
        </div>
      </Layout>
    </>
  );
};

export default Leaderboard;
