import React from "react";
import { Layout } from "antd";
import { Outlet, Navigate } from "react-router-dom";
import Loading from "../components/loading/Loading";
import Navigation from "../components/navigation/Navigation";

function LayoutCreate({}) {
  return (
    <Layout className="main">
      <Navigation />
      <Outlet />
    </Layout>
  );
}

const ProtectedRoute = ({ isAllowed, isLoading, redirectPath, children }) => {
  if (isLoading) {
    return <Loading />;
  } else {
    if (!isAllowed) {
      return <Navigate to={redirectPath} replace />;
    } else {
      return children ? children : <LayoutCreate />;
    }
  }
};

export default ProtectedRoute;
