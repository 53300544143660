import api from "../../utils/api";

export const getCurrentUser = async () => {
  return api
    .get("/users/me")
    .then((response) => {
      return response.data.user;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateUserProgress = async (progress) => {
  return api
    .patch("/users/me", {
      progress: progress,
    })
    .then((response) => {
      return response.data.user;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateUserScore = async (score) => {
  return api
    .patch("/users/me", {
      score: score,
    })
    .then((response) => {
      return response.data.user;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getLeaderboardPlace = async (score) => {
  return api
    .get("/unauth/leaderboard/place?score=" + score)
    .then((response) => {
      return response.data.place;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getLeaderboard = async () => {
  return api
    .get("/unauth/leaderboard")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const checkUserName = async (username) => {
  return api
    .post("/unauth/username", {
      username: username,
    })
    .then((response) => {
      return response.data.usernameIsFree;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateUserName = async (username) => {
  return api
    .patch("/users/me", {
      username: username,
    })
    .then((response) => {
      return response.data.user;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateUserIsAllLevelsPassed = async () => {
  return api
    .patch("/users/me", {
      is_all_levels_passed: true,
    })
    .then((response) => {
      return response.data.user;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateUserClues = async (clues) => {
  return api
    .patch("/users/me", {
      clues: clues,
    })
    .then((response) => {
      return response.data.user;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateUserModel = async (model) => {
  return api
    .patch("/users/me", {
      model: model,
    })
    .then((response) => {
      return response.data.user;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateUserTwitter = async (username) => {
  return api
    .patch("/users/me", {
      twitter: username,
    })
    .then((response) => {
      return response.data.user;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getPayment = async (amount) => {
  return api
    .get(`/payment/clues?count=${amount}`)
    .then((response) => {
      return response.data.url;
    })
    .catch((error) => {
      return error;
    });
};

export const unlockLastModel = async () => {
  return api
    .get('/payment/premium')
    .then((response) => {
      return response.data.url;
    })
    .catch((error) => {
      return error;
    });
};
