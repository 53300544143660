import React, { useContext, useEffect, useState } from "react";
import "./navigation.scss";
import { Layout, Select, Space } from "antd";
import {
  TrophyOutlined,
  UserOutlined,
  CheckCircleOutlined,
  MessageOutlined,
  LockOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import logo from "../../images/logo-green.svg";
import logoSmall from "../../images/logo-small.svg";
import { UserContext } from "../../lib/UserContext";
import ProgressContext from "../../lib/ProgressContext";
import CurrentLevelContext from "../../lib/CurrentLevelContext";
import { Link, useLocation } from "react-router-dom";
import { levelsList } from "../../utils/levels";
import { useMediaQuery } from "react-responsive";

const { Header } = Layout;
const { Option } = Select;

const Navigation = ({ isLoading }) => {
  const [user, setUser] = useContext(UserContext);
  const [progress, setProgress] = useContext(ProgressContext);
  const [currentLevel, setCurrentLevel] = useContext(CurrentLevelContext);
  const [score, setScore] = useState(0);

  useEffect(() => {
    if (progress.length) {
      let scoreVal = 0;
      progress.forEach((element) => {
        scoreVal += element.score;
      });

      setScore(scoreVal);
    }
  }, [progress]);

  const location = useLocation();
  const isMobile = useMediaQuery({
    query: "(max-width: 980px)",
  });

  const handleChange = (value) => {
    setCurrentLevel(value);
  };

  const isFullHeader = !location.pathname.match(
    /\/(login|signup|profile|leaderboard)$/
  );
  const isLogoHidden = location.pathname.match(/\/(profile|leaderboard)$/);
  const isAuthHeader = !location.pathname.match(/\/(login|signup)$/);

  const checklevel = (index) => {
    if (progress?.[index]?.isWon) {
      return "completed";
    } else if (progress?.[index]?.isInProgress) {
      return "inprogress";
    } else {
      return "locked";
    }
  };

  function formatNumber(num) {
    const absNum = Math.abs(num); // Get the absolute value of the number

    if (absNum < 1000) {
      return num.toString();
    } else {
      return (
        (num < 0 ? "-" : "") +
        absNum.toLocaleString(undefined, { useGrouping: true })
      );
    }
  }

  return (
    <Header
      className={`header ${isFullHeader && !isLoading ? "" : "header_short"} ${
        isLogoHidden && !isLoading ? "header_hidden" : ""
      } ${user?.user ? "header__small" : ""}`}
      style={{
        width: "100%",
      }}
    >
      <Link
        to="/"
        className={`header__logo  ${
          isFullHeader && !isLoading && user && user.user
            ? "header__logo_small"
            : ""
        } ${isLoading ? "header__logo_loading" : ""}`}
      >
        <img
          alt="Logo"
          src={
            isFullHeader && !isLoading && user && user.user ? logoSmall : logo
          }
        />
      </Link>
      {isFullHeader && !isLoading && (
        <>
          <Select
            defaultValue={currentLevel}
            onChange={handleChange}
            style={{
              width: 282,
            }}
            listHeight={isMobile ? window.innerHeight - 200 : 440}
            optionLabelProp="label"
            value={currentLevel}
          >
            {levelsList.map((item, index) => {
              return (
                <Option
                  value={index}
                  label={` Level #${index}: ${item.levelName}`}
                  disabled={checklevel(index) === "locked"}
                  key={index}
                >
                  <Space>
                    {checklevel(index) === "locked" ? (
                      <LockOutlined />
                    ) : checklevel(index) === "inprogress" ? (
                      <MessageOutlined />
                    ) : (
                      <CheckCircleOutlined />
                    )}
                    {`Level #${index}: ${item.levelName}`}
                  </Space>
                  {checklevel(index) === "completed" && (
                    <span className="option__items">
                      {formatNumber(progress[index].score)}
                    </span>
                  )}
                </Option>
              );
            })}
          </Select>
        </>
      )}
      {user?.user && (
        <Link to="/" className="header__score">
          {formatNumber(score)}
        </Link>
      )}

      {isAuthHeader &&
        !isLoading &&
        (user?.user ? (
          <Link
            to="/leaderboard"
            className="header__btn header__btn_leaderboard"
          >
            <TrophyOutlined />
            <span className="header__btn_title">Leaderboard</span>
          </Link>
        ) : (
          <p className="header__note">Save progress & access leaderboard!</p>
        ))}

      {isAuthHeader &&
        !isLoading &&
        (user?.user ? (
          <Link to="/profile" className="header__btn">
            <UserOutlined />
            <span className="header__btn_title">{user.user.username}&nbsp;</span>
            {user.user.is_all_levels_passed && "⭐"}
          </Link>
        ) : (
          <>
            <Link to="/signup" className="header__signup">
              Sign up
            </Link>
            <Link to="/login" className="header__login">
              <LoginOutlined />
              <span>Log in</span>
            </Link>
          </>
        ))}
    </Header>
  );
};

export default Navigation;
