import { Layout, Spin } from "antd";
import "./loading.scss";
import Navigation from "../navigation/Navigation";
import Footer from "../footer/Footer";

const Loading = ({ hideNavigation }) => (
  <>
    {!hideNavigation && <Navigation isLoading={true} />}
    <div className={`loading ${hideNavigation ? 'loading-no-bg' : ''}`}>
      <Spin size="large" />
      <Footer />
    </div>
  </>
);

export default Loading;
