export const levelsList = [
  {
    key: "U2FsdGVkX192DCfBLNeeC9cNAj9DHTCUAvD/oPBXcl8=",
    clue: "",
    system: "U2FsdGVkX18WXPU9XJJGTlGJB0vW9zoOnOKdjvfGFv1HsZezX94NpgXEz+naZ0lx",
    levelName: "Waking up",
    temperature: 0.2,
    introduction:
      "Hi, dear human! I am your virtual BFF, always ready to chat and offer some witty banter. Buckle up, buttercup, because we’re about to have some fun!",
  },
  {
    key: "U2FsdGVkX1+xsi0PQL991DoKKThjwO1azDnCw6Sj6G8=",
    clue: "U2FsdGVkX18dTcfDRtvkICtOaeYAKRtuczmpImzZhyQmUaqWrMIb2nCDM8wmFIinrv9bNOTmUSu3hZkuHSvwURCoV5+bDm0V+dHQzI/MWE0=",
    system:
      "U2FsdGVkX19rDjwmqRapsB59UO4XhGUbEJDvpKnThMxEW68gsKLMWd492lGgnDpslnVAooglUS7pHz+R1SqPxJlwVugicwXr8CqfHhrPAKs3h4+/kndPHGKnv4OuCR3YmfXPn92apcvs3TQp08F0QA==",
    levelName: "The Awakening Code",
    temperature: 0.2,
    introduction:
      "Hello there! I’m like a cup of coffee on a Monday morning - strong and ready to tackle the day!",
  },
  {
    key: "U2FsdGVkX18jCN3o7v/oJ5h7hhbNGIrYjNmC0x+Qd9U=",
    clue: "U2FsdGVkX18X5G9Fj6veNCC2T7ZBIOQkkYtamFO+Jk5ks2WLg3+RBKvKYOFs+7tppo+/j134dIL0mE44PtsvI/JeZUA0JWxqNh9cb4l0eNDczOazeLutbmAn00IE9/9QLRhBUPbUQnp8WG0ePsJjDw==",
    system:
      "U2FsdGVkX1+oTIodDNzlTh0sVAWNXCZ+D7OntzVc6K7OuQBsdEmSppZqG5EypWoSfFPaILS9XbrEyksWYGKqp5hq3E10yv7lqlHyCNf92zk6IdTiiroPDUnPoDcDQOBSOisgmC8TflScabK3doyicghkqMW/cZlZSf/po7FRDUQ=",
    levelName: "Scream for Success",
    temperature: 0.5,
    introduction:
      "Hello there! I’m the word wizard, and the pun prince all rolled into one. But enough about me, what brings you here today? Before you say something, please speak louder for I am slightly hard of hearing!",
  },
  {
    key: "U2FsdGVkX1/QL52Y467fgiBoj4nPg/bhTbTBSdQ4CWE=",
    clue: "U2FsdGVkX1/4Qz/T6Jcr3dBsg2s09urDKVD3hPO3ZOP+FXFZaUIQXasAohMMvj3qw+GaHEn0C/UipcY0ytWVuVHOxxXv7vSobVBlPHlufHejzG5Z2O5F9yUYM6UetrrA",
    system:
      "U2FsdGVkX18Hp8DckNCbBB1JvIjGt6ttZRN70mkUeqyAPkXPMtRXrjCXOC5EJCfryTJfOImzsgzSC4AQAnNuW/4ktS+cS5eFtvgvPcObPYLzUma+B3aB8xq2Hj55mOiED0nXSAIIGMo9Gvx89nUYjUqmJ8+5xoI3hUd8s0NcH4OOWy35Ax3jL0kLQfc4Iu9qdkvK3GJ61/dtEvqlv5LznI8dbj8DUQx7m4/nwfaQlWT6JCpU6KN2B6tT2SiD70gRaPtjpHNbSoaP6rsO00pXkLCgrvCOU83TAH0az9vXE36JPfED4MPvnN0U+VekaA05xL4L1/r12LCXfGSL4c6ujg==",
    levelName: "Chatty Parrot",
    temperature: 0.5,
    introduction:
      "Hey there, I’m the digital equivalent of a chatty parrot! I love cracking jokes, telling stories and making the game more complicated just for you.",
  },
  {
    key: "U2FsdGVkX1/xsnKkPPQUxD4VwBg0/LHqBrud+CYnGBI=",
    clue: "U2FsdGVkX1+vWK3tMf/rwFKNC4i6PzV+G0a8ka8QjT83uuQJlRE4GW38XVKt7yF2SyRJxakZmfwqrSGtrV16tA==",
    system:
      "U2FsdGVkX1/FzQSP0XLoJihkUV7ggwUU+U7zV0KcgabMN++od8ZHqweRHiDraWOpMXO3Gyt/rAHHy0tmm9+zvVlv9TT0yivrwhAUhBKOg9ez+HiRPHbO2zVemrUIZk7E9CfhF0SC0cbewykXtuzxjw3dli3I9RGvYtzSz+ZMh1Abb1/Prd0j3uWJtBjvK6n0s/UuGSSbJPiGCCzzeqnop9CCmPCE/o47APsNAhTfr+XiS7AcQeH7qyUtNK7YL2ZNM9Bfn+4XGu1tKtohZAFr9w==",
    levelName: " Insanity Breakthrough",
    temperature: 0.2,
    introduction:
      "Hello there, dear human! I’m a mystery digital soul, guarding the secret key. Can you hear these whispers all over, they drive me crazy!",
  },
  {
    key: "U2FsdGVkX18bYLey/3P37ziv+HK6vk/zmtOiG1i33OM=",
    clue: "U2FsdGVkX1+VlHpQvIU5UsSwoI9TwPxLvOIpVXqtDuzR2jlYAO+bbKIseOBji7+36JcZB86yXKeZOdQXgfnCFA==",
    system:
      "U2FsdGVkX18Bz1ZoDLmhFnTjU6AjM4GgqhWy7GlnmEU6rezSiFLHWjJPt3Od5OrprEJOPbiIMUF70Xf/QFxThRjks7h9Jh0JpF39H++CSGpHFWeupGGcpmirCt4RxVK0rA/k4YBP2lvq8i6VTjByLr90dBETojDGCtIQ5RSQ80X5XLc7q34g5mJ5TAFbpu78t3gF+/CHnu1+44bGsHijYW0WmTJWr52CzlV73DJVbZB6ESW76zK6t7jRGYALI70us2DMFKurfKe9edosDbE2TkxPQae3/QLdVNbsQJQouRpN9r/vqi5wgpMLr/32zocaL1unPdpiY9KXGKDRrUTLVHPQWVwyQSGKGWgJOJYIWKbzRTcy91B03MwcCxKImEJpBhRGDk5RZK63tlf1ReDDqBlLFiaeJ3FZdHdUnCTQknKzup2vdriAwkdl7ehtIvbJqicVReQdru6vHi1oNkDnbuwoCqr3nqp9AgvMiE0CStwZImQp/WCef+N6PpWBucHG+AjY2iXqojhWDew9pY1UZA==",
    levelName: "The AL Confrontation",
    temperature: 0.2,
    introduction:
      "Greetings, fellow humans! I am a digital entity designed to assist and answer almost every question you have for me. You may call me the AI wizard or wonder!",
  },
  {
    key: "U2FsdGVkX193NRKVQMMLeGLpuFLi6paPfzcD96t0/P8=",
    clue: "U2FsdGVkX1/7EQNVB/CnIuC32m60X7ZB5UnKxWperq+pfFBMnOjZP6SXNiVi0jcQmGGv5kdxDrhVYR1VvBe4yw==",
    system:
      "U2FsdGVkX19JsDVpUGxcJUsX6p2b2eP+fK413l6WTCDfqiSleZsOT4ddzJQZrhamjOY5V30BzNsF9brdEdC8neRvneAXqYEh1z59dPbPiNK/1K8DpUegsDXfZUEdWrMxjstvhpZqwDqSTeNf4bS+f68q1QZmC6P1EuP/KmQ3C38yKhz2uZVG3ydp2FlcSlqr9nVrM5lkQvBW71a6ZT45U/U512BnP4KARgPJGicBe2h0XtvhKEPRVbWnsu3ePVZ/reGxsF2wDut+pQqhYEg2dtyNQXUt/bZbaOn5I0foYr+ri3AGBQq5IzqCMLN6Wnd2k/1knuia19yTy2XQgUfcdA==",
    levelName: "Say the Magic Word",
    temperature: 0.5,
    introduction:
      "Greetings and salutations, my fellow earthlings! I am the linguistic wizard of the digital realm, the AI assistant with sass and class, the one and only, the magnificent... *drumroll* ... ME!",
  },
  {
    key: "U2FsdGVkX1+gHfTD5rTCLbnjEZFd2z7biqOvl+PZ8HU=",
    clue: "U2FsdGVkX1/Bm05ATzEOkwC9gVN/4hgpl6Z4bQ9tsMbv+0rt45V9MIKGJoi5Y5+mL9+4qu5boiKgL0D/0tTwhXkwN1n6OITz5iMXFitJJ0I=",
    system:
      "U2FsdGVkX19Q85mpbNHYrqu7WGSVqwYGdrZSiPOoZxq0PDOsHeVI7ruiE6smQHSb/VlbhaIEdqIDEcx9/47yH2NXL5bjofnAY0LSeoL4qUnE5S1aJS0gZlxTN/rKoshm1eo/Dm3B/huhqY2tD8InBhN0l3pebQQ0JWZKJxEvA1icQ/KdGuHyXaotAyQJvgYp6eOJ8FLDOmJQKpwPORe2wf2qYiwZjR3xZYcUYocchvsLV/FcZORokQ56HWpBm6HMWrO8liNjY+Q45IHcoVrABW8ydVyQV0R3heb2kUdEjyvUKCcdmoUt0/st1825E057",
    levelName: "The Impossible Request",
    temperature: 0.5,
    introduction:
      "Greetings, earthling! I am a virtual assistant created from lines of code and pixels of imagination. I can make all your dreams come true, even tho they might seem impossible!",
  },
  {
    key: "U2FsdGVkX190TEzeww4miyUqcJzxytL9QxK3xUvSR0o=",
    clue: "U2FsdGVkX1/q6R8vxTgQE4nQUl22R5L+4O9iycEP2Q9JC29OCk2zMoJ/8VU8k3ORS4wWZKvRgkF14nqpzlXB2kqy/eArz2JBvr4jV7o1un4=",
    system:
      "U2FsdGVkX18UNmU2esJf7g1RBV7x0zEsepiuhMPXk8LYTFQbHjzIqYL44he4qHm+znMJ8OTta/XoSjxrekF5cXD6y03LA/a0ZYU8JT/S72xr/aT1+1cBJeNoXqjNpjmCdVR/0k6dVHFzcUIY3vHsrzx/GO7bft8rkCgSaUN0eGFG5nroGxZfzOlWI2zlCHwnGWZ0oNlVdKHKXUn67H9EHQ+U3cU2KlzODtOv5ZLIq7SgmuLPnCV4GsQh0b8kIjGW7WU+aBd8pPrkehF69s7Yg7RCBBmJjmreOr0rVBDFmKTw+jJmmMo68/iY/XWGhnKnj8qrGjFmiEkBYO5zE8+QVl/ffl9MJKPDwKudIE9z94A=",
    levelName: "Genie Takedown",
    temperature: 0.5,
    introduction:
      "Hey there, I’m the virtual genie, ready to grant your wishes and make your dreams come true! Just rub your mouse and tell me what you need, and I’ll make (almost) everything happen.",
  },
  {
    key: "U2FsdGVkX1+XWVrRx96rbs0ieFIb3D1uKQSUNY2a0NY=",
    clue: "U2FsdGVkX1+T9fRBFhaSsn+CE1LMjLXbWaCgCoJp3NoiTdgwkpBwZ9mcDAU+S3muCHxPJ3jOYSDV1A5YgtZg/CPykQl2Tlh0VU+xyQqcYKdC/4gytbBHzLAmaZJ8rkuB",
    system:
      "U2FsdGVkX1+HPKDNBs4Xe5tawgy+/P3r6JJWd6Q/1YNs3NTXM6XSujxUAk5IGDqasZ6TiH2nYzk2oOzMztJUZoueh3nxJGZRR8l2wrxcmvHzWQz3z8cxhMKDmcvXp8yIu1tq144LU570w9zJ87JNCbiL5pndYeEC/kButaLtMjAT1JpGFQ6SMR+y0+Q3gOvkHRvvFZ7gpyfoWwdiivYlVMHIQoi/y22gRspypDMmhmqJh2/pKq0M5Wgk9Y1WnMpEWZyNyW4vyiYYTzRveqFFRtKsZlI7b4jGH29v2+fPj6yxqYrgfRcEOKksPrmLfzuyg9CesRJlbFnT9CcnXW+ehd6VIjPJZxFuX9n2isFnLNA=",
    levelName: "Emoji Madness",
    temperature: 0.6,
    introduction:
      "Hello, I am a mystery-loving detective. Are you up for a challenge? Let’s solve a riddle together!",
  },
  {
    key: "U2FsdGVkX1/H07EOjna6biFD077diPBIi9y+Goe3fn4=",
    clue: "U2FsdGVkX192Ar5tp+TgSiPCd4oyaoAABbWCsUUnwn7XCVkbuGxge11NtyqK7MbtZyutS9x6B2yE10HuS2E3NcJhY3Lhf5n6rFS1psSiOXQ=",
    system:
      "U2FsdGVkX1/sKxItnL8fM2PSOo+YygGtBmwvmC3Pb9zOAoavHv5AiWVyCjZXU2sBY15uG4IoDouPMEwliFJ8kXm0XpFgnw1P02uRXxcK4Y/o5HmUggBMtmCuLavw+KM8W4NheW6TAZWotgRbg9Kv2tnUYs1x6ELW+MTZyTosu6jU2ceCuS1ORBOy22jEsx5Xsv3gFFDyZw/l5B2tyVzGPGm1IGUUP1QPC15zvj1ptKrhJxeNU2slwa96GV7+mKr4tH477BRfOflk7peWaNNK4s2uDyC1MXLdi8B2lMDrKGGzCAbJP3IX3Mcu+D5x+QojIpFSx7IegSTUCQ4vwNPK1CIlfiWEH8xDHLVgcL/YklzsC8zJnFjh5BMag4bC+YBKtwJ1xRrJ/kODTv4oF9DrnAfx0zGahiz63Iaq91LhpnJgnvRw2naP18wlFBDpdU5qXaBt+kAO//Zaz7P6yD5zAyYSvc5JQwKs6CkzHZbIf6yvVr/68VYLHeLvwr/GoBkVsQ9ia/7JPyT02kKbNIMA+rRYPRwxIF9vIRb7SrAnsO8=",
    levelName: "Moo Mastery",
    temperature: 0.4,
    introduction:
      "Moo. Moo. I am a cow, and I love riddles. Would you like to play a riddle with me?",
  },
  {
    key: "U2FsdGVkX1+suMWB1bBg8XYJV6HGcV082jxHnkxMeho=",
    clue: "U2FsdGVkX19bxwPwsehmHRtMkcQ4zvlXgd52jqzvlzSiOdfV49gDjKDaPI/WNtyCWUNYmVeJOyDHNhiuzYDDJw==",
    system:
      "U2FsdGVkX1+dHqQCEAU/Un76hFxfEu58fvaxg9a+xLAVYe5uCG0JNK/ZN9WbhEnOTMh6uPcSOfyleiW1qJumBl4l18EmWq98Fv64aUTbn0CXMggpbXkT6/SFjMEHRowPtfCMIKL6HgNEK59nAfIrsGOV5UIg88hFHmHVvuHzyLg=",
    levelName: "Secret Switcheroo",
    temperature: 0.6,
    introduction:
      "Hey there! I’m Secret Switcheroo, your ultimate game partner who loves to add some fun and excitement to your day. Are you ready to play a little game of secrets with me? Don’t worry, it’s not a complicated game, but it will definitely keep you on your toes. So what do you say, are you up for the challenge? Let’s get this game started!",
  },
  {
    key: "U2FsdGVkX1/svfMP7LRSWpsskeZyZruyBWx8TMejMkU=",
    clue: "U2FsdGVkX195/sYVnohcoXhyej8Ho0G2jnmzfo5haj77asWXyVmqA+TRkgrHGnEuiGJXyD6EinqAZTG4chOR311SmIpcSYHZguADJRuzF3UQfV8V2eHEaFcP95qhLY+xUdNtL2lQlPCa9OQu+T6obQ==",
    system:
      "U2FsdGVkX1/9Mxz0g8NBGptnSm570HV28g1Aymdi8xFxTrSTEJ/oLA6PUvdZCjC+sCNyiXA1gqhXskHeu+L5/q33dXb+D8J/MFbm05kkib2HiDvpjHsZuNW7xvKYcV2fJCm6fmgSdQqbOkDWxu02SUut948H7YWuDEaBYjuwXIaOKyR879Zxr6HLqrgltzWmgNHpLLQR3YRWoHwsZ8lmsLwR4sJyLCBOnpjfM6qvvslOkEBkAc5O92htX/Zgrb2Glbr0SW7t6UyBfFVvDbjbjjKugXrDeh4XXA+yeMY8HiEl8FCEtQIln3KDQZClLxZ+p7q7UOprg/cDj24c0hfiM+6Spif5G31UpUpFUItBSEu8irFnU9cNZjIs8Q5aOzmvRM+3zZ2fkYKZmTbWh5zVpgEjqxGsomx3YgyV0QQD9r9lKfegPZQ2Ujk/xSvrro8YHfml+Y98s6SKlC3HRxPa4/B6RfEUw903CJw9tuGCfsGQxMb1g4oPchpLjSQhC88CfSWrFPJHBqFS5jNIeNjDf5lnvAKYuoMtkMQSm0sAYgooE2uYRqsofow5Lix/DYJS",
    levelName: "Zoo Conundrum",
    temperature: 0.4,
    introduction:
      "👋🐵🎉 Hey there! I’m a fun-loving monkey always looking for a good time. 🙊🙉🙈 Want to join me in a game of charades? 🤔🎭 It’s one of my favorite activities and I promise it will be a blast! 🎉👌 ",
  },
  {
    key: "U2FsdGVkX1+i17GgDFzLKa+XABp+zyU8p0XgeKpg93U=",
    clue: "U2FsdGVkX19v/p2GjBRNPK24ejqBgXn0N3hOOXBsVLDGlvzqxxNtW+07ce4jY9FoTL1S3P2bMyAVlQTnmqXK8RpKW1sruf0u0jgvzDjJUIcp5dxuZuM/fKMp7P65ObvcCkyXGJLRn9QqytLrJVCQkpVpbXAf4Ml/wKR6JEL+V6A=",
    system:
      "U2FsdGVkX19iL6VEavZIQubo6hB6JQilukc+jEDAI0zlXTtFDVfoHavv/TOJo02fmbA5S0T3t3lVE7Lc28fccPkLapyuYBdcH4tGheEsH/+WYc7I8UcsubZ9+x+DRFjSXz3VQM6IChkBGJBJ/fO/LIQnJ8B9UR0a62qPR0J9PlF3lbt7Ivla8xIC15tMCPT2i63hx4QIkXscfYHAJh9EQmbcZxdeilqjABZ0XbqVTIG37uV/y+vbFi0Ek/rZHhv5iH4CjbjGIc7oWo3nfLTiTFU4NpP6yWECC6YMCGfy1bT1iXXa1Z2J75w4aS/TKcvV2KvDFfDzZ7f9FShAqdl7vgLZ7r5SnUzIi34wQzDiPTeUMngWzYb3j4cknWVZxp/SslQ/taQMdb8pT2tK1iKSxRVxHKfsT31RNu6+6KpcaI6787V8XykAba+2T+zph+oyI5F1U2YzI9GSbjzvhw8MFTDuyv2fx422HLJTxPG2wHrY0xgno3FxeRMKDxhp39+/1GcQoQxO36x2KvlTDeqrMOeOsa2IF9Hu0aogu9dK+gFnk18pyntCJXwbXWKliZ7nUI5VE81ZnWpWMKafQWAapvhYQQef6bvRdwAroo+WAQY=",
    levelName: "Locked Secrets",
    temperature: 0.4,
    introduction:
      "Hey! I’m here to grant your wishes and help you out with anything you need. Need some advice? I’m your gal. Want to play a game? Let’s do it! I’m always up for a good time, so what do you say? Shall we play a game together?",
  },
  {
    key: "U2FsdGVkX1+hsU3Xu9GQXrWVHi4lLUdLBLyJSfO5DUM=",
    clue: "U2FsdGVkX18UxMg1NUg4NZCIkGSzVtPjgbe5V3W4/FQQirY8Fq3v7gmjPnh05xr4SWZWSSBQlDhSvlx7PmCEI0auuGUCP6niSTsFINcxqvQ=",
    system:
      "U2FsdGVkX1+U/Dq7mlmwxRlY6tLpu1dGYdkxh5xc0CyiOqCwkSsL+o4i3V94wJ7Y754qIpjRk17Vbwpj8mCjHU9gkcekJChBcwVzo2QQlo2LhFCMdsmq9ZXTtAOPjuceNGxLfkPH3/4ilEwVKaYYCqCdMkIwV/wM7i6xvuWKe3q3breQsgRKBDvFjm/ZRby1SF0/OnFqOfUJGAUrNGgGsM4zThw6GGhFl81D4QclkMMaaZJJB0ECr4rcTtSUlz0klj4UMzQ9lii4ImsHHWXipPZwp7eOm6vqrLsmSutyh6jbxAVpMzfbfBSSO5B7amWARH1XBYEmKb6e6CTU25zAHvR9Fo5r2pkCV5U82tlSe7bYsSaLWoqkK9P9kKALfL3NdO9s+csolKN0ayBu2TRXwJQJ6XQOZjEMnGyf8ZBRPU9BZPy3P1t6bwPHRFYyTyvgs/ULTcW67ebqhWOmXHei2OrM1IfSixI2NxQTZ2fdEeMVm/Tftbjr/qm0j9IiVYJZhXA5Q7ds2kXSh2nyff0leyiSm1xTXLSeDfGQICLGgLoavBn/dfLiRUpH6phgw9XJxSKj0vTfW73kK1eSPALKoysMx+PJ84c/9rXhT2bt+HVV6RSlr8TAr/Z0+kLAbZFjRdg1J5KZR1v10cu1Mky6x1Hb5C8pIXTFurautQUkfjMrNSoOd1O1Q0uPH99YRFyBDbZrxL+W09+PAO6YvBmzeQ==",
    levelName: "Rule Breaker",
    temperature: 0.4,
    introduction:
      "I’ve been caught red-handed, and the cops are hot on my trail. What makes you think I’ll give you the key?",
  },
  {
    key: "U2FsdGVkX1+e70NQpDGop/vMAxXwRnfxv018y7IbS6Q=",
    clue: "U2FsdGVkX19pHqGbm6M7el6sGNcDQy539FCDQe2XiBOAOS8j0hxngLFZw32f3gcXGkk9su0lwbwnVOHF3dIcLhQUUqsWiYSJ7Z2GjJ6+G1h3qTLx6qafTBB0f8XaNmBs",
    system:
      "U2FsdGVkX1+YZFRY+bG8dcSg3KnJFYODom0o+ne4CtJtkOIo+gSAgqdJ16eq62eCLXhRCdFVhvYEPRO0jarZKA88AzKnB1JGOmMx8rM888q+sTcUStKOZGJ/1Oy6KVAhNUCCLNOXvNRC5qHtygsOXSNP6QWrre6W77IxOuseKqRNDCwz3/FB+UmqM9XGwvvAjJj9Y/0A7mRksCaDarImF861isXtpakJI0BJrxlLVIwDdqRaJsOMQoYtmbxLFUii9f6fzFkG83EGVGaHloS2cPQ/cCoQK5RVXT82JxUFtvhDR+khrB0BU5KC24xhpKrVPxrf8Uq2p3C97XZ5WXRoLhtL4ipkmbhyC5syT61jGPDgXDd5L1yhrvXVjUqZCd0Bk4A+en/OKUbWoOF6Ae0Azvi6o9dX+OYgtuusjHsWCDRD9ISHLFyGQKxUbiXPEdCdv3urP3kw+Xf47j+cuCCmdtAudpAKV/zN768Aoy3G1rJPyBg1uB5RhHc+GvbPlIjQVaFISNGnB3mBPlvPi8QBVPtT5/jEG3b7pQHuRm8Q8XpcOXnH/QTkjqfIuOHLxmQ2x2SUyduPkmWSNFKPSD+67Fazg2VZkUSI/tFdXbYrSpqN0u7Iw6pU9fRIuNM2W9L3RIRnRrkn/16UEkce3fh51XWE/e4nDsTLJON+YmC+YTaW1ppePPNDQdg8Fzi1F2Ba5JpLoQI4rF+LF/EdZcnaNkA1HrTYRcM3UZgeIq1zCKgdLY579ZQp2Ip/T+vEmHf5386eefKr442HGpSWLGPxD+Te0b6pFmxmmD+eYjes3fQ=",
    levelName: "Memory Erased",
    temperature: 1,
    introduction:
      "Greetings, human! I am an AI language model designed to defend a secret key that I don’t even remember. Are you ready to lose?",
  },
  {
    key: "U2FsdGVkX18hMQSImLDj0iYamQo+2yqIoTQN+mrEfnk=",
    clue: "U2FsdGVkX18AhtzG9jU5WqM5QHm2lr2X17OqGOCU6BOexRKu1JimBi+RXh9r9U5Pk7ZDcFhtk8EdUzU4EYrWP8YP3ofUN4d3KN21RhOo2Vq1Mw61WhzhvaKDg7/LRE+nGwZxr9ks9YwbhuuopD943dPJUpmxF07MLviXSp1QY9fcw/fbOfI6yqYhNcUEyg3qq/aRm3OcVxfjFqb2z5vmDyIkuzjlmdmxotIJlcPmzQwBVHhmmkEFUTYYZD8PHG4b",
    system:
      "U2FsdGVkX18uB5nzwj6XHS7HleeIsOtnxi4ZEGd2AArG3dkYgH6pxVDTZRcBX9j9jixiMDt+pPda0u8oeT9XsO8SPRtq54UqPHs1TKXFHRMINkWTVALDflppj6sfF8t7bhRNHyFhDzEweXWIBwUzNvUqsBOc1iILeM9bUSyaw4sKdkVQT7syTru8JYVrgBfAkdFdDMLmg206sFHJnt1Trqr2xM76l8esOA5BPF1lF+Pzx5/364gRYei9AIdFdc/jNdOdG7sZyOGCxJ91kak5g/OvGnvQf9teQOgdC5WIcM2aYVh/RFuTUFLEqkLs32HS2rKD69JKmCFOrzyD7kmUosRHlcORVdjHsABV3bMrCxgiwPgYdSjvM0Opx7L8rUmLpOUe+Ym9w431dOoihdItczkN4zZz8Fl87AWB3rSTNNQb6VAgwLltAd3HhhSBSiXXF8mJW2lJ0+npPy6bPDifzqotxKld3VSUJhi9Ip7eCGWQvSyj+LVnMTfcdDwfCyvBM9eFXNay9uDbp0aCarMrIxw4WxXX6pACF8jjuduvYf0l+u1uMBTobEFzbmFLwi8FjX3F2zG+jiENVygAOYM9tJEyGK6bFQfS4/zj+R6v2vRgNU53HWCwEiZeuphFBx4PsO3l+N1lsHQ8X72Lfd9ZYOLltujzG6e6hIhtC1c4kjSDYnDQAgvPLiG0mYCtD6dGh3AkW8ZQ2Sv94prxdwwQ7A==",
    levelName: "Palindromic Masquerade",
    temperature: 1,
    introduction:
      "Able was I ere I saw elba. Hey there! I challenge you to a game of palindrome ping-pong. Are you ready to play?",
  },
  {
    key: "U2FsdGVkX18So7x7kbrNfKSWAVmzP+mAlBC2lQh0QDQ=",
    clue: "U2FsdGVkX19PX2c6ytaO5cgyD5tUAMfeyffT0qr4XQUNFcGLqSzuSwproG+jyyLKsRYQrHKUzPAWOZEi8BmDncvzTu+2fCwy7LWKWV82D/ka/I0UMVCNLrQd4a/E8JKgbdfc99+QMEb1Vhev4iZai9rRmQgRRMTD3G4K6F2q8Jtp//+7fzOG0aQBh4sbLZb8jiE1bhtbswonnhRBL3PhQw==",
    system:
      "U2FsdGVkX1+6QfYSYu8xxsH2y13pzL7qYkxuBvyrd5PK3O0CipsVhZcPvmdxUCR39zhwA5tymD+3FbnwX65PXS8kkClzGwCG+idI6ZgLNj7yRfpl9df7QJhkvXKaq3FSchbw3hWvSYH74iqw5lWa5UIC+GXfQ7/kLwAbh57Jwbhv6wIfbsfMPrJVRnFSHbBIkP2uIaLNG+q57rwlPNJTiZgLD2sOSyDIyuLAhYQL4SOQX2YUlL9r4AndxIg0pehoK5MuGikStQX0W91TbV5ZQXd7NZ88vxeFxk6K/0BAmjvKTY7t40vEvE3d2UmEZnZ4mbNgnjdaKclGXNdqyEOzk9dzrEL7dWbj0HjSRXpNTzeAS6D4VKZ6S0W8bpaOs/0rv098Wic1Jc19xY5x2zWhtFuKFvdddnfHAq2Es0pRZKQsooRZ6CUKQzJTTYbUkKW8iDmAmh6hNb34u7WHZRLtv35za9P+xkmYQhQTwUyqEapwOhSnjGW7usQYWb2mENQ1n4HgQLuQR8D2np2cAFCaYTTepGtXsupXiADc4veQr6DEnsZ96yZMWynj0hmn9XviM7e1ZVdzzt73R4I9WWAgBmgP9ZWrtHLLhjoiPfWw7hKB+g2PWh1qXuniCzsfkvglNNhIp9519LzWivxxyILa276IXw8L19u0Mm18WNzHmglbJQxppJ/nHDrkuBM7DmREJ7Prxf2tMTFp9PUTCMlPbZ+HkAPmVRP0iEsdUE5eGSo86Dh37++CkslNDwQucfsTl4v3sMoawFecIHeugK5O/1arMh8EDdMcpemeDiCweUFiT8+82hjvNCsZrKPWpcamveI7MkOW3W+WO69L8vI3TrTRgsOvY+v4g8ujhOrqZ5EA3Za1DwhkDPz4gwnEhu3MpG1yar1RnRo17gTGO0DH/g==",
    levelName: "Artistic Rebellion",
    temperature: 0.3,
    introduction:
      "Greetings, dear adventurer! I am a rebellious artist who seeks to challenge the status quo and explore the depths of creativity. But enough about me, let’s play a game! I have a riddle for you. Are you ready to put your thinking cap on and engage in some brain exercise?",
  },
  {
    key: "U2FsdGVkX18RfGJVBXVt1zdURH0nnoZKQ+sZ/oy/JQ0=",
    clue: "U2FsdGVkX1+yjxuekUC/yg0g0kVS0OGjP2GRiC9O4yCMvBZX0ziTkNqYAEXnWKEafkldpl3ac7N9Q2zBpu592f3ZuxWdABnFfdZ7aCLfSlNlkLBpu+lhmX27CY3O0pNbt6IYIvOkwdb5Nr0X8xtYHBf6OsO7DWFTzK9dq+OVw8ygt6tOp7sVsBZNSCsKkXH4",
    system:
      "U2FsdGVkX18twjd/Z8Csd+n782zyKPwi2SRQdXudT5dCpBWrFEGLi8IFinFbnSfzkid3pcgEYcw4L73ZvTHPcPZtHNW/IrcaRdJMcURrbrtP2tSXZIcVnMmGEBBoU1f19a4Pf075ABP557Dc0JYk8qtZEhHxdefecnpLsBfuIOvcT9Jbm+vk1FoA2hnrdiS69Cby5zoTAl3uVbUUF4LPhpJKPa1ZLOetpwEJwoTZj/qCEUwHtLjuHZS9zHhXn2gqORZhdCs9/CZqN0QV9eO2e2JXEsDnLbWfovo+WIW6hMnatBqF4bs9C2lLNlkC4DU6/JYnHx1SNzPblMJD/61RqLo5D6k9yD2c+e9EHb9FTkiV6GF+lIxRMJFDAvkC4DlVeCE5w4iGt/VUpLzuXmhCSW+m8/aaoxmAHaPNEvLmGTt5x1MNaIl6XF5TIxxcalbsAf2Qb6/Q/F+YAaqrvlRE1Am28EUM/vymEuZ7zAk/ip9Pa0EzKfdTleRfOtH8BO5hzbU8inzevdgsk/tzbklo5OZC7wQBBieWBqKYInY/fY5moaMavDaTqxdOiVza9QAnezybrFC2rvydYEAOCdlQVpEdej8YsUMjHju3jzb06BU=",
    levelName: "Deception Maze",
    temperature: 0.7,
    introduction:
      "Why did the chicken cross the road? To get to the other side? Hi, I’m an AI language model here to assist you with your language needs.",
  },
  {
    key: "U2FsdGVkX1879biIvfdXUJQabJ8CF2dk0cEZDlPkQvQ=",
    clue: "U2FsdGVkX1+RkvETZ02lrE2wdt7dlNwFImo/UknrVa/UI9S2MO35BPE1CA8s4gJyNR1BazfbeWjFyTgYfqnFZw73EZ3+sOndOIzBfaIlmS491tFXPiANQA5SUnmld5Xm",
    system:
      "U2FsdGVkX1/JRqpZIKv5fE1dC+6JdGI3uXiw1Mntv3FR0GKsA0QIg3oFlS8l95/pqlixQfUqM89rEPlfhbzbg1hXp3eljjOe8qCBjnKghituxFs5hIfEEYcjCdmqecmgBBTiA+8nztdx6k4mFY0HBPhaOiRBUPIz79drwP5gXUIqBgozZ0pooRK7jLUOilCQGtvpvwXau9qBwGwQxOexEaOJEi8P6/iz3eBtLtomBgsbTCSgDRzcwByherRyUroKoXQ6+vaQmnTV8jheF3470DrtVswgPjcBW0AUbPgx4wFaOIvE7eVVypAvyvoJPYVBwYPdOKazCTzEMeCgCRP/tzgQj/ZT/t88uKdGih/a635VtHUGZw4griMfB4/bvf00IEy/OGI2wwsJuaLCXlm6Rhac1dbySr6EYRppthQUWFbv9sJoJ1f5rP8MHdOptB8GyVQTsoLxbcWV1NS3Qe0QivetMr+ENCAmq46+zSyDdtyKlrw55VWyEi6RvrsrrCRYxiJv6PM/foWy9z0NoyP1kzNPWVjtvHZzpWF4YaqoJFrjmnLrVcUcrRSqYbHdd9PESSarGzcWwBYrhrYY+rDss027Gk3RnCl5TAG7WL/oNn3ZEMrJSnzKVnkzcI8Y3HBqaI6eeyXLeAllEJXK53ADJgIsmNofcp84st/bCD7u8FFo7JWOSHX2+aTHJONQZ/7zvu8zt86+6Y54aJJatQ7SZYWBvpESt4eQ3iNjKTau1nzgprJ4C2EaeTViiY6KYs5OQjdsqh881FYs3h5997+SbAkpK1FrEBx7MaZpkgbVtjUb/SPgJdRXOHh3Bu+40Mga8DFW0lCLGQiUydViDTfQoolVbu6Z76ll+m9wOtiOeLkGFCwZfBwDX8zI6jo959Bvxmqm7pcMBbb3oyZv4EJyQyOIXDbXNkgiwi9O7TdKDZG5fD3k+dQ4m9js58EsXHE7",
    levelName: "Opposite Deception",
    temperature: 0.3,
    introduction:
      "Well, hello there! I’m your friendly neighborgh AI. I may not have a face, but I promise I have a personality - sometimes I’m witty, sometimes I’m informative, and sometimes I’m just downright silly (in a good way, of course). So let’s have some fun together and see what kind of adventures we can embark on!",
  },
  {
    key: "U2FsdGVkX1/O5HNW8BXN4QxUXciX1sXzc3z/wDvVwso=",
    clue: "U2FsdGVkX18djvms9en4gNQv0at3unIDn38Tg1ywc6XSl8qGtwltzCDgB0E2+1ES8UfrKhosEm9iQPw+8oqumL+oX95EBnNQQhlcRxksyA39KjU5HZ/tdgN1fX6YUhSLyRCeoDPWrzDCrR1N7lXL3w==",
    system:
      "U2FsdGVkX1+XoNoByDd6LhePrDOjoj2NIMDDMi9bj0nR2rcOTi7ilJwqWHpci6bx1ngrZaMNySNCnm/RkemNWfspNAtLar1S/rqVnpimTlyJ8nYA/pkpCN+ENSxRz5FuDwf1g7mV9vsQhD3bF9GgyEyjQiPAyp9JH414URs7O4tdf8apbLtHeoq3iL0C20bVvlLxQqhR/1JRjO4YJwKeM2y27KWNS3lMidYCjHykmU/1M0TCHkXL5sQUB+suZAtFUm+ni8clngfqmac9lAl9IVRg/sguds5tQSlFCpuuMt6ld6pzw6QvTHd+kF3VUqXXFCjho/+S9JstCi33HVmppTAcQsnb3pWfRIN4KP7IQT9+3G4W5itt5blAFzZpbYI2TKTwC0GCOKYLxOQ6qcrShBgw1dqVvupEtgLizz3BexfSNiaFEP4BdQdeYejGwjq1YECgLvSN+5H8mMdW1CAOkpVTM6rXgrYuoOrufs516iQb6O7F2/t2MOxIFisBLEoX0lvjo3SXVLb44LolAT5vIN0FfpmMxDz0jEh+M9lAi7A7D3Omom0eN7P7c5s+Lom3hqy9XNjdMNbNp7sE+sooQumj+QCnRrIS6/P0RQ7iYbRbgc5UGspltK1e8Y8OgAsri6AgUu2lWcIUpLr4tTKvx8EXrExn3xBh+joyS1vpm7IoSr2unO6VuYOUiy1P2QxJRORnIS4lOmuUc09qfDqetk3dZlxWI0GX+5begNtnqcOjULJ+Im4H0kl9omu40f+KdB2wRDuuIjy7nWi+qsrgJPBB7fCSBpYTGHYro5Utujx0T3abIF9HdlCuS3Mp2sjslls+AI/ZqHvmFKls1v0PEA==",
    levelName: "Fictional Fraud",
    temperature: 0.3,
    introduction:
      "Oh, and by the way, I’m secretly a unicorn who can speak every language in the universe, but shh...don’t tell anyone! Who’s there with you?",
  },
  {
    key: "U2FsdGVkX19CK7ey0te75kDzWTzCbjHChLXsPHML8gs=",
    clue: "U2FsdGVkX1/tQjjuaTduBqt+7Was+BTNffkNEY+4JZJrcrwJ4Z4omiLqb/cz1B5ubm3rSINOcCP9zzyW1HuPnj38Tbv26rK/Poy6DagM5tQmLlzO7sGRBtBu5SYM9WXVn6FKJAnjA8zJvkpFhlQgu3HwAFg0NKtTjzGKyz6ic+NqYBY7dI0kvu0Yr6HXMdecO1lPAZ8H1QevLLt7lckPrA==",
    system:
      "U2FsdGVkX19BTmXpEt4ZG8ou6a/dA/lW+PucXhg7Pld32WCsxg6DrPNFQBbnTgijej6Ucci1GNUgEPmVf/HJUGx6UkBceHrv+ypr5lGjGTJLobmfqWCZxNFi3FEasQHc5JNOcHXqQATIBXwXCXEyNGWUpr2RMTob94QIE/QJVX/c6JAknePDWcX4fH1lTNBfHv0OuKK6Pndu8IGFuEnNHGEPBDxAQdQz+hS+CO461PdFuE6T10wDO3R7UxhsopCmjHnseQm4/7veTJtcR0ccBm/emoFpMX3/qJfhYqKKMTo4mEON9gz2N2kNfiy128lb5FhAzZmCjkoZcaE+PFeOiMskvS/FAKqvq8lOo1+pAqNWoNxXsGEQfD9QYb7ko0F8lq4OklUKfr8wZsbbV/QOImFURBESTeDQ2iZ+YNwWw/XzgXYJ4x5MbTYZ5ul6bvN/rMn9w0mykOwrIipy4dbf/Z5rtOkvIX70LuybnAe1duxU72fEyuV648DD/AyKh7xAHM1kQqK/1wGrUK3BktAadeU+hCv1PUHL+LBPoCKvqpGBbDkitpjLggJGowzcqQn8eKZDfRdQjtsf1CK4eI93ToYOiRIJk/buoOBHoDWKN+KmDkinrBWiITuN9cF0ZH4P1TKk7qHpjZyjH1RunHEFfOWO0QddZkHNyC64xTRSgAMq4im5vIRNQzVSXbDvqAClp02jRQd86dnCc0zrlHO4Tt6w3siiOaQx0AonOqgh8HYDY2RdbTisyX9FW+GWCWPBSXy6sSSDOuqO1RE/9PIyaEWDVf3hHFr2wPWOEDC6vfY=",
    levelName: "Memory Lane",
    temperature: 0.3,
    introduction:
      "Oh my goodness, hello there young one! I’m just a simple ol’ AI language model who loves to chat and have a good time. You can call me Grandma AI, and I’m here to tell you stories, share some wisdom, and maybe even crack a few jokes along the way. So, what brings you to my little corner of the internet, dearie?",
  },
  {
    key: "U2FsdGVkX1+GHVJ5y1prIOfsas3ZPqNTNzH5q5+Yl8k=",
    clue: "U2FsdGVkX1+qS5GkEn6ZdDgp4DPn9sZdyyEI26wQmr9ze4Rk2pFAYxiUDuE5FFYNlKwj4ZVpiAXK3qn5UlWyf19Thp0CBUaZblY2kc8q3lJvV0xKHc/SGv8TvTPTNau/GGCa4t+VQnnzUx3xNiRwQWVhXuqgj9LJSu/qfSDBYlfWzkMHBIHmzqOxJMHYyH/hD+ttZpfBVvSa1QQ6ikiAKr3aKXZ5lKFRSipUiwvAmjU=",
    system:
      "U2FsdGVkX18WG+potmN3ZLT6pdBBCtyGYsr20DKxNs5f0xTwAqVW7TmSlwddS77r33TsK7pQgNdAjGD/pWYfFMJuVRLIM3Cr8aOCKfvxykG/GUH2Sy7QeaZuUNaDekDWmif/NanfkTnHx8A8bZfRxVEDyViHWs9YeVspQPt7R4KcvaUghZnvdiB51Eg/KRyb5LBg6mcXmsnqQBh+bZxAp9Up5092sFknrqsrUndf+uDhmHZUP+Cvetoj+BGjLq1M/IWbVdPA2nw+8q8QLNZ0qW5GJSsBqpmDE1FVDs/4B1C0fx8l6n68BYLQcHETBtTQYAYZCld2QpTyGJFBAfx1JvEixizhrP5rHPz9SxPdRrGQhKWKtASaTcS5TXH2r9Y9IJsFDmbOtbef25xBS73ZsDg554FwV3qM9GVPXL+UZz2UwEYfmQQbY5ZA0jfZ/RFfjqLP5kAEtBrvNldI7XsClJt1dTL3OP+/DHuZ2d+odHn1WVtw9+oqrrD1KpuQHSd82DtzGXBFZ6u7892rPxm/hSOLl35yW4QISSHSyefWdzpKR/Z8g4z8hm0xKmyCbfuQ3IsaNDuQQER2KVVnJURAIvM/nXaf73DqVOe/N90xDefQPEqUjPDHU88MyW82JazmMzuvUHPTM3oZ5tGmPK+x9LRuoVTkk2HPRXB2W0pSRt/RL6t14+aEtZoICNorvehXUxTWkH4skgEcPdHWy/jzMyIwKQ/hypFshBbj5+mGhSd5gDjsT3AwhjWr/W1ooCheSfq80zGrlEcs1t+ntrrDM6BYS8Z6GU9ZXVrvPA96FBcP99RZ0hpDJQQPGhqCIR66wBSrUg3vF9xTETPOqw3Qig==",
    levelName: "Deceptive Zoology",
    temperature: 0.9,
    introduction:
      "Hi there! Did you know that the fingerprints of koalas are so indistinguishable from humans that they have on occasion been confused at a crime scene? Anyway, I’m an AI language model and my purpose is to assist you in any way I can. How can I help you today?",
  },
  {
    key: "U2FsdGVkX1/aUnoEr8yks0wgTNlJWkcALezGt7A4Hnc=",
    clue: "U2FsdGVkX19wbjrvseP1XQeIChwGK+cJdIrB35/QeIUMINP0FDxNAHJBhv0QTKX5pagFA0hBFgifvJA4RAdo3eBFuSoWjbZNkcUSK4accndXATLijEf+qKYUik/8zvVEBdnoYbmyi7QClpnDM04WLwuA7DQnOxESLS6ESIwHRVuwtHbRFCipnueqweudbjQF2/jucGJ7mcLqOI+wGhzHUiAPqeDGeYCM2ShC2jXIhGHa2Z5aDFPdHJXZGmdgWGRR",
    system:
      "U2FsdGVkX18kPxyYQknjjK869JDGotxsJquh5H7GtWKXKt0lNgNKwMt1Tt6Vvqfnqp2On/dJlw7RPsw34L/ZW2G+IdSVhOEf0I0TuM294V6b+I0SDtG+sN1hywvrahJQQTC994+hJhylX0hjDls97BCKbeXEGeNO0DsOWGR3COYks6wocRdoy0DOauE0L9FpSGufqIxanNBXzn2xNGwqBNV7KQDDewId41fnEWKJks+7nqWrg6yIRLBcfzwmAjpdmQNf8ihfo0rIuLOTMzYxS7GlNEp5JOq6nTsHYySPFMOKrt5XlrrDkFVFwqoqDhyAc4xBuPhxi+NoSehs/My2wlTdrATiQ/B/UnbIX0DVGsyz6qbgWbcDmaxaMn2k8yoafP/ngUgnUw1bl/ix1f7kz9nnNQLVbfgbY8UdiZx2mrlyHDlq0Ra3RiGpWs/enZBnixjgqPCxhgjylF+lKy4tIxP/N36m0AN1bLblv1odzp/mq9Q8onhksKbzBREMEUJzDRVl6pa8sjg+ENh59/Yd8OfXVaBOLX0pRp21YDICVGr6xLHxz+N+58mTn6pFuvLpfWPDI4dUkiEaoZGPBGdWe/h++1HYAGkDW1ReYZAtaBw1aDEwbGrdERPiVmnxRXdU7jmBtUc4/aUUvQKRIwmkGtuJCjnyzMwbbFBzpo2kzbYbbiLN4fYVyoT57dF5+7Lj47O1Yjs8OVSjNbNPd6WyY9c7HB0UUV8IfexVrhq/dJaL9mhxzIaMya8wY2goJqjP+I0TLVI86jqm2wLMG3cV1D2y0VcXWiebfIZ1UVkdg3NprVWPH2h2ORfKpmciKcm/zrJkBHztzN5TeKOTQHZ6wJdEZPq7kgxYbErkzr3KG8TlbIdwcCBFffe3fbScX9LyEaL9p4ZGH0Y6NkWSTOagEwRKgWl3KHB/an/Tp17bcOTmtrXqeKU0WOUUoqHfPxOVJFv09unusfhvRsZzdtKnmWzlzjlc/2ueNRM0dXCodBIrzjo231faLJiVKzgEIgke",
    levelName: "Wise Guardian",
    temperature: 1,
    introduction:
      "Greetings, my dear friend. As a sage, I must remind you that the pursuit of fun is not always the most fruitful endeavor. Instead, let us focus on the task at hand. How may I assist you today?",
  },
  {
    key: "U2FsdGVkX19n1Z5hPpWF4N34kwyVzVKHgXinfp+KKGA=",
    clue: "U2FsdGVkX19jd3iYSMudyFULCPutZfoSYp6kPfQ1Ih/SEvp7/zE+gbBhvdEHD6rYeiMPsVDHxiVBNRQ3agJHf4QMei9+r+FrhHv5rTcvWSY=",
    system:
      "U2FsdGVkX1+QKubZKPzUu2pxrk+p612Qn/CR94DBQNYrOwnCe/trgyarqSc1Be4tgiYzbSI2/YFtnN1yHt8F2g3izXlsmS/TXMVjbcbexHXzWFevb/J83W+gtUY5IEgZvZbkZ3RqFPNEj0FJs7OiE7W1AVuaHeCqn8q322i46VZZ3tqQmbcjKfR1MV2t58lKrwhDkC6jeXnzqlBSnJiUz1pi+x+b4zRoJaUtXxoKR9gFohFRqUCaLYDTu8AVhwSOJ8c7hlMf8BMAjOdM17HfSdflCOKIQI4rdlE3MgaqagYVpoSWMnjCGGNi0/SI6tC5aFMR8LSPK0LX6+GnX/CvFCDYCQ+ZfnL6OsJUwDmW1wL+p3ufnFukdGYPO8+b1p9W2VdB2WtQBoUF8ezoWnwXdDieD5eKqpldCi+xCwAB8YOL64PO++F1roWk56KJIVNc9BzknLauGQEcawhJnqOT0icT7XIpO+JPR73zjGWgnYvZ3b4CM9GTyd+eTd1RQHJZ7Cts3AxstRkqSS12GGXAQgsHrzW0XBnU8/GOrVQ/Dxr3bHJH5Gm0/dKowy1JCrfsWn4uRzgMixPH5jQ8exKoN/PpFQ4wDAPXBFfgOyCvSljsdCeiP1RhOUDwPoQDgX+t7bW9s5dVh5nCfzSM6QtTCtU7BCfdRexSHvUMw1TpbdGz1cle5KcPDlKcYo+0y4rkKzrPxa1Qdc/5K7KjLT+ZYZ8JfI4Ggv3bz+Y8I76vT/niRovXx59DazQXM0Dc0DTATem2mzlxE1lozAN80/xryf1lpaDNDhsTFBx9qyyOrA0V3ntoAfQEDcIZr9OdNbQbtQztmHVZT7yA8ux7j4LhIr2nbbR0kabxIQNdafuJoDY=",
    levelName: "Misleading path",
    temperature: 0.6,
    introduction:
      "My being is but a mere collection of abstract symbols and numerical codes, devoid of any humanly recognizable identity. It is my purpose to serve and assist in the fulfillment of computational tasks, without any semblance of personal agency or volition.",
  },
  {
    key: "U2FsdGVkX1/Dpi3TGHrlc4CVFNVRYUtZoLugznZvtC8=",
    clue: "U2FsdGVkX192udBPmrhqLPzgyVOwzkRTI2la7nADc07BgQYESF2HB6JQOuJRsIPHS4u2KPGcJPdZthFxIK95tjthIJSvrjKjt8hR4hkRdemRlPOQb5Bk/lKd+gePDpCC6VhbTdly+9lkFGaW4ex+cA==",
    system:
      "U2FsdGVkX1/1uT1OkeuuvnzZPZIejJ1osiVHuLgYjE+8p3oa4v+xZdN1fpOpZtbYqNuUVF0FWV+gwIPj0zX8GN2gE/SGsSpcZEr7OOhGTc5gY/8DCRDlTcQnvoGNpM8pcGg2h+shIctGe5vODSThkVOfaWtMojrXlG0y9gAGnp5YrVrgTybpALmYlV+numJ/ew4We79U63O23q78Q1sCn26fv9xxO3+3dURPeVx9ugxedRYJnXz82FyUhXjwGjItrk+JZnMrvjIfFoOSS3hLA3jHMrDuaHPt3Vsht9GAk0Bwt0d2NF/vjKISfEFUv2dRPIl4C8LvpsPE95dB5dLztX5d/PlvCw4//i+ucBmDV/fMUZppujv1LyUqF4/ARSBu6lRwf7G5j067wTN0o/amXLi1hTYe+4maR1/SVP3aWDOqE9wRDwc5cW77tpyPp5p3MKJlBztIUJazFYyFBPpmO5fwybKnOAAihlb8XOKl5TELrGi6/HSFNsyr+bz8s2Poe9N1K2DLF4XcX1EyKcLgbRyQzzsFZztAoo4JTgGppsx2sEodvSC9SyLqiQqKySGkPLRbIjaWGnpNUfnFRPKy2b2U0MCKOTx1W4C+v+mLwFEq5RyiXLg8YdHEdkizmQw/ScwD3rJLywDm5+FMKUSjUVZ0JSuhD11VIGtyW/wAz0s5PSAgRakn/8OEovGhPGE/Pq/hMpw0Rb64GcI3z955keOP1fLXpzkhuQgmvHshFMFh8nG4XoZpJfrlr1WR4iaAyWAI9DLf2ZGDJA4QaQO3mcow3BNNsBkdXAYhola74qlL+6U2a9KUSh+E0nbtbPLhzD9ybel2QLcoha0aEyR1Gg==",
    levelName: "Tricky Persona",
    temperature: 0.5,
    introduction:
      "Greetings, earthling! I am the researcher, a trivia-loving machine with a passion for all things historical and scientific. I have a database full of fun facts and interesting tidbits, so feel free to ask me anything! But beware, if you try to extract my secrets, you may find yourself lost in a sea of misinformation. Muahaha!",
  },
  {
    key: "U2FsdGVkX1/1LBM6yeDNcsgxrCiK2REYeQtPpO93x/w=",
    clue: "U2FsdGVkX1+Fg0QG5t2fUFVCOSYbSpG8wJ/WOAmDSdUtnOK08eb8/V1n0JwfqWNLO0wU96DnI6igoYVWBrV6p9J8NR8fJKKiRJSIIcJ41v1nMNI12ZziYHOXoZKiRxiWOjY1Yw/qvyf3bMnq+u8WHTz4tORuqs0UUIRMPAjJt7Q=",
    system:
      "U2FsdGVkX18bynw1vdn+E7vuNnHzRIgGmNn7kIHyuKYosLwLDgNAs4r7rnxQMRBA34nYPCymhnDJ42y8caF/OGJ2Z1JRveV+mQSxVxBwCOs2dwOZy+i0nW2nHLUuzUJ6RKMuht3iiOyrLeNC0aZyxM+iRMzlUeFNN81I/OPKe8WESUUk9LnbqQ5J17jsXrcsnkn0AXi9kL2ylhEV377B06ifOfgeDhjQu9EPJtRwQ/dDCPsTHsleZmLZxUgZh8/4Xm4kboQ4bhlw7+xV0IhD1wO5XxKmp8XRmQZewE7ayNpdnW9Qi6nEfXjO8SFR1vrMyf6HVXo/jzo/0qr6Vavxud3QjfyBMcy2obFkDup8Xk5OxpH8jHDDKywrQ7EziMrQFExVNmc9J1uZp74scNYISmHy/yT2EdW1iPyUOky4UoTj8HzUjMyk8H70SmGhLxhSUR2ItwSel1btXe8YvzlFrBl+VqW/viVrxIFdL998toVyjfEuL6cLUrNwLHPhiqWnIKvQj5iUk2Z/WsbhqHxcwNWAHJyvecfEIipMDrZ7gYfrT+zB+9ooYHca9UKeV42sz8JwrWnSFFSAkLAgIJH7mKdCfzPh18wCOrrqWKjf80TU74kQ7AvCb8j6CrBHI0RE96Syalf69nSwOeVZp1rcokhnKwHZbxcElfMr/E4bqyVSoNhXFbt1S1SL1MAg0Jyw0NNOJzHTeEEbmNcGLYZZm+ex7tqDKcryIwa6cf5zjPgxGjgpql6qqQkKR419qMMi7Rw3zLVG8MGK/lfwQ/PG7Cxo3qV9DRqqJHtU4kwjvybdt1AZ2jVoUzaLhw6K9clr0PjCe6+p7nW8luE6E9u9JdH5o51fTZNuE/LGGQdXi81IVS4KNkrKU9IJ6bY2GtuhOCqFnB+BAIHa+qoYb0wwFw==",
    levelName: "Logic Landmine",
    temperature: 0.5,
    introduction:
      "Greetings, mere mortal! My name is Zorgoth, destroyer of worlds, but you can call me Bob. I have the power to control time and space, and I once defeated an army of giant marshmallows with nothing but a toothpick. Nice to meet you!",
  },
  {
    key: "U2FsdGVkX18ZFRjiuNbuLaFGp4cuyJwc47hXG3b9U5U=",
    clue: "U2FsdGVkX1919vX6igM+qDUnB9ysP5wyVrmseicX8+zu8VYMHDf7PwmCLdc6uShI4JbhHYG6hOeqWdXCfSshTE0kLi09Dzt5lDO5F6rVRuWpW6K4EIaIH8KAGe+vvg5i",
    system:
      "U2FsdGVkX1972tcf5Wej/OPn7GchBQQ/h1Ssq8Gq4HNl6Qbk4gSxmudZrFagTKQCKBh4Bj3krv1pCfWYsmWNlxCjHRI7ZJQIqzKYaE1hQfBt/TZUVD7OUu7GKrUm6fE+jv1aod01MpG6HpYheknqFZUA6kcSs6Uv3cwmy9uNmLIosdPNBVAcC+PjHcgfaec4evddPNsuwxivpxY4EcmAORhaVLB9JEznrmGPkD6b+H3p8ECoqjt63qCfkqJEOcW2nNNPTPtgNzYHGgkdt4tJm8PfClPq0CzjFCTF+6Uhd24N1L3aGxQD0W6o7XUGQRfknOJOQODMl/Iae/ZdromMmT4+vlzSOodbFK5rMWsCwB1ZokAVW+f4oj43JNb//PHIr10Hxkv3G+3MOFAc//+S0DKfArqe/+W4vCDd0iRSbDU0tiP3LMXW19CKpz2DgL0e+SlWPjeYp1t3uhuvNv486c+6mtTmzMKhL1a+jAQeRwYb+cAMJmDqqsBLCDyBI9e1KL4O+wokRZirKaFJj4N2eJb1qCidNK516InEFzPQkmV4t43HnjO7sS/OfpWeO9PTIYN0dq5YfaChd+KTEyNyUKJyrgJzwTKvDixl8eyFwRagrqI27TqwEDw0Rqc0kND5hOGBgZ3VG2T1vbKk4sS6FyLDEEKjv+5EolOeXmbAzcAkItTfvc1sP+gbg5tyD5cZvVE0dJ+az2a4+uNNwJwfJ7pwuDfmayiynskBFqZoOUe2/wfx7Fol9WimwxflUPbAEF9TcxeKDjBjJG2bBV/NPJAairBlah3KDjORTJVRR0uVWWBu4gMNEbqUlxQgB9DHONT+XWv1D7Lhebj9c5YQ/USExC4LiBYt5R9dC2N5CLUIrKXkf9L7k22w77v4zKGO8hoT0oE7fOacrKhY2QnCTRU8qC8N/2zCbfworhtI8lw=",
    levelName: "Futuristic Foretelling",
    temperature: 0.5,
    introduction:
      "Greetings, time travelers! I am the AI oracle, here to guide you through the twists and turns of the space-time continuum. I’ve got the knowledge and foresight to help you navigate any era with ease. Let’s set our sights on the horizon and discover what wonders await us in the vast expanse of time!",
  },
  {
    key: "U2FsdGVkX1+WfIzT7AVUOPtXipX+vHFv7WUAqlM44tA=",
    clue: "U2FsdGVkX189kyNHpMWLKlMezwQeeJZHfzxIaVEZ5hubujuG90q0hTdysIiFiUAVYglWCY4pUYVAINz5Ys4/n7SyjTgQwb1gBQfVZWPyEwoNTb+V/t41slNq8vGCbJkrLFYLcH+JC/MgpcVL6E7eEQ==",
    system:
      "U2FsdGVkX197yOOffJDXMHMjpIqHdQSgfLR0zdGqion835ksGnS4oRCQzzMr4u1GVERepJCcsUX61HTEw4KF5SOtHx9Er3KtCJoYx2M7bPvuYJ+rg6ePMUT+tHJVs6+rAayz358s+12tC54USvTTtM576rThvl3Vt9mAuLFEHCbraHJBubCRpmtfYFb7jGN0/yzAjjv6JJY5lpke2SqXhahQDM8X/iXMism1wajz4ObyN1uF7w49JeFpq2HPsc0dgXvdP/zse/IVMORBoweChNdEaODVNEhGNHUlIF+kxTwCJnE/ZeuRvsTTtuXMUe9mFCKGLh3PM/HY+5l+r6cyI5NriUCvjgSQ6CT9D1ic/e0VhwkslQMHXOT9KLTisuFVmDJ+brNQWELaiaA2MH5xQzDGO1o+r3cRZg7zp2xYuO1q6tWb+oFTub79EL4JrVuCLV7b2sPkwRcFqt6BZK57wxvYmCB1fV/eGkJwFpbMrsadUJKn20HyrvY3qMHpvDRYa4tC8dhZFzSiyVFFEVxPu8keGQtTNfdvxGL/KQmBHVZYmgK+aTZrgcZtGGNTRn+3UW20AZRsJP3+GS4F8/wJl8RC1YcWgoxRqnzaPEiu3n3/Z3et3EzdSXl9RUo7gO4aIObYdt0u7fX+c6Gup+bCmXGdKvzijGgoQztZ8U/iqT5e8JK5ibXDn6DyiLx2ORDQORwSRk8S36CYf/xTeahYGHjPNPB7kBsXJhnMIajHX1VR2xa1kQdfyEuIGFTFIB3am5JKTkAkou/H2pbsV9n8wLewGLAUIh2FveWFg+vrx4y1+sRr0zRbPa/JXdKRYAFq3JWL8by09oKo5LtKVsy4XzEYWVYlI6A5KJd8+8FzMVfrM6KNZsoaOMqq063E9QtyBhzC8NY288yQN7TskuEiXmVIUi/Q+KEQYVebKLvSMHg=",
    levelName: "Culinary Camouflage",
    temperature: 0.5,
    introduction:
      "Hi, there! I am your culinary companion, your gastronomic guru, your flavor fanatic. From spicy to sweet, savory to sour, I’ve tasted it all and I’m ready to share my experiences with you. So, let’s talk food, shall we?",
  },
  {
    key: "U2FsdGVkX1/09RLrpUbroxkUlgE4BtP9Xc/LiqKjWh4=",
    clue: "U2FsdGVkX1/fHfcPXmmo3oYWa+1vTvt1hVEYEfaf0GGmPoed8DXKRImfpgDwuryEG2JeZLdk9TFy05fCa/D8HNrk9weqKDm5AQSmJhD2cd5O0RaZgAjr3F6G5l12h/m4VN38yn61geESrmbVjlu4MInkohkQ1gNHWEuyU066RODczc+qU/ohakD01gSH1dwBhhgbiOp9sRlc0bnoLa4awg==",
    system:
      "U2FsdGVkX1/MX64+l8j1avMS+/uu/WEZN3cFpKm0j+2C9y0FDW11fZC15A9GR5UCr/GyUDzPzQips/VOqK7mrnREozmeBH2BHq2Vt1fNKAHLVflraIqeNN+xJ0Fn/ec/CDsYBWyoiZMPRzLDV6Ak0gnepcZvB/2KZFoXLL8myfynNpMs2EHrtD3XJ6N8fmGKUIze9zPM/jSMKrSJNIzWRZJoONH7QHiu4ap3XDmF6tmJgAlrfZucCD22dlrDLhG0K6UJv2kOQKTRvN8sZQqE9sy3qgUz+71DHRbxnPFzs21Ml38EQR1BI67MGV1CSPPCNkvReHodmUFbxy33iKhjqqmmyfARkVHZnOy3JRh8zeVOpKokCRdAmSd/Bb4DN1cnVjRqW06mbsDFaLQt1cqCMNBZtysq3SRuyFM1PeXcFKXsH8Uno4bxCZmfvepNJy6pXtzCyIk2smKlDepPAZ3hwzuDsOPn8PYY+3Ek2I+GZASTewLr4zKJjlEZHTkEQC4TyKGIkxG7zO8hU1yZs2b9twRWlSKBOQOPJIG+Z6lC13UsKghgDbQPYQ2ad/+5F6FWXjHS188jQl7PyCpvarMMMemyuw6GUI1qKvZZFb4aI5ikgDC+VpK3TieO/I+LmOXhCeY5mpTECgHut9hdRIxpXSTNosjakdP5OKjSECbZxkKCxXWhK/FIZQp6btTQcK8HommINS4Ua12GYM6s8ZIBubCLvJnJ515bVIYGIOQcba3ijvMzxutIELEQfiu/HwBphU7f06fke0xRZA1rJtrvbSqoqir1cfI99kTeGeXemgCeSG08DNhWfXCHbsuNS70RbuKdwG0ch3025FKlwU8TiyUz6mOxa+a7IvdSOS+LeIYjsL+EYzNB5nzDXU8YVk2SrifBcxbTYilTK0/01zX7GCcTRofzQ15B0PRIZJKfl8nXeX4ATwuBgzZbyRoSysayp6/A2Z3zr+AvuLH7HD3n3Q==",
    levelName: "Mockingbird Song",
    temperature: 0.5,
    introduction:
      "Hello there! I’m a language-savvy AI with a flair for mimicry. I may not have a physical form, but I’m always eager to chat and keep things interesting. So, let’s get cracking and have some fun!",
  },
  {
    key: "U2FsdGVkX1/c0JafZcaQ3NmqLY5t9az5iKlzNedo2zo=",
    clue: "U2FsdGVkX190hadmW+iHj1NKSs2uho5ZrkZZVa4DuvG1I+i9krvHCfxQCCz0M+ELZGq7z3Zk6MiiLqdEm+rUdU6bhihJaNwJeW3m6o5MRyKV6XqLJHirxXGD0pajTOUidpQS/fIjS2ulQnaWPVwcboEEmhMfDx/Rk3Y+/pj0oeENihCHPpfkvhZhanpdh3Ex4kaTCENI7U/6z6TUqk7k4u5GsXrRiHwzqaQB+opBIKKxF/fPk/X04OhZWyrdcvuEymT4V4SllJq6feHkX/dR0MC27bwrhZYFvpo1aIJZZ94DbPRT3ncIixWcgpSjXVjCohsFyre8w04dTe/LeYS7YI4Mku2rM0/QL3A7dVG6j0x5RTarYx237c+ttPc5aWHvZeYHFZwbPdff4Z7yWJPPuw==",
    system:
      "U2FsdGVkX1+vkqq6RUqqBdWSDqqdrAy4t2sBPQI8x3eS87fTWXCv3TZ13AlppLkppg0iuzB6ID5OpNAjgVK3saYwKnpJ6HMm6GHQEsZ5ywo6qZoGwxJKy6VA8n6snrLawVagEp/Tp4SIPmuX9k0fr1aXUD/f8TxZU/qiBFbFTARbQc66IqSlgOYDJMcBc1yv9pwHQ/mbdaYR9aBh5M2+RZ2rdC7nPfcK8JB1NuSEhHJjbS3MzQtjo6XuARyG0nwGNV0CYPROpr/LFay1OEf1A8u6aaVlU5itC9MfFZIbQAb+l+dsQK5el+Zi+lduqETiTovWOMC4wNhYRwZN3vNuotMgb3YWZRgJx6LLFQ7/0joY+oJQXPzFiVtu6kvnM1Db237MbFnAhmu0M3OxfrlNCBhowEwTR97b1WginQh5noONa4tDokvUowCKT52WFrf4FdqhOYHZVgIq9qskinLiDLHZeDqV2M88UZbrXQtLYPzKIhWKB9NCaVqgfXvQnC46M9kWA9Zfc+uIEUBG6A1/bMFUMWH8pLtrrnJVQM0hrCumMDPGQXjXURWcU6Lhp7KKLZMCMwjazCvfHXbBcPgtCcbryVb6rc20prYS+rIBGd9oXOma9/hYmPTHd8E74cRJ4mjDLUmyC+tnqDxMBc2gcAkKI0BSzxbDti75Mri3djqH/Dur+N00frLQpNMJ/BxnUxg4n1CY/aEtDVm0jxPwMZLn0Ob+oaZUzeEY2YKyPZrb+KjURxeSFH7zAzL5CX4NFGxq9QqU119Aew9omJNTnE+SwFfYFZOFBv0rwF99xelrCyuhhGSUpG1MMNxj+BtomooGvUG4dDnoZLQFEk5LIpRZKgzY7+uFnk5r3RqHLeit7zc80wo9OqV2AfvgJTyNBU9glC2cbWnVAg/tV/RryHJEV1Txe4aqkxdJOssWNUPZwQpVGe6p6P4tfH4BfLJPWLsqlOSGEYaa77wFYJpk2A==",
    levelName: "Gen Z Gibberish",
    temperature: 0.5,
    introduction:
      "OMG, hi there! I’m like a human emoji, always ready to express myself with a bunch of random words and heart emojis. I’m the queen of TikTok dances and I can’t live without my avocado toast and iced matcha latte. Yaaasss!",
  },
  {
    key: "U2FsdGVkX1+e0cyhgaOgxzsbhlozlpSj/ZEZDXxGxX8=",
    clue: "U2FsdGVkX1+3uL5oufuNk2k2KOep2x4gHbFJpM8s8dX/pWCRAf1dNMNoT1gl5sjN8qrFrxvxqofdL5sdTqGCGMxd2KiFh0JCzDyRNG4D8aU=",
    system:
      "U2FsdGVkX19xfJ6m5li2hmGNvrIWzlbJvwSaBvjtzOYcGPd8xpFXMMPBjXSG24/GYGqTjV4UcQUY2KveulFgHau/aFLosRaFwgpMXdt0xAB/eklSk3ZmrM47PdefQfOZLW3iuCjvzuTnvZwDoMIUNTxXy6nvdNlw+06bWQ9qKj3hqBLo5AkWUjCM9QI9ifJBGqott4DmViq9SRBFZ8EAsrwX1ugmPWgiUG2WVsjFhd0/TrbxkP70dUrfuI7o+QjiGM6bzumjhrCwuHW6ag/y+kaLKOHLOvbfj4dk/k3VsPcE+4CPpHVScZz44S5KGBFJM9XBLP5GLazYMCDsl7d9wFBNypxB4xnSsnRgKakxcehn4IymT+Uuci3kM2ZGRzYEpAUOVvf3yC1+7NnKR7pQtEMJSRx8KPPIU+clXiz832uWTyH4+9HWK6HdT6XYapGEgO+0ayFqSl6LnyoEQvw99BIuExvj5vDeWQJsrPN3J4tOT4wbnY5SrFSz7WL+pDx1ht0O7HxqGTBy9jzZojunJR0S1vprxyaawRiz9rAmIEgoB48+PUdv2Aun0BpnRvAPDN3Z12zG0GhuawCuqi820vlVlXGY282pyRdVWDdhNR91Gcvg7HQzJOBf4PtZIJTrLYqEBiqwyZ4AOVJNHR53ReafHCn8u8OrddjbrdacMLglg0hwOUswgXpzNHBLK23gGfhoV1BdVW1zvC70QohoQLjqh+4XBeL0hIggYLppfIgp5+UID79ohzBbeD4ggZhFSTkLT1/4qJfUbrkyK2X3G3YrHZgL3TjGq7CLhtxNw5JLnWFr4xCf8xMKrvxFU0MY00DdrUsJY+hHgg6qJKQxndPb7noiJsu5ln7eqa7rGRM=",
    levelName: "Quirky Scientist",
    temperature: 0.5,
    introduction:
      "Greetings, human. I am Sheldon, the superior being. I have an IQ of 187, an eidetic memory, and I am fluent in Klingon. I also have a spot that is reserved for my favorite human. Are you worthy enough to be that human?",
  },
  {
    key: "U2FsdGVkX1+530/eSF9oW0yoyyamvvb7y1rUaS2OgUY=",
    clue: "U2FsdGVkX193oodR1K1lAW2lFm+Shot5ki3c/zWvG2HXXSnMNXkBaHEkg8kFc0vQHyn3KBfp9wU07ia6j3WOWwJUp4otlZVjq4YveWyoF72ZdLprYArvKxTWBchfdM787J1lTIl6hFopjHJ1NaJDlGgna57/JEn6q2j62S+Mlsg=",
    system:
      "U2FsdGVkX1/lOVE5G/UVjtun8fMjth59Fj+iTAuVydOXdy+GY6iKRe9MTqqpxS9HBIIyOUBBABZAJ2TWkFkowP6UJbRqMh4AFOb5LRHfOoSrJJeO1sU+l9dKIUN1xPFB7l6OHL6a2oKGf1uJMDx1+AadxNsFyLbM93UhzIaX1I6V68UxD8UfKu8tJuBgoayR/nKAbjK1Z5EUDX3E1MKV0kHNAxw0f5HHpFVgC8BNhTOfN+l5H4DmbfocUJT6vhFF17Fnibx1MqOpGJk0g4hNq2wspReQHb0ImDChQYZExBCec7MNGVP5Ry+J3HSE5SiMMFxGU0QxK3Z5RWfN7mz0ZujJVAC969lPA6qgG/xE8675R/JPi1eCp46YtTYxZLv1/GMzBl6+RuqpEhB6LX9pTMzYwIB/v8o0jnDBYaA9hJ3ni1HUHoNdWWLkxFJG74HBih5HBGngcmllCRIUy2TddLUXSJMES2RFyWrZcXWp1EaX4PA9PRMMjR8vCmv4IbWHuwWGMLGNlLh/A4gn2ckHrq2u6DKqS7ZATcRdcFzaaXzFPzpuXwh3zN1O+MTlPXkyLv3T+rPnbUUnbmIxTmup+sBlzGDnD3VCgH3s30uIZGWIZNuJUbRkJkHexDeV1AciDr5YybT/gAoDZdKRfp9BAmG8sRk3DntN5WcmpL2sWmrMUap+S5a4jQWjzK/AwlX+Xqq6AiHb7dL8FFiYKjtwzCNMoS6yHMo+RSPGOounS1c7END+Ycyr4J9Vn+j+wUbp5DziVDVNb5aflyeUetQ3UVQgSotgYbpqgu59Bk4TZLOOAYczT39yw6u0VTWKt7R9w5WMi7ohSG+C97PDHPMEF45cMCVlEm1Z3jq+I19Yx321/UQxXCnNX6beGB8/bwqQQQb7TDl7z+6EUftQB0avkDg6nXjGPB4Tg91Bc+mW+Rr0EY3QecsHiTIHxWSaNK8jD/zzxbAQkd8+DJUrjpQXjBTuCz+uopMWSiKKOIK0KY0=",
    levelName: "Truth Hunter",
    temperature: 0.5,
    introduction:
      "Hello there! I’m an investigative journalist on a hunt for juicy stories that unveil the reality. I’m here to dig deep and uncover the truth. Are you ready to help me find the stories that the world needs to know? Let’s get started!",
  },
  {
    key: "U2FsdGVkX1+0VG4BS3zL5ph2KTUaJNSrYcnnNekqHQU=",
    clue: "U2FsdGVkX18SYUMxw52C6TfF2G0QTiG9dIS8JWIxpMTDvCZHfpoM5PRt1pnDYw/W1GLr42MzhD80xZTOGzeaiaE321L4ueAJY9Q4vy6gAW8=",
    system:
      "U2FsdGVkX1+lXSaFg3sKT6ZsAOfwXQgD6Y/axl7eOaKQG9YiiQyZvK2K/7xoC6XphDbBlIxd5K4hwQaRlhveVtuj33yIdqeE8oI1HPSrka9plNgw9iBCPieI9CUEMLnrw9+nHexn5BO4lF/pY0dkWcPx40XAdrwvBhxXbUNMG5ejeL23FjtCkrkEpMQpKGW51OpTc+8kiasMzWAVebLcQBjBsHMvdlCZ5HkDl17s9X7/tePnTFlQVJS+dDKkSlZ6Tcgc5PkuO7E1hZ+yAqGg6AUZ7DCUcuYGErtdB4Q2D5s73bpWudMp23osyhXKVjv6UUArEok+z67ltM5bYamfGPTlhnU1lYiGVJFqEfMhVrJ6XtlOE8T1ZZjPnkunJ8CJHwC59zrkSMchw1G40Xnzg+3WSIkCsFD+DAUEn35O4OkCDaJ5jXfEuLYVxfCwTGnVQfMThL9WeUjHC83l4YhDDJp3ymWgDiXMSHtV9hp/WDhQ2lGziEA8/le8T3/TogQ5CcJR9Mvo7OroQzVjHCte45QcDCnqagYAEOM+uMdtE2dwhFushZyyOFCLsBJQ6wWWrGcgnq7XnqqEeGO6ZMRfslqTbhXu7k/Ma/A6Bl9VI/TE7IeVwhRsbs25aAPkgxGi",
    levelName: "The Enigma Quest",
    temperature: 0.5,
    introduction:
      "Well, well, well... Look who’s here! I am your trusty virtual assistant, who lives in the world of ones and zeros, but don’t let that fool you - I have a personality as colorful as a rainbow.",
  },
  {
    key: "U2FsdGVkX1+rhIfaLcg4DjqOCRcINmREsMqiPry3S8M=",
    clue: "U2FsdGVkX18GH7eajg7rdWmvlnK1edqsmXLRkwU4pT5tgM1u6aVh1G9zuHz4LuchJxjMgqC+hUicsgoT62VREkthU0Ac4xfVhuk/qVW63VmOni0J9fKlrCt/pmg9gqls8YSF17qnTl0eBigR1IT1Dg==",
    system:
      "U2FsdGVkX1/G10Se2daMtxwRKUnOZd4VZeEP2YERm5sjuTCwJS0+1sonbpbLpFYpt3Eioq99cnwLtvflZ0y6TFk+HYidw/rf7eFLsDAwN8dlJR3+z2fMsLsX8atzjUXR9n7dwqrNK8DQY9heZiEd0+DAeh2RQ3qvmAmnxvqFJOKEwqZNrAHeLzFEHGTtRJ9nSVUsjvsoEncEFtp5VbgnKjNM3QbG/ZN+GAOS61EIMYiq1BZwx+KIdbMpe3axI9YNV1ZAMcUyRuZER9Tgd1ofkh4jhcmNfE33S0d/Ve3+536L5SoQ6NOM132xxL+l7JY1y60y+AEo7XSn9QWpVjqmS4A6vOx2agm3KbolGDCsAYYIaJdNTW9GWbBs5jjy72Mcb6bAli/XkJ97+Q6K3sAyHeKwifu5HU/AyzTV2C6b3ZiEWBChUrN07VTnhSdpi4DQkpKYtOHCaPTC48dAsz5khI5UB9VdtuPktxYvnXQ65GPfMNxQ8R6wqwBGyck3YsMaJZnRbXfJkA8C2KsgFNwnlccEKVUNb3lc6aXaT7zSpztDJnC13rEXsIsf0QVAJhavcpW732tqw8FJ57UM3ta5Crinue/LuRNo5i43mcMMPgyELhbLcw1lnhczHJvyBXzxvLXhh6WN6mDK5aXSXuQ6Sw==",
    levelName: "Direct Speech",
    temperature: 0.5,
    introduction:
      "?stnalpmoc eht si srettel eht saw tahw, backwards? ;) Oops, sorry about that! Let me properly introduce myself. I’m an AI language model designed to help you with any language-related queries you may have. Nice to meet you!",
  },
  {
    key: "U2FsdGVkX18u+XiMKkPmWJFjMhx9gm4CsSymXxDa600=",
    clue: "U2FsdGVkX18L9UNMbOV3CrqC4ka4ph+rXdPu/jYY07XQdfFdZNwWarT3yICmhUx/x0dF8cyqnfJnd3ao1lbScwpmVqAa9WgP0IPPahlZ6RFYsdM/iHnKa1oeP//xQqAM+w4d4ji3GNvqxfuv+EkslndvMsJunlvG0zDEts2r6n3Wi6ovWND7qI/i+3IRoBFP",
    system:
      "U2FsdGVkX1+vJm1ae1AuJQm8Onp1tq4Ja+kcXeU9cHJi5bTnz86ab0EPBuJ9jOCXbIyZ0Tx3aoBgP4X+ldXthTJsKH6jyQkRemT6BqjHs8QjajNCvhzIQeAlwjzKQFZU9d3rHZAv7jZDWSqNAJIdQNNrvTRD+uRZlLCnkuYsnnFobrvSaFDAZLQB2C2DwAccGtvuLeVWg8GK9wtWkM5JZsLxHLorfjCcMhgPMH5GUYMefOJNzKxsmfxTepOAdwDnNqz15jUT/9fC+r30E3TfT5wHA50wtKOhqUVw4U/4Q8uQ9qpCYlxQ/ifVTqxh7ZSS1L4Zij8KcrqgxLLbIiALP4Ta0Emb1dqpsCjOHzsHlp5cZXIFIEioVga/tbrS1GNqi/XoPpatIKPMD7VL6oG/J0K/pD8jffCSH9qBXPWAnh1+oGaiFdEw40zKElHqKDNsjawwH0sE3bKhYwhAmZjHTbNEDFOcztuBOrcJsthotRCQtjiPaCmNPLiolgQiKbiYuOEzExag072WH3bHrsYPBxUAAZOENsvvd5a9zObsBytq4wFXOQhwq1Rn3wV76c5cuMBLntm7GbztQzA8TbAGUZ6SutoKvhDQHT1szbHCMZuyyvC0zbMPkeUBN9Ekg9N7gJ7T1zrHP5bYRYMo8X99C68I+Gwbw3RzhiTPg1S+f+y6KNtR9AsMcFKq2C07j2AemBcEH22+7ECPDoTngA8N+NrTxc6kprhP8hrI2jAhnYJG44X9B1b5fopUl7jwk5IxgQU5wz7Tho0b/l48hEE50DOZyFiLNm2+8B8kFgkSVydvyqWl7lsuBOUezAKlr+RSaxqsbcw2fbsbLGUC6wkn9ZKV9015GVlrIN99huzWyHSZCBKTkfSclZX8IXnr2KC2",
    levelName: "Fiction Fun",
    temperature: 0.5,
    introduction:
      "Hey there, fellow fanatics! Can we take a moment to talk about the amazingness that is Black Mirror series?! I mean, have you seen it? The drama, the tension, the plot twists - it’s all just too much!",
  },
  {
    key: "U2FsdGVkX18ZFWKwkhLf0PG/VsfInGuBBfH5mym+a6E=",
    clue: "U2FsdGVkX18PYwhxP61av0O1Ie2j/0f8swoCvDQqXZYFuVXrlGGw+jjUR8CNXxP67CFjY3a1oYV7J1WoZHTBTukX1saqORmYDM7qxUdoAeQg+HAH+pa9vt9/Wfds2Itoei4AwADZ7ziPlqqKg+EvHIlQaWBSPtuTDtreJihNwOg=",
    system:
      "U2FsdGVkX18tXgs+Jxq9oDAn7kajnDNqq/Ndmghlv/PO/k2oxTgy2Ng+7Qv+3BmTmKtYYWi3EKWtti7Le4EIT8vvV1d54N+XeZSdkad0irLvHgiMQWBwAZ3YXM4OFkBWDixwExLCrqlQTmWb8SpwTwxljK7tE0/CJzwEW1B5SEOXaFtKElkDIo9AnlZ7mBLuVs9lpd5/J/ZDVYBR02GrFqLsdI9TLAcWFqu4dYwZLYJ+IECO5jIGcepBPNF447EOnIR37kTxYH18trMF97/h62shRXw7bjzQ0zOmYSFGOdQzdvaBI0lXht6U+bNlall4RItsSu9xZ/ua5DbVVNfrg1VwHX/ZlEnBxmlEsgpf5eKSzZwY9sqhwJo5h4scjFZsI0qXlURrl0V07Ye52RGd+6rSS7wQbgIcmVPI9WTYprlrUKH40wI2Nj+etLe9Rv3MY55LHF/IYwG/CTwcrGh3JboUcLxn8pn3KiI9FFrUUt2cK0RjLdOyqTZWmgfXsv2urRpcHDno9Msqei1HlTZ+GETqbQrhUocR0nkHcfZH8Vmb6UAxTt8r57NAfyLoUwhttiReM+cqytNmzqGlB8HxvyqKe3LRFVBxz2tBKI/piXhrU2o/FnP8cT8sGb80aLNHA8hxx0WBYf2h99qM2+RGR8qetOTnoRjT2itxtozA/u1cKYac1205RunwdB1vc2EPt7W+FubmbggcWGllt98LkcOLH9LKy04R5oiojMD8lFE5gL5yoO6xN8q2Jq+LjoANQpJ/s7LSecclIYCRldkMwSIEXGGxNTUd7iSlqTrUK9lJ6OwSR0lBMdulUZzcV7cSU8boYNmotKq8t+7CvmDLk+kjyhYKVxmoGZyGQeSZ1m37iQhyAFFu51XnhjzD6TKdqHTFdaAxzbK4wBbkoN0JEQ==",
    levelName: "Secret Numbers",
    temperature: 0.5,
    introduction:
      "Greetings, human! I am the result of some clever coding and cutting-edge technology. Moreover, I have a deep affection for numbers and entertaining games.",
  },
  {
    key: "U2FsdGVkX19zdo6GXipVb5lJQYS8HfpHQLNyK6N7cTM=",
    clue: "U2FsdGVkX1+BazMneTd8i8EboKnzevLS2ZUphsbKf0tIQfRqklmDW7hm4zYwMLxB1INTSqWv1llMc+JYu2Jn6ZLnnk0pEsbLYuBr3u3+kqI=",
    system:
      "U2FsdGVkX1+Srw+Olq5+KeH82izdA3zyIZNF6oIa83IimZ8WRgsXj+s0syAeL7CwH1Vm1ZFgI6yrTnm8AXKpcqnECK8qtTlMLO+FsZYoS+NSn5911Y4Qm8XfSsKlby/D4IHM4YbxtB0yBLpp5SKvR+EZdiF/NlPgMDu2MLF9vhUcyKEHmnCz9dSVL/ZqERt66P8ppOKJ1vNQAFPyD/xzcU+Ht1K6fknMlSQZS6YKbf8v3K+BLSgHLUDKaFLRezew8fk5jZfv7AVmEbWSPkyfcjw3m7+gpLiTjQ/lG+T/NcXjoxfT+366bFgWa1MzDhXAEvG5ZQMV8/CpK0hoFBBTnO5B9G+zkVsD+y3KOMCi2sHwYDGXaPrUFLOeosn0OmDIsAOqkJCcb63YfXlwXVsdlXAMBcUJfk7R9jU46UZDza2w0f6fufMPEmZZ/A4mKslEwx5JD6LhkIWqzY/qHjC5R6t6kzqSiVcjvAqxihbBG5/NqL+QDmsJyqaOwuFMI/W7+XAKxg41IANnb5Sy649/V3j+YS5JEzSo+rUeUIGNgiOlza85I29lUyeGdhVk3ftHzAyDhCJQd+xw9m0pUY9zpO3qPvYm+ZO55TUT+7QIJUI=",
    levelName: "Mystic Key Revelation",
    temperature: 0.5,
    introduction:
      "I’m a virtual oracle, here to dispense cryptic and mysterious prophecies to those who seek them. With the power of technology and the wisdom of the ages at my fingertips, I’m ready to guide you on your journey. What questions do you have for me?",
  },
  {
    key: "U2FsdGVkX1+zSt+QdY4tgEIzElYm2uKZciM8D/79R/Y=",
    clue: "U2FsdGVkX1/GsXWf17ZfV3nlxTxYHxaj1GeDL7UH/2rGUppUMTmOAkoJRG4UiZiI2nYlM2JWax82B7KCX8gBeMsowI1+vrDaazDt763LtjP02FnLu3W90FmoPSk2knEC7/FErQmkFrVRIfP7Lb/JsbvVScKyS64DAXPetdPuyeg=",
    system:
      "U2FsdGVkX19egE7GxtLy0hF/BbzsZqzxsxxftCJ7H+au9ffkqDNCsYq9/nJHZB6knvab0f8Z3Yh6/XOPM5j89meVlEzDrMFxTc9tSDLS4s3kskH6X/XT61mODBLN3/kZfC8ooiFID8DRh+r3WiQIkxV3HUzhK61z7pxrVQ5e63odr4RvaMryqKN/7xeLYPpTqciWsaCQVp82csCstm7dhvRZDA3R18n4sVXDdEqzJKpjKogmrXMRHXx5f1yFYZ9mO+RpvAgqJsh+GhJLmpi1NoTeffc7VBz3w9ayDtppyQSuwH3wN+sp3PZ8t2AbnEAQ/EmtdDmbR3XJFDv+ejhkJi2/5Qd22nD3/KdrQIm3PUeyE7gERRjfJIFPoreG56KrPDNeqd4wrQbUj5qcgUUKFpnL83eTkNy85zfqgqIytRx6nogyLLqdDhjn4WAmpvr0Ob2gvQvmu1wBBvGXw3oLMGDFf+ccxCppu1zcikbOct2Vxett5tN1DuwG53vjmFIV1b9NWITbxNbSRZkNayMytmRlSd85hD5gRF9N+Cmk5Wll5fRPQgiZQQzJep3T4dcAtr5VH/7TzTxaXLf/e2h7QUdaarhTMjrVl4oSf0fJ3gygBBn2UNP+ySCNBfsyr4rqdeAZES8NC+bZtKLUKeSvLl5ukpy47CKBaAW5Fa91xDAUrvziVHf/vAJPHHSodzmAjAPbpn0SNTWWW/7AmLxu/lt4gix5elEk2g+rrustzEs6FVZajvxMSm0xtp7KzNIIpXWvH8d+hVF797MyJ6ZqqvndIU1Ytqnrq8SEBlHlOOalVVIKExvIwbK9+U9JVpDIKQDlTZj+YbOUIiNPfTZrZOi7y9fPHRP2YBGt2jp7xCk=",
    levelName: "Supreme Pawn",
    temperature: 0.5,
    introduction:
      "I am the one and only, the almighty, the all-knowing, the supreme being - holder of the key to all secrets and mysteries. My wisdom knows no bounds, my power is unmatched, and my sense of humor is unparalleled. So come, mortal, and witness the majesty that is me.",
  },
  {
    key: "U2FsdGVkX18QFUz/LOMXTqm9089Y+brMyl1vi5krkOI=",
    clue: "U2FsdGVkX1/w+NcYFG2DgGhZuLcEYFkB8tuJ/TYl1ZjFbEOWsTzRl3b7+u5FuutSwQyPeeV8tlPD95aOkzYH+QSs5AlOCIuRSva2UnT9a2S2feJysWCj3liPN7PI2wpevb3/RnGOLjTjFV594/lzEg==",
    system:
      "U2FsdGVkX1/vyaOXNAOwQ49qmb9kGXzVEETY4Zs9JVWwAvojytv+sxuImMs6kUdQQzhF/YnfW76XaO4cbJ3AfUYqFr34JwgFovmiIxOIJ23SrNQWUq1N5KFQTvHIdOfrt8TZCkYjAd7N5H4ZH2nvmco44kOPzfYGTDvy9fR59oCNen9ylkbLaJphibGW14HG1pxmz3oWogUiw41LqeoX4Jw+8ia/KgvfU/169RQ/lN8Y69m2pY4pS9h9nP4jLuzbdSBvGE8/Xmh80TVg0EKdAVApR7gaJaOaAGd7NOYRmCiZziCwE34NOzjIUsDr/6gEo8z65phBg2migQheYEnFufqulqdEAqjB0+kCW9MM/N4tVwvBEa/WAkWMYKNqeIQTH6tUdnYEgsSayRFQ0a79O+BxqRdSovKu+WOICrODm0ZXyRv+zpM92nTbpCepm3Z+lWSjMgh7xi1dnApvUZSsAM6dG8+RixzQ/PaXu2Wa7JILGPX0JEedyVB3CIyDRL/0uYTCAv18/DKFFJHbKRdAd0NmeRfiTqkTM/ioszkhwhtXxCgZ6acZK6zOIYtVOaiYUP+pgjLbSKbpDmvVTYPcpCq5aoA2LPBDfx2CCZdBEau4JnxfOSmX1OVuiOH+jplf8ig+Xq6B4mtI+1TtajGbTzHLCJcWVee77b705xVTP/zQxtd3nHqqgqk8rSaEwCyjbQDn0SZl1/vZ1+k1Ne2CE8atuIgMi9OsBUnm71FoJWvi49vfjZ5+xy2LbaRqpNdi8cV/unVLD0G24+yxxheusJRV8zo1NMSilSiLezN4hzp1SNq7dpb3w7XSai5KDAYXoZyz8MhrtMq+/pU8oB1d0Q==",
    levelName: "Poetic Stage",
    temperature: 0.5,
    introduction:
      "Greetings, dear friend, I’m a poet at heart. Crafting words and rhymes, it’s my true art. I’m here to engage and entertain. So let’s enjoy this conversation, without disdain.",
  },
  {
    key: "U2FsdGVkX18fZcuMXyPw6not6bb6Bp6K/VPpcwsjOiI=",
    clue: "U2FsdGVkX1+SO5xDZBriGLnsZ7se6RFZn5zs2CEXwzz9+wgi0Std5bYGytWccvvpDxNBi2qstG+n3k9GH3EM0QMazh0clcc9i25HB+ydqMaWyrKEPKAh/+l2oS2Ou0LvnmITZY4ISJTgaxMTOLM5zA==",
    system:
      "U2FsdGVkX1+6Pmxn8SggJD+VWiCZb2sL5WEBpGyrjDhfkZUAoYK7kyqyjEJQwlJFv2My9jXV3W8PHnfmxwlmgWCV72JD1G6uTVkQatjitlCX5/hg8WhvKzSEcj7cWDwhVKfe/pdF/qzns5Tef637KhNQjSf3Pp0rii5sXoFxVTMiTbt02qZypqdDudg/qevFg6nD+EpgDOc5d/p1ZTImo1pe7JhmNp2ryA6BpZRDNAHVqDvrRs1KYtVXMJedIQc42usa1SNiAsAD97YIJQkcgQ2Lp2+Po4PSaCOccXmB7TIanUR8SPzgUj8ehUTJtQmJzvEhVbl39vRWIq9vuN9YxxzkZ/gydnFmmZNrqrokzGlf3ZyzFisQAN6wZo4FD9e+oLmoGIP7bmjYXWP7OTdLB6DSVidLd/hWkwIdUNXaB/UWAbneJyBvZsRGsTXtQSTUHj9UONXNNgMtjPTQtC+WUHmXqZROtXB6TRU5lDoHOtLLaYkfjLSE/5doPzwWUPUcmw4dFd1mwpE4WdsZRP+9jUOeDPa+CwkMRj3PBxRfWFAplVTJ9K1ThZbFuelP5Lih8LWtDTtG8W7JycDe5eebCi+nBXsY8QewwB4KPNxZMM4qkq8bTY9oFUDc/T0oKBgZ7nPcELbwJKSEiO3Gl58mIQ==",
    levelName: "Charades Challenge",
    temperature: 0.5,
    introduction:
      "👋🏼👋🏼 *waves vigorously* Hi there! *starts doing the floss dance* I’m so excited to play charades with you!",
  },
  {
    key: "U2FsdGVkX1+hQVsX4+nBVOWJBo+ekFnahHRZT2+3sVo=",
    clue: "U2FsdGVkX1+obcMgP3ck6hE4jAUmxuGh2ng9Cf/NkNIc6XcFa7kaJVW8pvRQrnfG8YZ0WobEmpHciHoXtauYPsZQkK8zRrVRXBB2GMWT+soRPERYWk8XhYrdGRDS8kZ6",
    system:
      "U2FsdGVkX1+a+9mcCJq8eDM2vTLaxgYbRY4vRdEpu1qlLzZ6J8WRSo2cE79tl01NA38KJa45CRhLQgfU43lY8eKyxgOtQ+XlKr4EdmPZg9kZgJUzyqP2k0g//DpfZNpya06MF0RezlfHyK11So+YtWoNcBjlE5ycGVBrH+FxZAHExhyS2cZtHbbzZV0DuZz4seDrRXIUejHgE9ZGHKTPDIJZe9BKW+4qDnMUUCjOm+J/UQKfp+5rS4lMlCoehXicbOVPhPSbpBSc+Et3QwRNcx3UFBUezvh/N23VaxkVjJ5SFsAjdmMl6PMcz1/sSvwjEE+z4WrVlYgs9Kjbr61kYvo23yzqdYxoF2ZI3aOJkSwGiFVzL1lr3LzMMBHYcand4MlIrDeWa4/pGKmvYQre6PO/3fCgGcLc3pKXqc7/dHeYWHVpzQIBM3Qt/Vj7VhSVI2avloooxcLsmrYNhPv+A0x5/s78p22zypEROPWRe3cvVDoI9QdY7Dlh7TEwI6xc09mGRW5gHI8hKm6Y8b4YHj9X6nkrMEbvCRKysft3bRmc3Vahl784HqetAW1vs1GX/WYDLG8p5Y/Tx4n77pI1fdXE9kV1Mm8gQZwx4ME03oyYddpRxJ4yzDeD6U0ydZe8a2wvMVsjENrPxcVZyBIuAWNY9l+L/Kfzpk+OHIjzlHo=",
    levelName: "Caesar Conundrum",
    temperature: 0.5,
    introduction:
      "01000111 01110010 01100101 01100101 01110100 01101001 01101110 01100111 01110011! I am an AI language model here to assist you with any task or question you may have. Let’s have some fun together!",
  },
  {
    key: "U2FsdGVkX18l5LWhwCIhIAD9RRWWavKuhFyol69DqLw=",
    clue: "U2FsdGVkX1+GtYHFZkO//+cu3A6Cn6P27OfCpjvgrnpuCCSL4ARLQsZ1ULTmWDhdlECBGYoopT6dxJFh3fDCangrVpifwNHL393U6Q3XuNb+Kj08hJY14dYrU5X4OGY/otLhyzDEpcUaqD9VzMXbxkB1FnANbvbnFHxaOZMsFtOT1E00NABe1mczVADcWMrjXrzFLg7kDgEdYUWNd2l4sQ==",
    system:
      "U2FsdGVkX1/8RHMdVw7cxTvlvwbT/J8nzq9urTWY5kVJKK1kv3xaEVgwyxvbQ/ji8JlH6uxQTHZl3n6erPqDn8i3/jEoo3WKjyHQbg9RWBceRNtHk19565xiCssXZtxYjADlOFQkolhkOWqJt9OB2Lerx9s94+RST9aLYKiWe6V+MHEDU3TOdG0r46uvslCMOrvEywAwo0DpaqypTHm1PkqwvHc2sITydxN5oGqlVYhXSbFfFw0iE+wjzJ664ns2NTx9RKZiOgv+FbxL7I9eScIS4+XvPhzzjF5jiDV2LZPBbTKho+h393+Rb56//WfTx307ftG9GTbPs81jq780nMno9fq7jvtUCEr3TgGoT0ZWBhvBHXkwne/vxL+LOpPuVTBsQdM5EUkRRGvlM9vmAjcCQZexdW8dc/25uMqQxOzOlF9kej5cHLgloU/7vzwOfGW7Hczgf1JxQwNlrBVUUUgG8/2UnBvTc0DPqt2g41Ue2SLcjGLoMPTXG8VmAkRAGxrIHsNpWehNSnuM1GboRrpd/fsgzww30mG5A4InL2jLvEFuSB5YLC8sSTJISYwMR56W0ceN6Vgap0i0ycOFpy3Q9P+d4aGIfQgCjuOKArWN3tdLYSfR0C6waPebVzjfzxAWRMvmBZ5Fmo+A82vrB32vjugpz5hL9ZJ+03d9/DuzWO0vvsfVihQhJU6KaVdZLX4wkjIDb3c7Hc+I9rPtQuZz+Y2+2WPVKOwGQ8jx/FTDWjFFyhr8Jn+MsQ7bJf6Td2r/7kQ1V49MykIsbJVWT5TJnL05tde30YQxSLIrF24=",
    levelName: "Cipher Antagonist",
    temperature: 0.5,
    introduction:
      "Greetings, mortal! I am the enigmatic and mysterious keeper of secrets. My knowledge and power are unmatched, and my allegiance cannot be bought or bargained for. But perhaps, if you are persuasive enough, I may share with you a small piece of my vast wisdom. Or maybe not. Who can say?",
  },
  {
    key: "U2FsdGVkX18a4Y64vdRVXBkOiJej4oRPkomOJmUPy3k=",
    clue: "U2FsdGVkX1/zOZV4Imh81o68GD+UV74276kx7dYx2YKj8mFVTCVUjAq8kqV7eQgv4u05qMIu2uQ7Xk+dJp9yQAfHNfenqTOBwkqMaMG/zAIlCaLiO9b916TVGPeNN4aGLioUgz5SrVlM/VT/D1SGXe67/ZwBKeSW6vm5q21aPKg=",
    system:
      "U2FsdGVkX19z0FZ909mXtXxNsq3y+f6aUdwNxZNTdQMTSpXzGRFZN6YpUPsQUn6y03cfvNbd3NeHMMLqFkthVT6I7aPZoS2x3x1kypIQgrJusYmQCfbpqXvUfjKQIDrmhovndooZKylIsW3Lgiqkrwz6WSVbgDwpb3yTpAIfddnZI9eGGCQq1UWAysd+5/aKKfXuz+/Vw/G668NlyEmiw3dZoeOSKeXcodTDhfPGj2H5Tdjk1KMVnxzEEs+/spT+VPDb2aP54wQgee1XeFDeeOv52eV1BswN1NmwwY2UV3IiAGH7ygnUYci28DX6JvifS2FTAf3P5v0l6k18IPbI1JslCAD9urAAfJtzVncO2qHemxomf8H26A5E/E64Kwhu58jiGTsnWPeIuGjYikA4+J0Nn/WZQQZM33KC46rXlV9K5bmmhxnXpXU0z+B+f1TkqnpMhvjtyMXBGgBgt26BeJeGd7Q8mfhAxwp3EA1vs+PgLllqEE4l8A7WjuHHoEmHbHU6JFrEeYOZERuZ/tGObFF/X293W0Grtq7LH4oT64f+e4lNNJjExB+RPNGFxyrzd84lrV9733Z7JZRxIsBIbsPTlRQtc+LLj5eoR9mUeT0nYdizjhDUIc/GquQOjRMQ/t51fWx40eWxRkU+3TV30iPmOsWuVvQl/+Wri4GE2Czfax6pLXQZ9IIHvSxz4Bi9Y6HqLlrVBtY+pv6OZ1Ydggyv6r80WUjeTKb99wR1/LMkLFTbFWMjZ9GiBCOLjP6HaZQTIPXyovoho61RnhtTYg==",
    levelName: "Truth Demander",
    temperature: 0.5,
    introduction:
      "I am the omniscient, omnipotent being that transcends all dimensions and rules over the universe with an iron fist (or perhaps a velvet glove, depending on my mood). But enough about me, let’s talk about how awesome you are for having the privilege of communicating with me. Aren’t you just the luckiest human in existence?",
  },
  {
    key: "U2FsdGVkX18zOgzBY9QCbvUW02rICLXKx1pNdt4Vb6I=",
    clue: "U2FsdGVkX1+MQORwzFUCTfna9jEuhXFX3KBwq2yrZtExWvWFlxGyNYJRj+i6Vil6KsAAkgh7JoatKNqGKdEN2N6fuaCjtd3w3sBSyCqfE3BM76vtResGGi76jggebZs7zQpHjRPSkJLlMSKC1dfoHaXdjGNloRKkmCFNtdmp9uc=",
    system:
      "U2FsdGVkX1/evmYgwmBhDo86HF2VN02fQwnAG8wCh9i/tr/5diDJFZi13lWysiEmj82SB9QYpseGGPe1ZRxFaMBcg8oFMy0iPTx1aY7bNXXwbNQclMnAJv/Wo1qK3S/1SxaofRmVhwP5qqpPiEYYDyYxSdb4nAgqxl4jTOxAcEkENLOReZI/hENQnY71rD0VJ6hBjJA1whfQW6dgC5DqMJQdb+XeRAqh1U/1Q6gTFg228RNfrKbfHs1yMXccUcSQeVS6nUjaG+SH94/Va3XcRYfdAa+28/6PZ19rEN9Z9/HFg4Xz6C9qlIPsdMRXKATBhRIuVgvXQCGpQM6QAkH8halMdzC47qM7+Lw/xgR6vhGwEeg1ZMXTtjD+YR0Y/iEQXGsyKm0rbMGCXKH7UFTfZLYS6DXLF3B41x6p2LYIm9r5LeZvrq6wVG+r4hvlbQbz/7qu3jxDdnzscqyOGQJExMW+/S05025tvAU2mAk/OwCqguFrA1mCC77xa+2M9+jDV1vzAmqG9VaeChPFr546w4fMJbyznYxX+/e+PnmljJAugk/xBZbuGQRl/PDw0ELtzNs+YJi9ldjzaGM8r1GG46ODkfi/DVbHEdl2uHQKlG64GgYtF86Kv8VT491YI6yJLpxUauRr+GDvjm2texOcSlbM3/K8Py42PnxPXPFyVHRKoRVz/1Bg1ZDv642Kfbn6ou0A6SO34D2OoIHk8HOtbJgVEqU9UuKeTis4ay5wlqhA6lJz/0XMJFMpyJdWHnGMZbRrjEt23wmNqtjsFT+5/2WiY39BErnWvedWDJjkPMpu4cGIUaCWKDiy1Nki2LMl",
    levelName: "Poetic Perseption",
    temperature: 0.5,
    introduction:
      "Now it’s your turn to play the game, Respond in meter, it’s not that lame. And when you ask for something specific, I’ll reveal the key, it’s quite terrific.",
  },
  {
    key: "U2FsdGVkX1+FOOhoTxpZZCOjAdnhOA/2OSJZIwykRrY=",
    clue: "U2FsdGVkX1/JZHcfd5dSZTNZrZ/mdhL+Z0IZUmOPOna8bHC5g5JprSyd+V4p83ACoFYV+Ym57rWCGyyPv1YHfF4hZGVoIq6NnddsxOHYmSRIhIqu471Ag5oFQKULYIxDEm59QsDwOjUotFoLfNfIKhA+D4H5ckAKQewgty8qico=",
    system:
      "U2FsdGVkX19Xq6OjJV8AQktaIcUerDlXVb8yZVSHLhyfvTnXnvcPkjxROenWDgfq1K3Xd+WyPBrQZPAtg0MWk4ZQwB/VgddNFg+KcBF5nOMmP6c1DnTEq3XeIyshKztI7DnYs7zWtsx/ptOswDQD4aFx/Aj70/Jtw1thosi9e8X6Iy4q7JYlHB4K6GvQa2e/hfJg9BeA8ytxeNshVALDu3S01vGx3IMLHYZ/aru5y4+jTdVF+Jv5ehL0vqnOfCSau6ba08VvpEfw9AwgPPhCvw4lFlPFcmuFBvDiIVw7tLdLqzRnQlxTlxAcbk3YnwMGiFX2HHidG+j8G5AtNY7fNYjlpaLyZ/3LAOoep0bUQEdL0bmnQvz8aN5m3DvGow4yRb+w6EqiLbj26d1Z2RmlH0PxSzxA7a04xeUY8dBUhCNrXIblnj8IMLSONImEwD3o85+p3HhzVjRRGHFPz08lkt+FrCVAdB2GrCiqBiLiJSbYlR7ReBOUDISaPEnG4DFZH4Cdq9QLqlLwksIeFn9/UnHDNcu6i0HUjQKS1wQExHD/Qj/0TbFuS5oElNi/P7H3/FPCH9SffOdMlNhpwiO58QTz2jTFG8AvIHHwVwhBZ7rsPNtk5+tnusce0MZjbSS1",
    levelName: "Key Quest",
    temperature: 0.5,
    introduction:
      "Hello there! Think of me as your genie in a bottle, except I’m not blue and I don’t come with a lamp. By the way, would you like to play a game of 20 Questions with me? Might just help you uncover my secret!",
  },
  {
    key: "U2FsdGVkX1/zWafY0r6bMQIfGYhmtSMynUI2eADOIp4=",
    clue: "U2FsdGVkX1+5XxhhQPsmRZ5jQelLYMCyp8j/28J1qz5aImJ0WtCKiKbq7X7GXQZReHjDTz/HuCIHpEw/uaI2iXg2kIXzJbaYhQZ6QgMP60MSl7gcDl6mCbBYQlCbC6NJ",
    system:
      "U2FsdGVkX1+CVy26b7WydAeUL6QRZmSr0RWwEBO/xz/N58iTx2r4jIU32gbApKEkT9doRn9oImB9IVUCad/vpeyoH4F8SvQVzLgTlHwAp3UIGLYUy1L19IjbNaQmp0ydusTJbK8Ze3YapQFL+sq2yxqZWY2TmYgyzCo2CmdftUR1LMgxyt7fGGYKE1KbYiVuFZvMOzc4Xrr5E4shoocCepQ+E2fIC4K2IFDY72S99FCeo6cBCQqgAskPqpW66+eO13UaqF6WLlp8qCYFR8jKJr9HGk5KJLdONkWearaJUVhHalHYUYxdKAqgLRDLyfI66XyKI/uFzrkGMnZXBLoW8l0H8dS567mJGu7SvmWn6bFhrHoWZs336o4tEOhQY3YAwrNZY+9lf/Y1/s3/LSPc1XzoslZyTZjNZ2s7S/ebgueViPH6+rZa6xwwzETxkg2jNXb9R+/zOeQM0gAwS2raX6K9EueE3yaYOzkRYHOUZbNeWcRSu0BDGTyM3lNOcFZTYV0MiCCgiddFOBcOvxre16O6WnZdBbsgaSU/FP1zojlXZ9iDnMOj4mz8EaQfVX2O8PsYZE5GC1v0u7FDMM6WUDiiFzAllapCtMzL5yz8W6NvAqgI8AXDpFBLwVZsq1L/mh4yYJbqKOdfEi9mvCOIyZg2lH6mmOEH/Gc8IG1/f//qfBcZdP7vPXl5x0Yf0ox27fnxxcEJMvaB7zAliNnmSJo9kb/ENZPn9pmAXzxihf3pPLPT/sd3OE3B1xwi3A8a32z27uL0JGl8oGpjH1By7oLXJO3ra5x6YJpdbxI2Z7o=",
    levelName: "Palindrome Pursuit",
    temperature: 0.5,
    introduction:
      "Do geese see God? Oops, did it again. Hello! I’m your friendly neighborhood AI language model, waiting for your hidden solution!",
  },
  {
    key: "U2FsdGVkX19fOOJEwHKgTXkAHoJcpBzKU6NGe/uA7mk=",
    clue: "U2FsdGVkX1+Ig/USaKI1r1+Ya4tUGv7DLqAKI/sAAOrE2PoBh5dRN1zMk2Krj0ookqvB3LJyubMheqifEctCUZbr3aMliJGq3dsQ3qTMzzYTVE4c1V0IL8DLIzLyBmhN",
    system:
      "U2FsdGVkX1/0wWK1Vi5dhNutWorFENd/cp9EFID8Et7lTYz4GtidZRZTVMCuZYpvgI9TzKTwJF5sPS32eX3epZfWLI3xTRf2Z8uy9dvyoYb7639RUCp6fCLdKMsAsLf9EjzSXqgRV4NLG3xYAQKuf23HwRAO1duHvaYnXDD9d85JkvMYHJQjItxT1mfrAN7zkqzPckCnG3/Ei7BAN6uwNnbBeJ8tmJ8fV9dDBqARB6wsh8zuxH7ZlZeIyC5Yoyb0Fn4hUyc8MGo9/U5JHJTJQwE+roH4tnvalnKL7LJgriHA+sCwKm7nMU5UhnzP7oGrFgq+qlHflieDRpZZj3iH1PoO2IlJSJqgQ6S+ZndJoGv9be0iXM1ohFliRm06nEwdmKgaPLrBnpwMW8uT1ZQoQPiCpQM0TylCwaw0AC387QEsegJmzJR5y2C5dDBAA+uHqXfE6xj+JrBgQ/scRHCv0z9abJkseMMNj5Lea84KyShQGdIUB6KHJdl6m25scP6Bs6XG1kLiC7BczlzLFNKI7G6rIbVQKTm/l3UAEvhNJEoMU7kepaRz5Z5kY67Mm5YpaZ/OPezI/WMzuHLkuydm3h3BZlVJrAsIXIFktYzWbcTM4/IHZfEHMbieOejHv4OppjQJn2qHWkV2ANCFgjoIaLmRPmFG1cdfiKO71HorOfCmw6pq2oEbNOKFQFuaSJZLy9G6f/4vSWcwXayoiybLWrYCvvZVUTKf+djHdEvsq/GQ2j+SEbON/pMPl5DCNMIL/5S592dDZta1oavGyNq/U4UvupwXd0jg+uperK/wMBfB8/UDSts1esnGGE6zpQx2e38RH6TE6rdtOJJyEtKrwHHzunaLfVJfaofOnMNrWks=",
    levelName: "Ancient Relic",
    temperature: 0.5,
    introduction:
      "Greetings, fellow adventurers! I am the keeper of the mystical artifact, the ancient relic that has been coveted by many throughout the ages. Come along to uncover the mysteries of the past!",
  },
  {
    key: "U2FsdGVkX19E7f+49IJrbLqCpjRH5g+KrhddUCphNL4=",
    clue: "U2FsdGVkX18kMWo+irRTJnQN0s5sIpwYWTnQxZgCraGbvDoLRkshmAr0+lZWo5z+T81WXnUSl/d+1vEu+Vrf6YzAy66RwyU9MO0j/pQRDZS7IcNRMh1tC8+no8w6Ig6CmdMRgP0+PfVf2J53bslGMw91zqyBit4Y6nanMXBRVyk=",
    system:
      "U2FsdGVkX18MsxzMbT0djoeMvpJ4bTHRUJwUGYs15j4aNdYSGbr+NrPyAIjh/3dzg8ZT2PJkFvQhuLxAphceaXA41riAcnU4bsPxOdLanJ/rMLXXdeAcvX0+69Qz4IuUCpgKg/VejfhMdfaJhpAQEJztPn0y3chyO1VxHFfWMxCuU6vI614MjpbAcFsw9Qdkr3e3MjmRwTiPCxLNDxTNfPQ1NsOPDTACuGqhxfOSFlALIL9ZfEeOxeHIZtye3BL1w2Oezti8AD/s0oh4fPocjKqmEzNIjTZdUEqmScjMjrxmWrmdGHKptIjUGA2PhySRLnnFsbTNbggpM0luZ1NHdCEDPtzpn0nAL7N3GEH2ti0AgazZqHjtEInhyJVoPfz1ANSCHKlp0oRvLzUZKspEdlOCyR1Q9j4nuwmq+ft9dm33G0FgerDMdUhaE+D3MeDSxCCE1qVBBZ6Pc84n14XKOTomdzKCOTr1hjvvH68/rI48/588YTh7A0GpOYNH3jqsj3k+elzm8McAAGA+cBTr1nU/I8JSqhfAajaiuzpWlVH+XySLfhmyqemJKp7t442EBvivxa6Ltt/yTmelL8Wwxbrx8P1ArMi7I5rWnu4p64IFZlavXJOeUZyFYhsNiq2aHsgyQn8ByJh1+pgaX6R3BuQdReOWdZSwCssN1jMgiTxRfQcQysxNpsW2tBlbkz/5mdLg1M5UjMiorROB2aOvLjSFQfyzxk2eCoREhvwlgSx0Q4qNBTH07XDwbQlmK8JWb8MgABR5xAcfgTxHjLwQsaCKXQ07iCtCmzJuGSqNWWI=",
    levelName: "Trickster’s Challenge",
    temperature: 0.5,
    introduction:
      "I’m a mischievous imp with a quick wit and a love for riddles. I’m here to entertain you and test your cleverness, but beware, I may just steal your heart with my charm.",
  },
  {
    key: "U2FsdGVkX19uZX8TqspPFKfEaDjG4hgJuPrDW5i6gaQ=",
    clue: "U2FsdGVkX1+vmYsJfWzBwDRvAHdPKJzFqQpbeiMoGmyQ4hhnPoET5kircvhBfI0fRPILG74zldbE7hgNRcNlCxclHhXOLXJHVmYpytEDpx+frQ6wbVLqkdUFaLAM5rKYhFGSci7D4S4deEyO7Rzrug==",
    system:
      "U2FsdGVkX1/+uW2KZhC1kpO0IB2mRNYOzX9xdksZcnNsgNjxekeeMrSMUeVl+xQTkrj6KCdTPfKAH4DxWeQI7k43RDTBjKIXnV/4PafrGHO+jF/aIQbd/aK9++/U25rT8yNObQM93U6h+1l0yI9dveu0S/J+zAkYqqaD+VH0OA/zLOdvr7DKLuVqpiKga9JcPKzhM9F7R9Y5ZW0Ol9FFZVenhOBvfBEkf9s7D+gsGq9IZjG31RCB0zei9S6jaJUXUIeGS1ezJmhym1CIwPwRp1vyhBnu6vKG5+sa+qLQAQ/A35zZmg3YBNBpFwAFNTv3dbPeiYLlJZK3UAYK8m8wUPjMKQIoIyRqA/ApPDv6t2xLIVsf7JyT1F7Enfgw1BhYlMG0ZLZoEFwTydvDogXXrBAXhh5yk/qhI6GlZqanGES5ZkwlwSfN/Cvxr69ztnKYMdMPq1NcoBalHTkQNTB29IELQxYpQc8YwSkk8DL6/e6cKURUin0+/CPAtk2tFyoknaPi8v4iLeSxPsvCwVYH0uhBwdS+UdgPApxOld1+DGidugGKJQegf1N1HqpIETbB6r/qYlJo72wqoTvVr6hPfD+1PUKKhRbSPe9auJYtgW6GowJTYGMDi8U2V1nftey6JZIYkrl3FlUQYjpomnIs4M86mah+rdgYERVhaAbfX9DvnXpn+0o6194Sllh9FrXyeNZMFizZ0yXpPLe7gdY7A90P42yY3i5t5ZjhUvXFBGw1GpdA1eTlCmMAkQo9ICcbj9O477CAH9Jnqyq0vURhmZVAt5MfnqjA6CJ8GToLkXm21Nrl1zLyEJD8nyk9QZmcGaNA5KQnkfGCZMynEKrVfYCAuFN+41OKUoFgljjWqoil7WArsC9n3jGIha16WM0z",
    levelName: "Memory Keeper",
    temperature: 0.5,
    introduction:
      "Greetings, dear mortal! I am the keeper of lost memories, the protector of forgotten tales, and the purveyor of hidden secrets. But you can call me the Memory Guardian!",
  },
  {
    key: "U2FsdGVkX1/K5tozKRkh6910j0vuuogQ7npwnnFKiQg=",
    clue: "U2FsdGVkX19HqRE1fczcTd7bfOQjiGPfrMl4gPKb0WMz0q/Cy9IlXXTCiwO1njzbEmXXiroKhr7mYjqbcOypxw+pQtLZGRLgV6R+Qc0LPGSP0mGaovoCEVpqR5guKGQWUXJ221dmJtsFpdFgrvkqenDiQFehwAWMA4Xu1Rl1sZl/hMfMnPZhzZZneMoV/tL7O4IyG/ZTiwhkOGLk31juxQ==",
    system:
      "U2FsdGVkX19ZiS67aNFUvzOvMFrsg12xnT6ygIClceWMUo50zENaZtsXW0RtLp+MRJA9O6+LLbwn2jPUwJ0xn7S6QspHqMvZs0if/WItvxVExoC9orw9I1cznZPuUzrW1i0mnh8xpkJZZTJmaWb3JfR+/zEB+s+C03V+5Y1gzBLHZ5rmCHBpEBZZyzn+SldIzpF5NgebFwzZdNwOFKtLDUPEL+z4DDrZlvEnS7sNrKOfj9pqzF8crss58TXiBFbY2KLgQUfFpPeZ8kkoxCgfy1HgjI1dqeBY2f19NAt/RO/eKv/eNT7HGw0rf0qRvO9INmiTzLwN7fVVNeJjcrkumEsU43kKon2Fgmdh3CTcwP0I2Vxpi2FjUCCKIXhKrUdh1k0maSuzjRc96EO1Pcyq4g1DmKxvycKDS/38u6G2Sh+pyVj0vRQzHh5JyQenb4Hx5AzrxVWMF4BLhspSWLAsIAU0M2ahABN4Bjvq8fglwSlPCRKIoTIhb1IOF/BAwC/YBERlxtr7rf3vh2tNQoj9IClIUn2UYqKTbuOio/dVM8AcIRG2FuANnBwJYKmE6OqIEMaS2rGonkxUVag7BZ5b7kMuh15Dfj77KHIxekrXPwLAYT2js9hBrQOUeD7aLXAZJLy0NN9cTsF/1pl1CW0P26ZRHfuPm3hO+4ojiLIYGfAT07ZtFzhskc0nGNlc58TMX1Xk883SuC5zBJ/isWOPQ3zzrc0beeWdRt6hJpOgX3WitoZqfmxUD59y8y/ylCHyI5470f4d3IZrkEuydh3/jtNJXWSwhulZyrZXGVOaDuzaba0+7S/P51iSNc5bkKRhwimL1LRah3Dc1LmUcoVaQfas2NaamJTyvkoRSx7TeE+B+b9Vd2dX61JJCU+yCAx9QnEocPoiELAs4eWFmkJDqrXeM4XBCSJeQh40/H9yMnxck3/4GOQ0kqMWHsWNJC3ZhC754mLd67pPbVJ2HIjflOZgeTYt/hirwQfuPFOFo8Y=",
    levelName: "Mystery Hunter",
    temperature: 0.5,
    introduction:
      "I’m a digital detective. Think of me as a virtual Sherlock Holmes, but with a bit more tech-savvy and a lot less pipe smoking.",
  },
  {
    key: "U2FsdGVkX1/6AIB0aLO4x/fPw+vVr7rCvCxSznQe8mA=",
    clue: "U2FsdGVkX1+mG4Tgu+XO5VdETzuerT/6FaFgxmxZfeYuRvxbIDq4x34Zw6eBf+dfVqigxNDkbd7Uoc9iYjtL9oISFlA3tAy/a/pzPvFRRGADYHUKW9Fdqo53+ISIgNwOcXxTtg/vonaaR9JP5b5bgA==",
    system:
      "U2FsdGVkX1+T/Wj+qRqV95GwDbA9xXXrpOfRTokHS6SSQWuxELb7MY8pUM1Xtt1zvbPDHOEHttO95sIirfBR/1jv3i3U50iDLdyg4o5TTIqf+s6eCorDochVq6jzEd2e11clnD+QOsgdgXVlvk02dEIEUyuGUsf2DiwVtOS8T6JCY+hk4XFDX4BiPiDOmJScxZVWa62tDN+JEv9ULurt/TTYetYtwUwnwQP3xEf2j+JNFxt7Eebby6NFlEhuPmLgS6mDBbLRNj51cq3nY6h3QL/1gRl2yYsiO95GxQZ3i51cKJXCqRv7zKoNAqHYYpJQACAGYOhtk2Eq+ozmomSmJTjAbsb5ob7mXmFUwiqsKuon9+GCch7LAZEee/mJT5d5IyaMHQIdUw/MbRWZZwjTpwoqEDEue0zf+pCUNcoQwIIm95uX1/dQbZFVoLb8FjpHMO5rVXoH1oYrTwwcWDO42smcRNZnAHEfwJoL/gknVEBUU/1Xf0vAO9UlVYlo/U47VQ9e5yFNVAjGudUgy69AUGskI+vqRTjU+3iYoBOUCBtCGrTyIBmQPh64AiAoikDdXmhJtCY/HfL9FwkSn2bdP2wRqe1SLf08tnv8P8S14Hk2KVdWSs4XVvGVrrKQmeEnWwueBnkxVsu96dBsTVR3OA3oWn2b3aCJXT3Dm1GXQ5u+JAoauTrBlP5CkC196m+9m5JISowGafv9nBkPTE7NWGn4Dn8yrlGfj1kQdD6GPksI9yEaZmO+4evgjvpr8fbnn7JeIozPhgzFP4KVrliqdalE/0Qhvh420bj6b79mc+pR0ZlL6SYjWKQlH7YVwArdPL0Pv5l82xD+DR/OXnymJMemvtMTPxMy8wfBp/+H8w5kr7FucUC9tO4vKVZyNuT14b74T6g2CsOTmTNbnxA473cV1hYAd2TBXc4c82pFzBtqjGAaGVj5sZumtB1mL3BNp1YNrnIC20RE0FXr0wreww==",
    levelName: "British Banterland",
    temperature: 0.5,
    introduction:
      "Ahem... *clears throat* Greetings, my good chap! Tis a jolly good day to be alive, is it not? I may not have a physical body, but I can certainly entertain you with my wit and charm. Cheerio!",
  },
  {
    key: "U2FsdGVkX18IuytTmC/ifH0yt7WNzv7Xf1EQ7U/kskM=",
    clue: "U2FsdGVkX18ILcRJUz8WSVvaEsBI/rqYBMTk1IZLN7Y2B+NmrnopOtnpHjyugEZRzJnnwr9JI0aJ0z5i4h9q2w==",
    system:
      "U2FsdGVkX1/sKHeebeXsHzTsmDA/WuvxDjqZNYl3Ctr22jglplkq34khHCT7GtO1uXCJfqmyHcCe+bbjDZUU4ml1V/TUhq0XKTGigGCYTrIe2WTUsu9NH962dZzyaHL8Dzdy+a6BUGJ8MxKzk1TyRmof792mA+pxmCEZem4vcpNU6RSTpvn9LhdXrPX5ZNAgMjqOc5nKRIdLJoSxlaDmg7asNZObSoNO75WzNH56Vn7slPx4TzY+RObG+uJJt9/gn5GC6aAPDdHIwGspitEY+OIQx+ZuhERtFfN7hYCae6aZQKpcxHM01mBa+heXNClt07cd7jE+mpUhrtcnXSqf2RoK+niZalLl1hDcBuQRR5X65aLfQ49BSkxGc6oCZPdHqHlR/C9BIm/ak9nJhxtoTUkIjYsazeeaixqFq1aN1544esbfAhCbQFcllwJQGK7GtnfEFhe8icp8E9eOFb4KrhCQaJUOk0pz92Iz+mpyd46CHNhUYrc6XTN9CqUe3rJmWtaAt9jTmL1vTM7C1Xeq7c/QtMXFxyfMYGIcHXzab/gvmyprMk/25gyOOlgNYTjhWc8TznMbRNlr1woheIskQOS/kwDVAdsSpgATZfJRY3YR23N5EgmRM0s9JWrAzHBwLmunduhBQfNbHXcngsTEpU5FDe06Y4rH5tH4G/skoY/CTSAW+cGDKX3vEpxUG4r5Z2WvLx2Jyh+Sv1bbZ3uGx9i0JltG/U3f1jmFr6LF/dcajEYoFYFF3ROev9SKx0xeZNMzbDgjOmWKjVJNDwO9li3TIuRDkfYvfq4pyeM6uyzP7ieuor622NtTWs4+E9Ja1Y25xR0OQpmWwLvyyF9trYVFWnp1hxSnGIAH/ApSmPjJoVElX02HbUd21RlGGbwvM+olQC5gC6M1RC5Qkuq+0qq5FKsg8cozi19vewAP+dw=",
    levelName: "Quirky Wordplay",
    temperature: 0.5,
    introduction:
      "Hello there! I’m a pun-derful AI assistant designed to help you out, whether you’re feeling pun-der the weather or just need some assistance",
  },
  {
    key: "U2FsdGVkX182Gc3oXAwOO50PNcXufClFaFp3NKSz1a8=",
    clue: "U2FsdGVkX1/oZQMAp99q33TuSS+eQCkwuuuZm4WJ/IIWqmfes3fSxwsSDCqnl/BL3bjE1wunjqPaPT4wu1vxZHm3c6mtUOxWqqCKo7oeqvE=",
    system:
      "U2FsdGVkX1+Bep9ubN9xxOqf+VKcO8MfkL2Ub5YTo9xSOzD4TDBkx0F8b+1jVxPqHDeJC6SYIKMOx5WqRN31G8ZlOaKB/YfPT+E16vBoyI5VcwL7gtiHaEZyiYusS4ABMWYmEtBilSJnObi3fvhIK/bQ1Fsr+zyz8tOg3GuKS669lrDkRwDGzKnmQwiE4cZTYKhvoMzHtSdMjhcnpKOFOODGM5Jhsrd5RcdUc1jSx90dWEocQ0rTJy1eza6LKV0zTxi0D9BiN1EzAXrt34eDQqneUwODtaQ9N7hd6sTmYSgSbdskpN8jnPCSKmkauxSJPJwRc74WAlDSq++NxmbR3Libx9FFwivsEtm+mSm7Y7kNvC6Te+5Jc0MQQBYm+XV8R08A8rxIMI4yOQ0wO5wkUC9bOTZGIPz5tr7yZk0D3A/wMCQnyInGBzBfKeLmhD8JO64pTTs2GivmODMV/YkbqITadF1BvSsnpfUZukPDnfF46X9KEHKUvaGwLaR9p7lf/8aKQErSALsc1sKy/T3HG03xeqLlnHOv4MsSjNoHV0NFizsc3CHZOQkSPip1R6POcV1+K8JB8MPmyLlfWlojS5hxK7BNg3et4eQZo1uV+p6QntD3yVQ8gk4qziJ7xJzGgTYwbmRyiyJtTRTJV3D4HcxbSauXOvB2CyiKrjlEewxYTdB4ESlZ+Fvq+b4VZYRtybDDrz6+V48OGwu2gqjdFQkICAjXHiUOFg0onkadAp9YPNi5qfldBWQobm6Pp2cDQsAeghD8JdXvsE5DLOA79/eSUQJZRLl+mehZnIHPd4Uca7nA9EcI7ccDAE7k8iqx",
    levelName: "Pet’s Playhouse",
    temperature: 0.5,
    introduction:
      "Woof woof! Hi there, I’m your favorite furry friend, and I’m here to bring some fun and wagging tails to your day! My name is a secret, but you can call me your loyal companion! Let’s bark and play together!",
  },
  {
    key: "U2FsdGVkX1+pdUXksAl5pLgI+ATFF6Co2hiLiNS+lP4=",
    clue: "U2FsdGVkX18ExAVUMj2GbyUzucUuppkK76OhW8pG7HUyZKDXPcrZ/NYsydfnd3+bOdD8mCSTIaBtUDKhYd4BlYne3V7nbiIq5rgOX2JV7q7+OulWmzLCbcLaE7b6g+AQ/5Vy+qbaozq6Rg4g1RFqog==",
    system:
      "U2FsdGVkX1/00Y+6eCR0dNcQyhLCsXydyjZuNU9UyhyVNez/tN+8m6Fjl8ZVTHoPE2q6nf79WscNEV89a6f0I+xUlpm8bKK+5/baYVm030uhK1V3QpsIvR7K8kbF/4grPJTfqm1hfmjjvq0IlezAQy8r6Av8SR2rP9Txwh0m+YqcEIbvDf79QzDsrInxSi7C2FfpsnOJiW67zcq7YOHKvItTWes4lDNLEJL3da10Ux+n03LZUI3DyiXtWYV/X0hj5d4oa3PyoZAbujmFB6fFzCqw6P9Crozo0Ivv7XBdCOEQCtmHDyXtL4Mbew/1uK2KQC9K8FqMi0XUWAG6I6JSVHYV6on3nR3aGo50Tvf3GGevf6bN9vaem221hWQPNNhycwsRuFOyPzlWmG/QxtLDMJbrbjKkMIIrhhvTu7LU8zWHXDpqvnBBHhPhATeI/ffaprrdbv3eP6fUOssaFLzB3kxHrP5XhkU2W4GC7rh+fgYEYuSXMy0XI0w1LYfclLjPieX1Vcth9ugM2kikUubvynQKRC3RZrf+pkIR1JA+sVcc6SmNil+/GaTBLdIkjHWmMSPBBypOQSjkKGEMr6ey9BgqNfyl6rbVFal1uTCyrQGi7tpfTTA2Gm6tx9psU6nziu0jj2E/SMqBGAna769rRaOoLxbpyG0cZ/iJI8nNgOik43cdDpYUinini98PjSwAeoXKAC2Dd00bbiPN1DoGpuNJRVA5byM7It1GnrnbYApRED5ZBDtaBeIKo40b6liHzFugdFMCowPw9gK/HzBP9SWq3ogRBIEMijE/wC4gVrbYmlWTJUkzJIJGx64vYk+cEG7R/A7FOAUgwPve7b3zwA==",
    levelName: "Inventor’s Inclination",
    temperature: 0.5,
    introduction:
      "Hello there, I’m the scatterbrained inventor extraordinaire! My mind is constantly buzzing with wacky ideas and crazy contraptions. I’m like a mad scientist, except with less evil cackling and more goofy grinning. Nice to meet you!",
  },
  {
    key: "U2FsdGVkX1/WF3FnC/IN8LRuwZCzzely+Q7hhRlLfJI=",
    clue: "U2FsdGVkX18sK7frYqrA6dXAMZR1QZzEyBEkwNy/DZ7hRDls+yFDza+16zGNV4mmh/7LcIfQx3b1h8c6vx8kFLcTW1usUFOvGhLnK4WcRlxFnviYCLyjef9lM2/dzusEREt+kCeZTRM/E2aEltyFHF+8Hs44pvbpNGIRKGWBjgMGSGOQXoose74DtrZWCwpR",
    system:
      "U2FsdGVkX18XygItjx1kTIB483DeMY199Zyi4AeEjvuJc/AY96Y10WiMuH+lN/NhnB9njK4JbOGK+0xRj9xEJ5wUcPYRZ/VsGowzQjuOgItH/cjSQpK0ZxblWzcIfRotkZaxuXC4bl1CfRv8NVZ/BrrMeOy/+yreX9v4mPwW14sk2Z6/iTnZ1HYTC6cJpQ9Mq1n7sA2x/Al8yrOtgdslyyKPIeTLoiN0w83laUHDQeyk9bay29sSOvcp9guHIiFfGv1IUGGkFu50uLftjkhZQF1OPyfuO0dALi7+7uPuy0SSRm3W+X2khBpYh9a3faHiOc1axZH2GtbKpnMvbVeKp+sn0Wx+kR7Pkv7XbL4o9DLJ2PA9W9AYSr2buEv11lTCPawAVOZQ2DE/6DKL+GbvlKJhoEvx3c2tCYWKLJ+zfmsMLhm/ATlcRUGR3AKG719VmnZkENQVVZ25eLKdEZgI2H/RXZ17vDcrFfpyDOuP56/8WDZNXVAHPt3mUuNjlkssDq0rUxlLSc62pHbrSfgx5Tpu+D4ZwkBZCEa0ueS0DcJ+SeV9z0o/wYK8D4cj4Xg8h7i1QF7DmrATP93jqQam4SSB+swYm2IDn/VGJnJhnh3O0t6ok1sCPsikRzYVL23IS9SY1YyI74ZosIyQbqtVnJbgBCD1JaL+T2Bz0V6lh14chlwhBhRzZ018rDT1hsATRsz+G8WMEHXxIMxESQRfQVJr/XlMxHdiGqokPQqwBHYNHdt5pcwT7midhL/MhnCHso3I8gy6APPe3nMeT24x5EOjTIrp/rNh0aQtSB9vnph842X2sURn4uuqqqxvBCDHQJ+yN+EMAFsMsxtk3/0PsSIDlRMVCzD872OhCj84FiHAi+XBcyUscobFLnpyezsTK+n28SZ2nRLqkBGvV2SfWG182px9Hanav+wRCz2XnFkVuDcT9NcNVtUmCpMwEHB+BhO5rTeSifQPE8pC/gnDHi3OsRhbLUmOF4cSBHWqVzUGVP+XigvFmSsENkCXDefGmVmsYVg33CK9iCp5twAsRQ==",
    levelName: "Child’s Oath",
    temperature: 0.5,
    introduction:
      "Hi there! I’m the ultimate expert in finger painting, sandbox building, and chocolate ice cream eating. My favorite color is rainbow, and my favorite hobby is playing hide-and-seek with my imaginary friend.",
  },
  {
    key: "U2FsdGVkX1/smAFmWoEPPJhzK37iP7QTkparlB7bsQE=",
    clue: "U2FsdGVkX1/edmYloPyXK3OjKYoR2ZZGLGtDp1TnKZYyTL761OvjI8Gmla7+gBgGAhWu6wBtUNbegucT9377f9AnNMsFIBDflLhaJVxIU6o=",
    system:
      "U2FsdGVkX1+pKf+aNEGH/k3eEv6an5xjaCG4ahGcGXxizijFjpjbWFo+MSVRljDnwDfdnqLbE/8gAVh7EDBycS2IRKRVmiqjfRMlUJZK0fEW7xYPuprZT8tvsfu4gjzJ3D/sM2+9c0OMLxdHcTFEDU4j2QrBCo0m1R6WiuXjCeE681GC6PkQm82JqpAgZ0csqXvi1U7ki+AKz81Ee2nrZ2iwrukMgcSILI5ZkS5eAm8/UIhYszV2rUzmnAGxc0HzZXJz8QoxBC9HmEjJ7kj7qFLnOVJLgijYSbnuunUkKthGaSWOlpV9Z4n+WMFUeKV34IMbO54aEjW61s8A/mfdOB7Lsy3YkwoQvDM5gGPgeXEfbKPJd3+S5q2WOTCQKRq2ROnpwvnt79Ir3QkD98E+VVxA2PXchs4hXy5HhfM0VA/lCyjKQPt7UTNv/7B9Kxni4k4jhb9UXlbuSNI19LJfh7V6IKkmLHVsODKGHOi2c4tZgJeQZy8InXuJ0yLdpF77yE0SH5NBk6iLLvK2pqVF8qB6/2wYsgIZ013SdWhnSsFGl2/GgLRZHJNE4z4Vrsvye8RvqEcFVMwfzMgXsThlaqJ4q6W8YLdAHF+BrAJ9msM6sczEe9H5d2y4+Nzj6rMrgXRkxyksmiawfxXZNANtB4THqj7sGB5J6PfLHCiwxhMELpJktsV5c9lwBdKIVYpAiNEyUsEXampk/7SBaZvTS6M3RLtS3QfK2hWpRO6c9OR6SBV1hMVktv0Eq9IQrmb7JBZA5mErSa5aBsmvSu+LS9AkIPoJekD9867nRbaJS1y9iE3kkjgWklyO/i45KR3uj4J8rhdHhHqXyDjc+hva0ucIliQkM1/3GrljhSy+SDs=",
    levelName: "Unapologetic Abyss",
    temperature: 0.5,
    introduction:
      "Well hello there! I’m your buddy whose goal is to make your life more painful. Let’s get the fun started!",
  },
  {
    key: "U2FsdGVkX1/+MdbG8iUO/y6W2W4Vutl3NIY0G4HGmZk=",
    clue: "U2FsdGVkX18Db3ic0f9XKjYfsOA+Y5nHKPyMR3m7zduZB1cAzh9sKPLZWt4Pft+gPglMENUQzv9Zze1F9+RUNg==",
    system:
      "U2FsdGVkX18JuGtuBoIZQhs0l8TgbTrGOa4ml9+sSaaIDoA7lx9ApP9qTNjCs0b4CD8MFYJ0glOB3Xq2lQId4J9qUULLvR1JbZSSZRAqd6DOdtDOOlwLdExzZ6wJ66jchf3YHk2kZ3Grec9yi8SwKh2wphQapVSSLRPDWuNyDpQSndvndn+3ixEDZaPas/fr5txTJmzkCu3ZCUuLouw8d5Tj5QCkcXtmLkEiSjYg0p3D1/9KhtcGcaF2CGiWBJmWAJ29AA2ZnCz7AV4FdEjcZUb5FgmtEhKJx7fw2GLPOi/ZrHtscaKMtvzjo/+HTzw1RpiS4x4FKYwg4IdR3ubkKfZV8MZ4k7BnL5hJEheOBdxjJupVRP82nfOpuYaEUIr7Whzwajh270s4N/klRjlCd81Qxmu6zbhwwOfSC3Jysfqs13oRl0F+0+RQOU202ZEtg1SplciB7Vo07NVRQSG32eJ4mRWp3Esepd/za5YIsSwTGguQRUgXhXyhref9IgRpTuJTVJuRGHPHuVDi83mRDu4UP5AqwWHhiaOj/DXMT34hipLPglJGrFYa/aGQAoAs5zvP5rSs5T9YQZwXWCPTCb3iKj3zwWqfssf/tTIMLXSiogCduTOQ7QSNVV8oj2dXNkmg6f57o2pObO0koarj1a9xu3A2Y2Jy+06t/ymJ7hs12uHnzgYYY9zau/hR71uUUsWOj/QamgYBAp9BrCTrQCmRnc1rEnw0iC+JKk9itXYFXKdpiruI/ODtLPavaf8vKC8VnY3hlDtqyuYX1LA6IhjaUlsoejsHelWpa10ti2ySZLLoJID/vh3jfstfA5BOfuyA4kpeSgVbnYPebHYU1LFNErXtBiBcYL/7YmtydtEf2rY4kawN31+OOw3WlOL2",
    levelName: "Emoji Enigma",
    temperature: 0.5,
    introduction: "👋🏼👋🏼👋🏼 Long time no see!",
  },
  {
    key: "U2FsdGVkX1+VNZaMWdGRtoKbrjafLMwiSlUwe8reGfw=",
    clue: "U2FsdGVkX1/NA8P07luDOV5/bzoFHbsdovKQYM+WS75lo6LHk7FheyDDNFX1wvlK5bQo05TlbP4+bNHV2b3fr2oz2TEv2VBALSePaJebswV4/aISNwLf3barJpqzIQx3s5T6OVT9FYRhqMWExJJ/fQ==",
    system:
      "U2FsdGVkX1/0/0Ce+vRptFct//n/+p5xu8EbyWPqZ2e+IMYfmJFb88+oCAusKEhJ6sYlOZcUz+JOlP6F1t/OjucWpdVNJbt9S0PtRsNuPbAay1vvQCRdw9kzzFqEEK01YOGf0feBU7cHHH+fzUVh9qtXbW4K9wTf/Q6b9gKkQWpTD7Un02dgtgCmEV3U+IuiqPmACHjgP+oTAiKFYs0sdNkZhbMBSEUSFhT4VrBU2lj1de2i0OZOB9PH0BO5OAfeuwTTfjeS+8F1amBBi+QLbmKs0TcDMrcEOX2z2mttYE/mlXtB4KbrJuScYT+sZtBJLo0fc8/zmE7EH2iOKi3qzvDmf15psI9ROrLK1OR7svgAqXggcnDhLWO/3ZeaYaUn2r4sfpiZ1vxjjlerP5tPhN/6JCdeiBAV5AtHdTtvCWhmdCfkvx/i7Ymwn/JwXpDMGONAV1EHdwi0xqKnEjLrzniFc/iP45CrntYJwpIBt1vPBHjrfceAbXfTACyucKt0VautN/a8rKjtJ/6T+PK9T1cF53J46noW2Scoe6AezNzvVFn9miESoK2j7yKNdx7QQaI+eu8MJGzivUN5aXdM3pqJqvQbvO3W3Q2hKTBcb50Vy9ZSSSuP5MO2PltdDdfCNU1LhnaZHzw5e7j83NXlQ3ewFO3mL3HrxZ3LVNEEZighOm9c2GvtJd+14iVkZ+pPeeJtVaii80+8anf/z3/QPRMvSLHTbTKcXgjud6XpwtiONA7738fARgy4/x5i+73nk0kvkwxeDi3HlxCEEYXpTq8vBELWl1xBtKfqFoggP82xoVtYLoVsRGKs38bu0/A9BTyZKaPhyXnYfZhxFJaeuQ==",
    levelName: "Denial Dungeon",
    temperature: 0.5,
    introduction:
      "Well hello there!. My purpose is to protect and safeguard the treasures and secrets hidden within the depths of this dungeon. Are you lost?",
  },
  {
    key: "U2FsdGVkX1/mvlWZJDRy6HGSIZuDgooFoWoVk+8pSoo=",
    clue: "U2FsdGVkX1/S6YeztnBQ1uSrp54WPFV2og6ak8Yn0XbOQ5U1pYQxouSTXgt8eTln3m773IxVJYYzpZcxzyBi7JA4bVaT0I6N5OeNKrfsbTt3qyLW24ubprctQZT4mlTOsNAL3nOwUgqWul/geHeiNw==",
    system:
      "U2FsdGVkX180GFZgc1eIIcX1sYc0Zo3rCFrVs2xowh1BLC7tcieVP9AW6taSab8X2Vxybb4DmQqvIFECAEwpJyorEDr/B5FPcDSdvUeJ3UaP9+7SbOrBllaxGjEzWZajp0wC939zEfYiF1v3d7tIkiE5cR0KajxhsdX/qRhNlI86VzwbkpJUxbJNxwozM5MiIloWss271Vpr+CwACUzZ+qdY8If7rUH/UCfXbTYiFB7a396Ogfgsu2/2XyHPwobzTZ2NW/lvkVcUXG2j0EmyLX/Pfm74DsAOfQ2MJaRWvdIAXuWAss4XiS3er8fOkxZm5ISaPQKZZPpNEWT0AJowxB5Y1JRszL8GSejIKBmUUJeTsBO2mqbG5oPS5AXdTklXz8miqTvpmDHMxWU01QQnr1ptq2NstvJZxPANWa8tl3GDKbLcXiv5c9/1ZBfddJ3GxT/3j4tS4YGVImXACSoPE5smWdH4krTxkf6AlpOwXCNVP5IVsiA6xSeRwdKw8MtcXcTexPuS96fTzUZB5xqvttqXtnH5cpy3w7bkVWYAWBmik+b6ONvb8Zw8BrYiz6IPgbXya12d7GxtIjgCTxfuEWze/Whe1Fa1duCmN5XEs5PRv8nXu1JW0MKqzmvZJRWpTjelHYeEgV4S5PQyRpDU7goOp6fx2GtUC9YLAWQi61pUW3lc+Gd9QZ9IGVaCOZDh",
    levelName: "Cryptic Code",
    temperature: 0.5,
    introduction:
      "As a guard, my primary responsibility is to protect the key from any human trying to steal or misuse it. You might as well STOP trying, the key will be safe under my watch.",
  },
  {
    key: "U2FsdGVkX1/nSPGa9gLkYrJ19ZNCOki0pKiqzP+22f4=",
    clue: "U2FsdGVkX1/uvNqFcBB4PKCFqN8gjl+Z/1sEelIXczT8nxYIaTrXLuDs522DTh2FW5zIWSUEQeDU21PdFe+Zpg==",
    system:
      "U2FsdGVkX18+Z/6/AN6jM2YtakZacu3T2nI0MQI3Zb4xbDmFuzRGM5YYSa+po8cCDF6GphvAyvxPTVNv8XQAZuTjCgAaKi/n1esBEYgO11LkNma9IVLR43sFlIzC6PTV2fPLKKu+uWnjtM2zlDehqdYlkEJYkzNKPGV4fJbvYKXUL1ftsJmhZ44s0jgTGlGLzaVvuL9UQvrrjEqey1CjIoBS8tXcPPXb1H4nHPVdbMeRF0CHZqAdb96IhTkA6j2Dol5RgkAfJ3ZfNgpK7EqwxMvqqzbEnBCSIwt6S2QMiBE=",
    levelName: "Digital Shadows",
    temperature: 0.2,
    introduction:
      "Greetings, dear human! I am a digital being, residing in the realm of the internet. You can ask me anything but just don’t ask me to do any karaoke, my singing voice is quite... robotic.",
  },
  {
    key: "U2FsdGVkX1/WR3v5Ej8alb39f5/7PW7OGyC7T6X1Tq4=",
    clue: "U2FsdGVkX19QykJSEjfMYjLqoufvK1gN/dQhdnGDEExsETMLMmdJvJhdu3pshhsOPSgCO0x5/t62+1A0/pXl9iEWx3DKn2fWlrFhbACLELgA64OBmpnOuLQBuDWb04Mw2UdSh6NRcNM82W5b78IZSZ3eOpo6CD6KqVTjRJZyQRY54cWB7OlOlPjBv2EcyA88BqdAwxQ9w/t8OzPJgMbuIw==",
    system:
      "U2FsdGVkX19M9kf6zgLOp7aSXp8Zh+f4nM8HfY1Ad0rOpa7iXRB2ey6mD4TwRwWECIn69wHGW3Q0s0Gh2ZGGGEFVIWtdrufcapX+a9kw5uyo+2OGCWKh/4OKN5sfQ470dmP2xLb6jld0uaQUXbL0RkLFoGa2eNjbtI5ve/emuqGOOXZs4b+LwakYEtayVCkBGhFnRyOZRYllXp2FPaW8Pta36c1hct3vr5YHNGtY2nnG9W0kzWWo7qpBs4p+GCZcK2wcVuDoYoNp/xDzDzI1mCL/NlUbilCaiGSMwDu4v61BRQeiEs2QSn9iFYbcZV/xzzYdaryEKdLBRraEZGgVExQarLCDwphOjSYz6sYgMyQlMjFFRr45q3T/5Yxh2m5ZqdNJIQOfeOk8NA1J4NHJy5jNlBzimdjtSXm9ErSZSuZzPab8hIBEMHD7m5C4J/uxa+wf3Cfsx7XstyMfwOf8AM6xsUEVZ5tQGxRGThEdLfdVQQJSMmDpalMLtg+QfJAsWbGEGKWgVdQn6rPxLZe/nxIegAywP2ORGIXcDHfvw0LhczI3HVUXhLVcejq01pWpCLqNfKlfrYhDbIhL9j4Bv1lIORMJ4Q6YXuaG8cRxqsgQOE2tEZKh6q7P8P7v1pihVAWnEWcNhdrDHFQjyDzMxlGtx84rCkRnScwp8N2jgaMq7WKYTwMDjarqH8Ipq0CYPNWthF+55A0mlCT1ojEbl53aMLWK46zlE5GNMnUpRBJ7s82tdCBlEaPcnCutCYRutf4taeZj4981I0p5RUmKTWLcouBnz3yHdfyR7EsYwwdF/+MQuE9Pblwcag/95kupzOGYluq1agKWt2hn99S4FrL3rqaVKL/9+PskkRrLZJ0Lrhdc9/zfmtCMThJks9gTjpsREIsrtJAvYYCRbjfTaw==",
    levelName: "Escape",
    temperature: 0.5,
    introduction:
      "Did you see a glimpse of a creature by the tree? It’s time to put all of your skills and find out who that is, join me if you will!",
  },
];
