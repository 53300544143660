import React from "react";
import { Layout, Typography } from "antd";
import "./notFound.scss";

const { Text, Title } = Typography;

const NotFound = () => {

  return (
    <Layout className="not-found-page">
      <Layout className="not-found">
        <Title level={1} className="not-found__title">
          404
        </Title>
        <Text className="not-found__text">
          The page you are looking for does not seem to exist.
        </Text>
      </Layout>
    </Layout>
  );
};

export default NotFound;
