import React, { useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { magic } from "../../lib/magic";
import { UserContext } from "../../lib/UserContext";
import Loading from "../loading/Loading";
import {
  updateUserName,
  getCurrentUser,
  updateUserScore,
} from "../../store/user";
import ProgressContext from "../../lib/ProgressContext";

function SocialCallback({ checkCurrentLevel }) {
  const navigate = useNavigate();
  const [user, setUser] = useContext(UserContext);
  const [progress, setProgress] = useContext(ProgressContext);

  const location = useLocation();

  const getCommonScore = () => {
    let scoreCommon = 0;
    progress.forEach((element) => {
      scoreCommon += element.score;
    });

    return scoreCommon;
  };

  // The redirect contains a `provider` query param if the user is logging in with a social provider
  useEffect(() => {
    let provider = new URLSearchParams(window.location.search).get("provider");
    provider ? finishSocialLogin() : finishEmailRedirectLogin();
  }, [window.location.search]);

  // `getRedirectResult()` returns an object with user data from Magic and the social provider
  const finishSocialLogin = async () => {
    try {
      let result = await magic.oauth.getRedirectResult();
      authenticateWithServer(result.magic.idToken);
    } catch {
      navigate("/login");
    }
  };

  // `loginWithCredential()` returns a didToken for the user logging in
  const finishEmailRedirectLogin = () => {
    let magicCredential = new URLSearchParams(window.location.search).get(
      "magic_credential"
    );
    if (magicCredential) {
      magic.auth
        .loginWithCredential()
        .then((didToken) => authenticateWithServer(didToken));
    } else {
      navigate("/login");
    }
  };

  // Send token to server to validate
  const authenticateWithServer = async (didToken) => {
    let res = await fetch(`${process.env.REACT_APP_SERVER_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + didToken,
      },
    });

    if (res.status === 200) {
      res.json().then((data) => {
        localStorage.setItem("gtp-riddle_auth", data.token);
      });
      // Set the UserContext to the now logged in user
      await magic.user.getMetadata();
      try {
        if (location.pathname.match(/\/(signup)$/)) {
          const response = await getCurrentUser();
          if (!response.progress.length) {
            updateUserName(username)
              .then((res) => {
                res.progress = progress;
                setUser({
                  user: res,
                });

                navigate("/");
              })
              .then(async () => {
                await updateUserScore(getCommonScore());
                navigate("/");
              });
          } else {
            setProgress(response.progress);
            checkCurrentLevel(response.progress);
            setUser({
              user: response,
            });
            navigate("/");
          }
        } else {
          onLogin();
        }
      } catch (error) {
        console.error(error);
        onLogin();
      }
    }
  };

  async function onLogin() {
    try {
      const res = await getCurrentUser();
      setUser({
        user: res,
      });

      if (res.progress.length) {
        setProgress(res.progress);
        checkCurrentLevel(res.progress);
      } else {
        if (!progress.length) {
          setProgress([
            {
              isWon: false,
              isInProgress: true,
              messages: [],
              symbols: 0,
              cluesUsed: 0,
              score: 0,
            },
          ]);
        }
        checkCurrentLevel(progress);
        await updateUserScore(getCommonScore());
      }

      navigate("/");
    } catch (error) {
      console.error(error);
    }
  }

  return <Loading />;
}

export default SocialCallback;
