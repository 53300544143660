export const winImages = [
  { level: 0, isThreeLevels: true, isPersonFixed: false },
  { level: 1, isThreeLevels: true, isPersonFixed: true },
  { level: 2, isThreeLevels: false, isPersonFixed: true },
  { level: 3, isThreeLevels: false, isPersonFixed: true },
  { level: 4, isThreeLevels: true, isPersonFixed: true },
  { level: 5, isThreeLevels: true, isPersonFixed: true },
  { level: 6, isThreeLevels: true, isPersonFixed: true },
  { level: 7, isThreeLevels: true, isPersonFixed: true },
  { level: 8, isThreeLevels: true, isPersonFixed: true },
  { level: 9, isThreeLevels: true, isPersonFixed: true },
  { level: 10, isThreeLevels: true, isPersonFixed: true },
  { level: 11, isThreeLevels: false, isPersonFixed: true },
  { level: 12, isThreeLevels: true, isPersonFixed: true },
  { level: 13, isThreeLevels: true, isPersonFixed: true },
  { level: 14, isThreeLevels: false, isPersonFixed: true },
  { level: 15, isThreeLevels: false, isPersonFixed: true },
  { level: 16, isThreeLevels: true, isPersonFixed: false },
  { level: 17, isThreeLevels: false, isPersonFixed: true },
  { level: 18, isThreeLevels: true, isPersonFixed: true },
  { level: 19, isThreeLevels: true, isPersonFixed: true },
  { level: 20, isThreeLevels: false, isPersonFixed: true },
  { level: 21, isThreeLevels: true, isPersonFixed: true },
  { level: 22, isThreeLevels: true, isPersonFixed: true },
  { level: 23, isThreeLevels: true, isPersonFixed: false },
  { level: 24, isThreeLevels: true, isPersonFixed: true },
  { level: 25, isThreeLevels: true, isPersonFixed: true },
  { level: 26, isThreeLevels: false, isPersonFixed: true },
  { level: 27, isThreeLevels: true, isFourLevels: true, isPersonFixed: true },
  { level: 28, isThreeLevels: true, isFourLevels: true, isPersonFixed: true },
  { level: 29, isThreeLevels: false, isPersonFixed: true },
  { level: 30, isThreeLevels: false, isFourLevels: true, isPersonFixed: true },
  { level: 31, isThreeLevels: true, isFourLevels: true, isPersonFixed: true },
  { level: 32, isThreeLevels: false, isPersonFixed: true },
  { level: 33, isThreeLevels: true, isFourLevels: true, isPersonFixed: true },
  { level: 34, isThreeLevels: true, isPersonFixed: false },
  { level: 35, isThreeLevels: false, isPersonFixed: true },
  { level: 36, isThreeLevels: true, isFourLevels: true, isPersonFixed: true },
  { level: 37, isThreeLevels: true, isPersonFixed: true },
  { level: 38, isThreeLevels: true, isPersonFixed: true },
  { level: 39, isThreeLevels: true, isPersonFixed: true },
  { level: 40, isThreeLevels: true, isPersonFixed: true },
  { level: 41, isThreeLevels: false, isPersonFixed: true },
  { level: 42, isThreeLevels: true, isFourLevels: true, isPersonFixed: true },
  { level: 43, isThreeLevels: true, isPersonFixed: true },
  { level: 44, isThreeLevels: false, isPersonFixed: true },
  { level: 45, isThreeLevels: true, isFourLevels: true, isPersonFixed: true },
  { level: 46, isThreeLevels: true, isPersonFixed: true },
  { level: 47, isThreeLevels: false, isPersonFixed: true },
  { level: 48, isThreeLevels: true, isPersonFixed: true },
  { level: 49, isThreeLevels: true, isPersonFixed: true },
  { level: 50, isThreeLevels: false, isPersonFixed: true },
  { level: 51, isThreeLevels: true, isFourLevels: true, isPersonFixed: true },
  { level: 52, isThreeLevels: true, isFourLevels: true, isPersonFixed: true },
  { level: 53, isThreeLevels: false, isPersonFixed: true },
  { level: 54, isThreeLevels: false, isFourLevels: true, isPersonFixed: false },
  { level: 55, isThreeLevels: true, isFourLevels: true, isPersonFixed: true },
  { level: 56, isThreeLevels: false, isPersonFixed: true },
  { level: 57, isThreeLevels: true, isPersonFixed: true },
  { level: 58, isThreeLevels: true, isPersonFixed: true },
  { level: 59, isThreeLevels: true, isPersonFixed: true },
  { level: 60, isThreeLevels: true, isPersonFixed: true },
  { level: 61, isThreeLevels: true, isPersonFixed: true },
];
