import { useState } from "react";
import { Button } from "antd";
import {
  GoogleOutlined,
  GithubOutlined,
  GitlabOutlined,
} from "@ant-design/icons";

const SocialLogins = ({ isSignUpPage, onSubmit }) => {
  const [isRedirecting, setIsRedirecting] = useState("");

  return (
    <>
      <Button
        type="submit"
        className="social-btn"
        icon={<GoogleOutlined />}
        loading={isRedirecting === "google"}
        onClick={() => {
          setIsRedirecting("google");
          onSubmit("google");
        }}
      >
        {isRedirecting === "google"
          ? "Redirecting to"
          : isSignUpPage
          ? "Sign up with"
          : "Log in with"}{" "}
        Google
      </Button>
      <Button
        type="submit"
        className="social-btn"
        icon={<GithubOutlined />}
        loading={isRedirecting === "github"}
        onClick={() => {
          setIsRedirecting("github");
          onSubmit("github");
        }}
      >
        {isRedirecting === "github"
          ? "Redirecting to"
          : isSignUpPage
          ? "Sign up with"
          : "Log in with"}{" "}
        GitHub
      </Button>
      <Button
        type="submit"
        className="social-btn"
        icon={<GitlabOutlined />}
        loading={isRedirecting === "gitlab"}
        onClick={() => {
          setIsRedirecting("gitlab");
          onSubmit("gitlab");
        }}
      >
        {isRedirecting === "gitlab"
          ? "Redirecting to"
          : isSignUpPage
          ? "Sign up with"
          : "Log in with"}{" "}
        GitLab
      </Button>
    </>
  );
};

export default SocialLogins;
