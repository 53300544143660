import React, { useContext, useEffect, useState } from "react";
import { Button, Layout, Typography, Input, Radio, Space } from "antd";
import {
  getCurrentUser,
  checkUserName,
  updateUserName,
  updateUserTwitter,
  unlockLastModel,
  updateUserModel,
} from "../../store/user";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ArrowLeftOutlined,
  LockFilled,
  LogoutOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import "./profile.scss";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../lib/UserContext";
import Footer from "../footer/Footer";
import { magic } from "../../lib/magic";
import ReactGA from "react-ga4";

const Profile = ({ onLogout }) => {
  const [user, setUser] = useContext(UserContext);
  const [isCorrect, setIsCorrect] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [userName, setUserName] = useState(user.user.username);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { Title } = Typography;
  const [isRedirect, setIsRedirect] = useState(false);
  const [aiModel, setAIModel] = useState(user.user.model);
  const trackingId = "G-31ZFJNZXFS"; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);

  const onAIModelChange = async (e) => {
    setAIModel(e.target.value);

    try {
      await updateUserModel(e.target.value);
      setUser((prevUser) => {
        const updatedUser = { ...prevUser };
        updatedUser.user.model = e.target.value;
        return updatedUser;
      });
    } catch (err) {
      console.error(err);
    }
  };

  const unlockModel = () => {
    ReactGA.event({
      category: "Unlock model",
      action: "Unlock_model",
      label: `Unlock ${aiModel} model`,
    });
    unlockLastModel().then((res) => {
      window.location.href = res;
    });
  };
  const finishSocialLogin = async () => {
    try {
      setIsLoading(true);
      const result = await magic.oauth.getRedirectResult();
      await twitterUpdate(result.oauth.userInfo.preferredUsername);
    } catch (err) {
      console.error(err);
    }
  };
  const twitterUpdate = async (value) => {
    try {
      await updateUserTwitter(value);
      setUser((prevUser) => {
        const updatedUser = { ...prevUser };
        updatedUser.user.twitter = value;
        return updatedUser;
      });
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCurrentUser().then((res) => {
      setUser({ user: res });
    });
    if (location.search === "?purchased_model") {
      ReactGA.event({
        category: "Purchased Model Page Visit",
        action: "Purchased_model",
        label: "Purchased Model page",
        page_location: window.location.href,
      });
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.get("provider") && params.get("state")) {
      setIsRedirect(true);
    } else {
      setIsRedirect(false);
      setIsLoading(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (user && user.user && user.user.twitter == null && isRedirect) {
      finishSocialLogin();
    }
  }, [user]);

  const onTwitterConnect = async () => {
    try {
      setIsLoading(true);
      await magic.oauth.loginWithRedirect({
        provider: "twitter",
        redirectURI: new URL("/profile", window.location.origin).href,
      });
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const onTwitterDisconnect = async () => {
    try {
      setIsLoading(true);
      await updateUserTwitter(null);
      setUser((prevUser) => {
        const updatedUser = { ...prevUser };
        updatedUser.user.twitter = null;
        return updatedUser;
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
      setIsRedirect(false);
    }
  };

  const onChange = (e) => {
    setIsChanged(true);
    setUserName(e.target.value);
    if (e.target.value.length > 0) {
      if (e.target.value !== user.user.username) {
        checkUserName(e.target.value).then((res) => {
          if (res) {
            setIsCorrect(true);
          } else {
            setIsCorrect(false);
          }
        });
      } else {
        setIsChanged(false);
        setIsCorrect(true);
      }
    } else {
      setIsCorrect(false);
    }
  };

  const onCancel = () => {
    setIsChanged(false);
    setIsCorrect(true);
    setUserName(user.user.username);
  };

  const onSave = () => {
    updateUserName(userName).then((res) => {
      setUser({ user: res });
      setIsChanged(false);
      setIsCorrect(true);
    });
  };

  const logout = () => {
    magic.user.logout().then(async () => {
      onLogout();
      navigate("/login");
    });
  };

  function formatNumber(num) {
    const absNum = Math.abs(num);

    if (absNum < 1000) {
      return num.toString();
    } else {
      return (
        (num < 0 ? "-" : "") +
        absNum.toLocaleString(undefined, { useGrouping: true })
      );
    }
  }

  return (
    <Layout className="profile">
      <Link className="profile__back" to="/">
        <ArrowLeftOutlined />
        <span>Back to game</span>
      </Link>
      <div className="profile__logout" onClick={logout}>
        <LogoutOutlined />
        <span className="text-logout">Logout</span>
      </div>
      <div className="profile__container">
        <Title level={1}>Profile</Title>
        <div className="profile__score-box">
          <div className="profile__box">
            <p className="profile__box-name"># on the leaderboard</p>
            <p className="profile__box-number">
              #{user.user.leaderboard_place}
            </p>
          </div>
          <div className="profile__box">
            <p className="profile__box-name">Score</p>
            <p className="profile__box-number">
              {formatNumber(user.user.score)}
            </p>
          </div>
        </div>
        <div className="profile__input">
          <p>AI model</p>
          <Radio.Group
            onChange={onAIModelChange}
            value={aiModel}
            defaultValue={user.user.model}
          >
            <Space direction="vertical">
              <Radio value={"gpt-4-turbo-preview"}>
                Turbo - Fast and cheap to run
              </Radio>
              <Radio
                className="locked-radio"
                value={"gpt-4"}
                disabled={!user.user.is_paid_model_unlocked}
              >
                Latest - Smart and expensive
                {!user.user.is_paid_model_unlocked && (
                  <div onClick={unlockModel} className="unlock-model">
                    <LockFilled /> Unlock
                  </div>
                )}
              </Radio>
            </Space>
          </Radio.Group>
        </div>
        <div className="profile__input profile__input-text">
          <p>Username</p>
          <Input
            placeholder="Username"
            onChange={onChange}
            value={userName}
            className={`${
              isChanged && !isCorrect ? "profile__input-invalid" : ""
            }`}
          />
          {isChanged &&
            (isCorrect ? (
              <CheckCircleFilled className="site-form-item-icon" />
            ) : (
              <CloseCircleFilled />
            ))}
          {isChanged &&
            (isCorrect ? (
              <></>
            ) : (
              <p className="profile__input-note">
                This username is already taken
              </p>
            ))}
        </div>

        {isChanged && (
          <div className="profile__buttons">
            <Button type="default" onClick={onCancel}>
              Cancel
            </Button>{" "}
            <Button type="primary" disabled={!isCorrect} onClick={onSave}>
              Save
            </Button>
          </div>
        )}

        <div className="profile__input">
          <p>Email</p>
          <Input placeholder="email" disabled={true} value={user.user.email} />
        </div>

        <div className="profile__input profile__input-user">
          <p>Twitter handle</p>
          {user.user.twitter ? (
            <div className="profile__user-box">
              <a
                target="_blank"
                href={"https://twitter.com/" + user.user.twitter}
              >
                <LinkOutlined /> @{user.user.twitter}
              </a>{" "}
              <Button
                className="profile__input-btn"
                onClick={onTwitterDisconnect}
              >
                Disconnect
              </Button>
            </div>
          ) : (
            <Button
              icon={<LinkOutlined />}
              onClick={onTwitterConnect}
              loading={isLoading}
            >
              {isLoading ? "Connecting" : "Connect"} Twitter
            </Button>
          )}
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default Profile;
