import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

api.interceptors.request.use(function (config) {
  const token = "Bearer " + localStorage.getItem('gtp-riddle_auth');
  if(token)
    config.headers.Authorization = token
  return config;
});

export default api;
