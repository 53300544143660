import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Modal, Button } from "antd";
import "./cluesModal.scss";
import { getPayment } from "../../store/user";
import ReactGA from "react-ga4";
import cluesFive from "../../images/5.png";
import cluesTen from "../../images/10.png";
import cluesTwenty from "../../images/20.png";

const CluesModal = forwardRef((props, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const trackingId = "G-31ZFJNZXFS"; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);
  const buy = (credits) => {
    ReactGA.event({
      category: "Buy clues",
      action: "Buy_clues",
      label: `Buy ${credits}`,
    });
    getPayment(credits).then((res) => {
      window.location.href = res;
    });
  };
  useImperativeHandle(ref, () => ({
    openInfoModal() {
      setModalOpen(true);
    },
  }));

  const handleCancel = () => {
    setModalOpen(false);
  };

  return (
    <Modal footer={null} centered open={modalOpen} onCancel={handleCancel}>
      <h3>Get clues</h3>
      <p>Buy a pack of clues and use them during the game</p>
      <div className="clues__box">
        <div className="clues__clue">
          <img src={cluesFive} />
          <h4>
            <span>5</span> clues
          </h4>
          <p>$2</p>
          <Button type="primary" onClick={() => buy(5)}>
            Buy
          </Button>
        </div>
        <div className="clues__clue clues__clue_big">
          <img src={cluesTen} />
          <h4>
            <span>10</span> clues
          </h4>
          <p>$3</p>
          <Button type="primary" onClick={() => buy(10)}>
            Buy
          </Button>
        </div>
        <div className="clues__clue">
          <img src={cluesTwenty} />
          <h4>
            <span>20</span> clues
          </h4>
          <p>$5</p>
          <Button type="primary" onClick={() => buy(20)}>
            Buy
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default CluesModal;
