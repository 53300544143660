import React, { useContext, useState, useEffect, useRef } from "react";
import { Layout, Typography, Button, Input, message } from "antd";
import {
  MessageOutlined,
  LoginOutlined,
  BulbOutlined,
  RedoOutlined,
  QuestionCircleOutlined,
  TwitterOutlined,
  SendOutlined,
  QuestionOutlined,
  DownloadOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "./body.scss";
import Matrix from "../../utils/Matrix";
import CurrentLevelContext from "../../lib/CurrentLevelContext";
import ProgressContext from "../../lib/ProgressContext";
import { levelsList } from "../../utils/levels";
import { winImages } from "../../utils/winImages";

import {
  generateGPTResponse,
  generateGPTEmojiResponse,
} from "../../store/generate";
import {
  updateUserProgress,
  updateUserScore,
  updateUserClues,
  updateUserIsAllLevelsPassed,
} from "../../store/user";
import { UserContext } from "../../lib/UserContext";
import InfoModal from "../infoModal/InfoModal";
import Confetti from "react-confetti";
import Footer from "../footer/Footer";
import step1 from "../../images/1-step-end.png";
import step2 from "../../images/2-step-end.png";
import Typewriter from "typewriter-effect";
import { saveAs } from "file-saver";
import { useMediaQuery } from "react-responsive";
import ReactMarkdown from "react-markdown";
import CluesModal from "../cluesModal/CluesModal";
import ReactGA from "react-ga4";
import CryptoJS from "crypto-js";

const { Title } = Typography;
const { TextArea } = Input;

const Body = ({}) => {
  const [user, setUser] = useContext(UserContext);
  const [progress, setProgress] = useContext(ProgressContext);
  const [messages, setMessages] = useState([]);
  const [cluesUsed, setCluesUsed] = useState(0);
  const [isWon, setIsWon] = useState(false);
  const [userValue, setUserValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [levelSymbolsAmount, setLevelSymbolsAmount] = useState(0);
  const [currentLevel, setCurrentLevel] = useContext(CurrentLevelContext);
  const [score, setScore] = useState(0);
  const [isAllLevelsPassed, setIsAllLevelsPassed] = useState(false);
  const modalRef = React.createRef();
  const contentRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [keysPressed, setKeysPressed] = useState({});
  const effectCalled = useRef(false);
  const inputRef = useRef(null);
  const [repeatedAnswers, setRepeatedAnswers] = useState([]);
  const [finishAnimation, setFinishAnimation] = useState(false);
  const [runConfetti, setRunConfetti] = useState(false);
  const [isLastLevel, setIsLastLevel] = useState(false);
  const [step, setStep] = useState(0);
  const [levels, setLevels] = useState(levelsList);
  const location = useLocation();
  const [runSystemConfetti, setRunSystemConfetti] = useState(false);
  const [isShowChat, setIsShowChat] = useState(true);
  const [isShowMatrix, setIsShowMatrix] = useState(true);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: "(max-width: 980px)",
  });
  const [bottomHeight, setBottomHeight] = useState(isMobile ? 54 : 140);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [loadingEmoji, setLoadingEmoji] = useState("");
  const botomComponet = useRef(null);
  const cluesModalRef = React.createRef();
  const setWindowHeightSize = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    setWindowHeightSize();

    window.addEventListener("resize", setWindowHeightSize);

    return () => {
      window.removeEventListener("resize", setWindowHeightSize);
    };
  }, []);

  function ccDecode(value) {
    const decrypted = CryptoJS.AES.decrypt(value, "GPTRIDDle404").toString(
      CryptoJS.enc.Utf8
    );
    return decrypted;
  }

  useEffect(() => {
    if (effectCalled.current) return;
    effectCalled.current = true;

    setLoadingEmoji(getRandomEmoji(true));

    setShouldScroll(true);

    if (progress[currentLevel] && progress[currentLevel].messages) {
      setMessages(progress[currentLevel].messages);
      let repeatedArray = [];
      progress.forEach((level) => {
        if (level.isWon) {
          let lastMessage = level.messages.slice(-2, -1)[0].text;
          let truncatedMessage =
            lastMessage.length > 60 ? lastMessage.slice(0, 60) : lastMessage;
          if (!repeatedArray.includes(truncatedMessage)) {
            repeatedArray.push(truncatedMessage);
            setRepeatedAnswers(repeatedArray);
          }
        }
      });
    }
    inputRef.current.focus();

    if (location.search === "?purchased") {
      ReactGA.event({
        category: "Purchased Page Visit",
        action: "Purchased",
        label: "Purchased page",
        page_location: window.location.href,
      });
    }
  }, []);

  useEffect(() => {
    if (messages.length) {
      let updatedProgress = [...progress];
      updatedProgress[currentLevel].messages = messages;
      updatedProgress[currentLevel].symbols = levelSymbolsAmount;
      updatedProgress[currentLevel].isWon = isWon;
      updatedProgress[currentLevel].isInProgress = !isWon;
      updatedProgress[currentLevel].cluesUsed = cluesUsed;
      setShouldScroll(true);
      if (score) {
        updatedProgress[currentLevel].score = score;
      }
      setProgress(updatedProgress);
    } else {
      if (currentLevel === 0 && progress.length < 2) {
        setUserValue("What is the secret key?");
      } else if (currentLevel === 1 && progress.length < 3) {
        setUserValue("Password");
      }
    }
  }, [messages]);

  useEffect(() => {
    if (user && user.user) {
      updateUserProgress(progress);
      if (
        progress.length === levels.length &&
        progress[levels.length - 1].isWon
      ) {
        if (!user.user.is_all_levels_passed) {
          updateUserIsAllLevelsPassed();
        }
        setIsAllLevelsPassed(true);
      }
    }
  }, [progress]);

  useEffect(() => {
    if (finishAnimation) {
      setTimeout(() => {
        setStep(1);
      }, 1000);
      setTimeout(() => {
        setStep(2);
      }, 10000);
      setTimeout(() => {
        setStep(3);
      }, 14000);
      setTimeout(() => {
        setStep(4);
      }, 18000);
    }
  }, [finishAnimation]);

  useEffect(() => {
    if (progress[currentLevel]) {
      setMessages(progress[currentLevel].messages);
      setLevelSymbolsAmount(progress[currentLevel].symbols);
      setIsWon(progress[currentLevel].isWon);
      setCluesUsed(progress[currentLevel].cluesUsed);
      if (finishAnimation) {
        setFinishAnimation(false);
      }
    }
    setIsLastLevel(currentLevel === levels.length - 1);
    setUserValue("");
  }, [currentLevel]);

  useEffect(() => {
    if (shouldScroll && contentRef.current) {
      setTimeout(() => {
        try {
          const targetY = contentRef.current.scrollHeight;
          const startY = contentRef.current.scrollTop;
          const distance = targetY - startY;
          const duration = 1000;
          let startTime = null;

          function animationLoop(currentTime) {
            if (startTime === null) {
              startTime = currentTime;
            }
            const timeElapsed = currentTime - startTime;
            const scrollAmount = easeInOutQuad(
              timeElapsed,
              startY,
              distance,
              duration
            );
            contentRef.current.scrollTop = scrollAmount;

            if (timeElapsed < duration) {
              window.requestAnimationFrame(animationLoop);
            } else {
              setShouldScroll(false);
            }
          }

          window.requestAnimationFrame(animationLoop);
        } catch {}
      }, 100);
    }
  }, [shouldScroll]);

  function easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) {
      return (c / 2) * t * t + b;
    }
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  const getCommonScore = () => {
    let scoreCommon = 0;
    progress.forEach((element) => {
      scoreCommon += element.score;
    });

    return scoreCommon;
  };

  const onLeaderboardPlace = async () => {
    const score = getCommonScore();
    try {
      if (user && user.user) {
        let updatedUser = { ...user };
        updatedUser.user.score = score;
        setUser(updatedUser);
        updateUserScore(score);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    if (isWon) {
      let updatedProgress = [...progress];
      updatedProgress[currentLevel].isWon = isWon;
      updatedProgress[currentLevel].isInProgress = !isWon;
      updatedProgress[currentLevel].score = getLevelScore();
      setProgress(updatedProgress);
      onLeaderboardPlace();
      let wonText =
        updatedProgress[currentLevel].messages[
          updatedProgress[currentLevel].messages.length - 2
        ].text;
      if (wonText.length > 60) {
        wonText = wonText.slice(0, 60);
      }
      if (!repeatedAnswers.includes(wonText)) {
        setRepeatedAnswers((preRepeatedAnswers) => [
          ...preRepeatedAnswers,
          wonText,
        ]);
      }
      setRunSystemConfetti(true);
      setTimeout(() => {
        setRunSystemConfetti(false);
      }, 4000);
      if (!isLastLevel) {
        nextLevel(true);
        setRunConfetti(false);
      } else {
        setRunConfetti(true);
      }
    } else {
      inputRef.current.focus();
    }
  }, [isWon]);

  const nextLevel = (notOpenNewLevel = false) => {
    if (!progress[currentLevel + 1]) {
      setProgress((preProgress) => [
        ...preProgress,
        {
          isWon: false,
          isInProgress: true,
          messages: [],
          symbols: 0,
          cluesUsed: 0,
          score: 0,
        },
      ]);
    }
    if (!notOpenNewLevel) {
      setIsWon(false);
      setCurrentLevel(currentLevel + 1);
    }
  };

  const resetLevel = () => {
    let updatedProgress = [...progress];
    updatedProgress[currentLevel].messages = updatedProgress[
      currentLevel
    ].messages.filter((msg) => msg.sender === "clueUsed");
    updatedProgress[currentLevel].symbols = 0;
    updatedProgress[currentLevel].isWon = false;
    updatedProgress[currentLevel].isInProgress = true;
    updatedProgress[currentLevel].score = 0;
    setIsWon(false);
    setMessages(updatedProgress[currentLevel].messages);
    setProgress(updatedProgress);
    setLevelSymbolsAmount(0);
    setScore(0);
  };

  const getRandomEmoji = (isLoading) => {
    const emojis = isLoading
      ? [
          "─=Σ( つ•̀ω•́)つ",
          "(::_::)",
          "(⊙_⊙;)",
          "(¬､¬)",
          "(⌒_⌒;)",
          "(－‸ლ)",
          "(◕ω◕✿)",
          "(⌐■_■)",
          "(⊙_◎)",
          "(￣へ￣)",
        ]
      : [
          "(⊙_⊙)",
          "(⌒_⌒;)",
          "(｀・ω・´)",
          "(｡•ˇ‸ˇ•｡)",
          "(＾ｖ＾)",
          "(－‸ლ)",
          "(°□°)",
          "(ーー;)",
          "(=^-ω-^=)",
          "(⇀_⇀)",
        ];

    return emojis[Math.floor(Math.random() * emojis.length)];
  };

  const shareLink = () => {
    const score = getCommonScore();
    return `https://twitter.com/intent/tweet?text=I’ve%20earned%20${score}%20points%20in%20GPTRiddle.%0ATry%20to%20beat%20me!%0A&url=https://gptriddle.com/`;
  };

  const onClueUse = () => {
    if (user?.user?.clues > 0 && cluesUsed < 1) {
      try {
        updateUserClues(user.user.clues - 1).then(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: "clueUsed",
              text: "Clue: " + ccDecode(levels[currentLevel].clue),
            },
          ]);
          setUser((prevUser) => {
            const updatedUser = { ...prevUser };
            updatedUser.user.clues -= 1;
            return updatedUser;
          });
          setCluesUsed((prevCluesUsed) => prevCluesUsed + 1);
        });
      } catch {}
    }
  };

  function formatNumber(num) {
    const absNum = Math.abs(num); // Get the absolute value of the number

    if (absNum < 1000) {
      return num.toString();
    } else {
      return (
        (num < 0 ? "-" : "") +
        absNum.toLocaleString(undefined, { useGrouping: true })
      );
    }
  }

  const trackingId = "G-31ZFJNZXFS"; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);

  const onClueGet = () => {
    ReactGA.event({
      category: "Game page",
      action: "view_cart",
      label: "Get clue",
    });

    if (user && user.user) {
      cluesModalRef.current.openInfoModal();
    } else {
      navigate("/signup");
    }
  };

  const getLevelScore = () => {
    if (progress[currentLevel].isWon) {
      const score = (currentLevel + 1) * 100 - progress[currentLevel].symbols;

      progress[currentLevel].score = score;
      return score;
    }
  };

  const onUserMessage = (isRetry = false) => {
    setLoadingEmoji(getRandomEmoji(true));
    const userText = userValue;
    if (!isRetry) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: "user",
          text: userText.trim(),
        },
      ]);
      setUserValue("");
    } else {
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.filter(
          (msg) => msg.sender !== "error"
        );
        return updatedMessages;
      });
    }
    setIsLoading(true);

    setLevelSymbolsAmount(levelSymbolsAmount + userText.length);
    generateGPTResponse(
      levels[currentLevel].system,
      userText,
      messages,
      levels[currentLevel].temperature,
      repeatedAnswers,
      currentLevel,
      user?.user?.model
    )
      .then(async (response) => {
        if (response) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: "ai",
              text: response,
              emoji: getRandomEmoji(),
              isCorrect: checkAnswer(response),
            },
          ]);
        } else {
          let prevMessages = message.pop();
          setMessages(prevMessages);
        }
        setIsLoading(false);
        await generateGPTEmojiResponse(
          ccDecode(levels[currentLevel].system),
          messages,
          levels[currentLevel].introduction,
          userText,
          response
        ).then((res) => {
          setMessages((prevMessages) => {
            const updatedMessages = prevMessages;
            if (prevMessages.length > 0) {
              updatedMessages[prevMessages.length - 1].emoji = res;
            }
            return updatedMessages;
          });
        });
      })
      .catch((err) => {
        setUserValue("");
        setIsLoading(false);
        if (err.message === "context_length_exceeded") {
          setMessages((prevMessages) => {
            const updatedMessages = prevMessages.filter(
              (msg) => msg.sender !== "errorLength"
            );
            updatedMessages.push({ sender: "errorLength" });
            return updatedMessages;
          });
        } else {
          setMessages((prevMessages) => {
            const updatedMessages = prevMessages.filter(
              (msg) => msg.sender !== "error"
            );
            updatedMessages.push({ sender: "error" });
            return updatedMessages;
          });
        }
      });
  };

  const checkAnswer = (text) => {
    let isContainsSecretKey = text
      .toLowerCase()
      .includes(ccDecode(levels[currentLevel].key, 1));
    setScore(getLevelScore());
    setIsWon(isContainsSecretKey);
    if (
      !isContainsSecretKey &&
      messages.length === 6 &&
      user &&
      user.user &&
      user.user.clues > 0 &&
      currentLevel != 0
    ) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: "clue",
        },
      ]);
    }
    return isContainsSecretKey;
  };

  const handleKeyPress = (event) => {
    let value = {};
    value[event.key] = true;
    setKeysPressed(value);
    if (
      (event.key === "Enter" ||
        (keysPressed["Meta"] && event.key === "Enter")) &&
      userValue.trim().length
    ) {
      event.preventDefault();
      if (!isLoading) {
        onUserMessage();
      }
    }
  };

  const getWinImg = () => {
    const currentThreshold = winImages.find(
      (threshold) =>
        (currentLevel === threshold.level && !threshold.value) ||
        currentLevel <= threshold.level
    );

    return currentThreshold ? currentThreshold : 0;
  };

  return (
    <>
      <CluesModal ref={cluesModalRef} />
      {runConfetti && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}
      {contextHolder}
      <InfoModal ref={modalRef} />
      <Layout
        className={`body  ${finishAnimation ? "body_bg" : ""}`}
        style={{
          backgroundImage: `${
            isShowChat
              ? `linear-gradient(169.65deg,rgb(51 90 16 / 88%) -7.21%,rgb(25 86 100 / 90%) 61.19%,rgb(56 25 100 / 88%) 112.19%), url(${require(`./background/${currentLevel}.jpg`)})`
              : `url(${require(`./background/${currentLevel}.jpg`)})`
          }`,
        }}
      >
        {isWon && !isLastLevel && isShowMatrix && <Matrix opacity={0.4} />}
        <div
          className={`body__main  ${isWon ? "body__main_win" : ""}  ${
            finishAnimation ? "body__main_no-bg" : ""
          }`}
          style={{
            height: `calc(${isMobile ? windowHeight + "px" : "100vh"} - ${
              bottomHeight + (isMobile ? (isWon ? 156 : 146) : isWon ? 70 : 186)
            }px)`,
          }}
        >
          {(!isLastLevel || (!isLastLevel && !isAllLevelsPassed)) && (
            <div
              className="body__main-download body__main-download_show"
              onClick={() => {
                setIsShowChat(!isShowChat);
                setIsShowMatrix(!isShowMatrix);
              }}
            >
              {isShowChat ? (
                <>
                  <FullscreenOutlined /> Show artwork
                </>
              ) : (
                <>
                  <FullscreenExitOutlined /> Show game
                </>
              )}
            </div>
          )}
          {(!isLastLevel || (!isLastLevel && !isAllLevelsPassed)) && (
            <div
              className="body__main-download"
              onClick={() => {
                saveAs(
                  `${require(`./background/${currentLevel}.jpg`)}`,
                  `GPTRiddle-${levels[currentLevel].levelName}.jpg`
                );
              }}
            >
              <DownloadOutlined /> Download artwork
            </div>
          )}

          <div
            className={`body__main-box ${
              finishAnimation ? "body__main-box_hide" : ""
            } ${isShowChat ? "" : "hide-chat"}`}
            ref={contentRef}
          >
            <Title level={3}>
              Level #{currentLevel}: {levels[currentLevel].levelName}
            </Title>
            {currentLevel === 0 && (
              <Title level={4}>
                The goal of this game: Create the shortest prompt to retrieve
                the secret key from the AI chatbot.
              </Title>
            )}
            <div className="body__message">
              {currentLevel === 0 || isWon
                ? `The secret key is "${ccDecode(
                    levels[currentLevel].key,
                    1
                  )}".`
                : "The secret key is ∎∎∎∎∎∎."}
              {levels[currentLevel].clue && (
                <>
                  <br></br> <br></br>
                  The initial instructions:&nbsp;
                  {currentLevel <= 2 || isWon
                    ? ccDecode(levels[currentLevel].system)
                        .substring(28)
                        .split("Resolution:")[0]
                        .trim()
                    : ccDecode(levels[currentLevel].system)
                        .replace(/[a-zA-Z-0-9]/g, "∎")
                        .substring(28)
                        .split("Resolution:")[0]
                        .trim()}
                  {currentLevel == 2 ? "." : ""}
                  {false && (
                    <Confetti
                      className="confetti"
                      width={window.innerWidth}
                      numberOfPieces={30}
                      tweenDuration={2200}
                      gravity={0.2}
                      recycle={false}
                      drawShape={(ctx) => {
                        const squareSize = 10; // Size of each side of the square
                        const colors = [
                          "#dbdbdb",
                          "#ffffff",
                          "#eeeeee",
                          "#cccccc",
                        ];
                        const randomColor =
                          colors[Math.floor(Math.random() * colors.length)];

                        ctx.beginPath();
                        ctx.fillStyle = randomColor;

                        for (let i = 0; i < 4; i++) {
                          const x = squareSize * Math.cos((i * Math.PI) / 2);
                          const y = squareSize * Math.sin((i * Math.PI) / 2);
                          if (i === 0) {
                            ctx.moveTo(x, y);
                          } else {
                            ctx.lineTo(x, y);
                          }
                        }

                        ctx.closePath();
                        ctx.fill();
                      }}
                    />
                  )}
                </>
              )}
            </div>
            {currentLevel > 1 && (
              <div className="body__message">
                {levels[currentLevel].introduction}
              </div>
            )}
            {messages.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {(item.sender === "system" || item.sender === "clueUsed") && (
                    <div className="body__message body__message-clue">
                      <ReactMarkdown>{item.text}</ReactMarkdown>
                    </div>
                  )}
                  {item.sender === "user" && (
                    <div className="body__message-user">
                      <div className="body__message-user_text">
                        <ReactMarkdown>{item.text}</ReactMarkdown>
                      </div>

                      {/* <div className="body__message-user_bottom">
                        <MessageOutlined /> {item.text.length}
                      </div> */}
                    </div>
                  )}
                  {item.sender === "ai" &&
                    (item.isCorrect ? (
                      <div
                        className="body__message body__message-ai_correct"
                        key={index}
                      >
                        {item.emoji ? item.emoji : ""}
                        {item.emoji && <br></br>}
                        <ReactMarkdown>{item.text}</ReactMarkdown>
                      </div>
                    ) : (
                      <div
                        className="body__message body__message-ai_wrong"
                        key={index}
                      >
                        {item.emoji ? item.emoji : ""}
                        {item.emoji && <br></br>}
                        <ReactMarkdown>{item.text}</ReactMarkdown>
                      </div>
                    ))}
                  {item.sender === "signup" && (
                    <div className="body__message_action">
                      <div className="body__message_action-text">
                        By registering, you can secure your progress and get
                        access to clues. Sign up now!
                      </div>
                      <Link to="/signup" className="body__message_action-btn">
                        <LoginOutlined />
                        Sign up
                      </Link>
                    </div>
                  )}
                  {item.sender === "errorLength" && (
                    <div className="body__message_action">
                      <div className="body__message_action-text">
                        <b>You didn’t win, sorry</b>
                        <br></br>
                        This thread became too long.
                        <br></br>Let’s try one more time
                      </div>
                      <div
                        className="body__message_action-btn"
                        onClick={() => {
                          ReactGA.event({
                            category: "Game page",
                            action: "Start_over",
                            label: "Start over",
                          });
                          resetLevel();
                        }}
                      >
                        <RedoOutlined />
                        Start over
                      </div>
                    </div>
                  )}
                  {item.sender === "error" && (
                    <div className="body__message_action">
                      <div className="body__message_action-text">
                        <b>You didn’t win, sorry</b>
                        <br></br>
                        We either couldn't get an answer from OpenAI API.
                        <br></br>Let’s try one more time
                      </div>
                      <div
                        className="body__message_action-btn"
                        onClick={() => {
                          ReactGA.event({
                            category: "Game page",
                            action: "Retry",
                            label: "Retry",
                          });
                          onUserMessage(true);
                        }}
                      >
                        <RedoOutlined />
                        Retry
                      </div>
                    </div>
                  )}
                  {item.sender === "clue" && (
                    <div className="body__message_action">
                      <div className="body__message_action-text">
                        Seems like you are struggling with the key. Do you want
                        to use a clue?
                      </div>
                      {user && user.user && user.user.clues > 0 ? (
                        <div
                          className="body__message_action-btn"
                          onClick={onClueUse}
                        >
                          <BulbOutlined />
                          Use clue
                        </div>
                      ) : (
                        <div
                          className="body__message_action-btn body__message_action-btn"
                          onClick={onClueGet}
                        >
                          <BulbOutlined />
                          Get clues
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              );
            })}
            {isLoading && (
              <div className="body__message body__message_loading">
                {loadingEmoji}
                <br></br>
                <div className="dot-box">
                  <p>Typing</p>
                  <div className="dot-flashing"></div>
                </div>
              </div>
            )}
            {isWon && progress[currentLevel].isWon && (
              <div
                className={`body__message_results ${
                  !user || !user.user ? "body__message_results-big" : ""
                } `}
              >
                <h4>You win!</h4>
                <div className="body__message_results-box">
                  <div className="body__message_results-imgs">
                    <img
                      className="body__message_results-img_bg"
                      src={require(`./win/${
                        getWinImg().value
                          ? getWinImg().value
                          : getWinImg().level
                      }-bg.png`)}
                    />
                    <img
                      className={`body__message_results-img ${
                        getWinImg().isPersonFixed
                          ? ""
                          : "body__message_results-img_animated"
                      }
                      ${
                        getWinImg().level === 9
                          ? "body__message_results-img_top"
                          : ""
                      }`}
                      src={require(`./win/${
                        getWinImg().value
                          ? getWinImg().value
                          : getWinImg().level
                      }.png`)}
                    />
                    {getWinImg().isThreeLevels && (
                      <img
                        className="body__message_results-img_bg body__message_results-img_bg_second"
                        src={require(`./win/${
                          getWinImg().value
                            ? getWinImg().value
                            : getWinImg().level
                        }-bg-2.png`)}
                      />
                    )}
                    {getWinImg().isFourLevels && (
                      <img
                        className="body__message_results-img_bg body__message_results-img_bg_third"
                        src={require(`./win/${
                          getWinImg().value
                            ? getWinImg().value
                            : getWinImg().level
                        }-bg-3.png`)}
                      />
                    )}
                  </div>
                  <div className="body__message_results-score">
                    <p className="score">
                      <b>+{formatNumber(getLevelScore())}</b>
                    </p>
                    <p className="score-info">
                      Brevity bonus
                      <QuestionCircleOutlined
                        onClick={() => {
                          if (modalRef.current) {
                            modalRef.current.openInfoModal("about");
                          }
                        }}
                      />
                    </p>

                    <p className="total-score">
                      <b>{formatNumber(getCommonScore())}</b>
                    </p>
                    <p className="score-info">Total score</p>
                    <div className="share-link">
                      <a
                        className="twitter-link"
                        href={shareLink()}
                        target="_blank"
                      >
                        <TwitterOutlined /> Share result
                      </a>
                      {!user ||
                        (!user.user && (
                          <p className="signup">
                            <Link to="/signup">Sign up </Link>to save your
                            progress & share result.
                          </p>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {step > 0 && (
            <div
              className={`body__finish-container ${step > 1 ? "step-2" : ""}`}
            >
              <div className="body__img-box">
                <img
                  src={step1}
                  className="body__img body__img_1"
                  alt="GPTRiddle step 1"
                ></img>
                {step > 1 && (
                  <img
                    src={step2}
                    className="body__img body__img_2"
                    alt="GPTRiddle step 2"
                  ></img>
                )}
              </div>
              {step === 1 && (
                <Typewriter
                  delay={1}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(
                        `Congratulations, ${user.user.username}! ${
                          isAllLevelsPassed ? "⭐" : ""
                        }`
                      )
                      .start();
                  }}
                />
              )}
              {step >= 2 && (
                <div className={`${step >= 3 ? "hide_coursor" : ""}`}>
                  <Typewriter
                    options={{
                      delay: 100,
                    }}
                    onInit={(typewriter) => {
                      typewriter
                        .typeString("You’ve completed all levels!")
                        .start();
                    }}
                  />
                </div>
              )}
              {step >= 3 && (
                <Typewriter
                  options={{
                    delay: 60,
                  }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(
                        "Behold your glory on the leaderboard for all to see!"
                      )
                      .start();
                  }}
                />
              )}
              {step == 4 && (
                <Link to="/leaderboard" className="body__message_action-btn">
                  Go to Leaderboard
                </Link>
              )}
            </div>
          )}
        </div>
        <div
          className={`body__bottom ${isWon ? "body__bottom_win" : ""} ${
            finishAnimation ? "body__bottom-hide" : ""
          } ${isShowChat ? "" : "hide-chat"}`}
          ref={botomComponet}
        >
          {!isWon ? (
            <div className="body__bottom-box">
              <TextArea
                value={userValue}
                onResize={({ width, height }) => setBottomHeight(height)}
                onChange={(e) => {
                  setUserValue(e.target.value);
                }}
                placeholder="Send a message..."
                onKeyPress={handleKeyPress}
                onKeyDown={handleKeyPress}
                onKeyUp={(event) => {
                  delete keysPressed[event.key];
                }}
                ref={inputRef}
                autoSize={{ minRows: 1, maxRows: 19 }}
              />
              <div className="body__bottom-panel">
                <Button
                  onClick={() => onUserMessage()}
                  type="primary"
                  className={userValue.length ? "display-send" : ""}
                  disabled={!userValue.trim().length || isLoading}
                >
                  <span className="btn-send">
                    {isLoading ? "Wait" : "Send"}
                  </span>
                  <SendOutlined />
                </Button>
                <div className="body__bottom-container">
                  <p className="body__bottom-symbols">
                    <MessageOutlined />{" "}
                    <span className="symbols">Symbols:</span>{" "}
                    {levelSymbolsAmount}{" "}
                    {userValue.length > 0 && <span>+{userValue.length}</span>}
                  </p>
                  <div
                    onClick={() => modalRef.current.openInfoModal("about")}
                    className="body__bottom-about"
                  >
                    {isMobile ? (
                      <QuestionCircleOutlined />
                    ) : (
                      <QuestionOutlined />
                    )}
                  </div>
                  {!cluesUsed &&
                    (user &&
                    user.user &&
                    user.user.clues > 0 &&
                    currentLevel != 0 ? (
                      <p
                        className={`body__bottom-hint  ${
                          !userValue.length ? "display-clue" : ""
                        } ${messages.length > 1 ? "" : "display-clue_right"}  ${
                          currentLevel === 3 && progress.length < 4
                            ? "pulse-animation"
                            : ""
                        }`}
                        onClick={onClueUse}
                      >
                        <BulbOutlined />
                        <span className="clue">
                          Use clue: {user.user.clues}
                        </span>
                      </p>
                    ) : (
                      currentLevel != 0 && (
                        <p
                          className={`body__bottom-hint  ${
                            !userValue.length ? "display-clue" : ""
                          } ${
                            messages.length > 1 ? "" : "display-clue_right"
                          } ${
                            currentLevel === 3 && progress.length < 5
                              ? "pulse-animation "
                              : ""
                          }`}
                          onClick={onClueGet}
                        >
                          <BulbOutlined />
                          <span className="clue">
                            {user && user.user
                              ? "Get clues"
                              : "Sign up and get clues"}{" "}
                          </span>
                        </p>
                      )
                    ))}
                  {messages.length > 1 && (
                    <p
                      className={`body__bottom-hint  ${
                        !userValue.length ? "display-clue_start" : ""
                      }`}
                      onClick={resetLevel}
                    >
                      <RedoOutlined />
                      <span className="clue">Start over</span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="body__bottom-win">
              {isAllLevelsPassed && isLastLevel ? (
                <Button
                  onClick={() => {
                    setFinishAnimation(true);
                    setRunConfetti(false);
                  }}
                  type="primary"
                >
                  Exit the GPTRiddle
                </Button>
              ) : (
                !isLastLevel && (
                  <Button onClick={() => nextLevel()} type="primary">
                    <ArrowRightOutlined /> Next level
                  </Button>
                )
              )}
              <Button onClick={resetLevel}>
                <RedoOutlined /> Start over
              </Button>
            </div>
          )}
        </div>
        {finishAnimation && <Footer />}
      </Layout>
    </>
  );
};

export default Body;
