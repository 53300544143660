import React from "react";
import InfoModal from "../infoModal/InfoModal";
import "./footer.scss";

const Footer = () => {
  const modalRef = React.createRef();
  return (
    <>
      <InfoModal ref={modalRef} />
      <div className="footer">
        <p onClick={() => modalRef.current.openInfoModal("about")}>About</p>
        <p onClick={() => modalRef.current.openInfoModal("privacy")}>
          Privacy policy
        </p>
        <a href="https://donate.stripe.com/4gw4j93cz5j49zy4gg" target="_blank">
          ☕ Buy us a coffee
        </a>
      </div>
      <p className="footer__bottom-team">
        Made with ❤️ by{" "}
        <a href="http://karmabot.chat/" target="_blank">
          Karma
        </a>{" "}
        team in New Zealand
      </p>
    </>
  );
};

export default Footer;
